import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Form } from "react-bootstrap";
import IconGroup from "../Icons/IconGroup";
import IconAddGroup from "../Icons/IconAddGroup";

interface ModalInfoModulInterface {
  showSaveMeetingModal: boolean;
  handleCloseSaveMeetingModal: () => void;
  info: any;
  user: any;
  selectedGroupeSave: number;
  setselectedGroupeSave: React.Dispatch<React.SetStateAction<number>>;
  handleCreateGroupModal: () => void;
  handleSaveMeeting: () => void;
}

const ModalSaveMeeting: FC<ModalInfoModulInterface> = ({
  showSaveMeetingModal,
  handleCloseSaveMeetingModal,
  info,
  user,
  selectedGroupeSave,
  setselectedGroupeSave,
  handleCreateGroupModal,
  handleSaveMeeting,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showSaveMeetingModal}
      onHide={handleCloseSaveMeetingModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal-group-title">
            <div className="modal-group-icon">
              <i className="ico-modal-title"></i>
            </div>
            <div>
              <h5 className="modal-title">{t("save_meeting")}</h5>
              <h6 className="modal-subtitle">Meeteo</h6>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("save_meeting_elements")}</p>
        <Form className="save-meeting-form">
          <Form.Group className="form-group" controlId="title">
            <Form.Label>{t("title")}</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                // handleChangeTileText(e);
              }}
              value={info?.subject ? info.subject : t("meeting_title")}
              contentEditable={false}
            />
          </Form.Group>

          <Form.Label>{t("group")}</Form.Label>
          <div className="group-content">
            <ul className="group-list custom-scroll">
              {user &&
                user.groupe &&
                user.groupe.map((u: any, index: number) => (
                  <li key={index} className="group-item">
                    <span className="group-name">
                      <IconGroup className="icon-group" /> {u.name}
                    </span>
                    <div className="form-check form-radio">
                      <Form.Check
                        type="radio"
                        name="groupItem"
                        checked={selectedGroupeSave === index ? true : false}
                        onClick={() => {
                          setselectedGroupeSave(index);
                        }}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="modal-footer-save-meeting">
        <Button
          variant="outline-primary"
          onClick={handleCreateGroupModal}
          title="Create new group"
        >
          <IconAddGroup className="add-group" /> {t("create_group")}
        </Button>
        <div className="modal-footer-save-right">
          <Button
            variant="outline-primary"
            onClick={handleCloseSaveMeetingModal}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleSaveMeeting();
            }}
            disabled={selectedGroupeSave === -1}
          >
            {t("save")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { ModalSaveMeeting };
