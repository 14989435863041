import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Form } from "react-bootstrap";

interface ModalEnableModuleInterface {
  showEnableDisableModule: boolean;
  toggleShowEnableDisableModule: () => void;
  meetingModules: string[];
  toggleActiveModule: (moduleName: string) => void;
  cancelModuleConfig: () => void;
  applyModuleConfig: () => void;
}

const ModalEnableModule: FC<ModalEnableModuleInterface> = ({
  showEnableDisableModule,
  toggleShowEnableDisableModule,
  meetingModules,
  toggleActiveModule,
  cancelModuleConfig,
  applyModuleConfig,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showEnableDisableModule}
      onHide={toggleShowEnableDisableModule}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-group-title">
          <div className="modal-group-icon">
            <i className="ico-modal-title"></i>
          </div>
          <div>
            <h5 className="modal-title">{t("toggle_module")}</h5>
            <h6 className="modal-subtitle">Meeteo</h6>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("suggested_module_functionality")}</p>

        <Form>
          <div className="module-setting-list custom-scroll">
            <div className="module-setting-item">
              <div className="module-setting-item-top">
                <h3 className="module-title">
                  <span className="module-icon">
                    <i className="ico-person"></i>
                  </span>
                  {t("participants_role")}
                </h3>
                <Form.Check
                  type="switch"
                  className="form-check form-switch"
                  checked={meetingModules?.includes("participants")}
                  onChange={() => toggleActiveModule("participants")}
                />
              </div>
              <p>{t("define_role")}</p>
            </div>

            <div className="module-setting-item">
              <div className="module-setting-item-top">
                <h3 className="module-title">
                  <span className="module-icon">
                    <i className="ico-agenda"></i>
                  </span>
                  {t("agenda")}
                </h3>
                <Form.Check
                  type="switch"
                  className="form-check form-switch"
                  checked={meetingModules?.includes("agenda")}
                  onChange={() => toggleActiveModule("agenda")}
                />
              </div>
              <p>{t("define_objective")}</p>
            </div>

            <div className="module-setting-item">
              <div className="module-setting-item-top">
                <h3 className="module-title">
                  <span className="module-icon">
                    <i className="ico-rules"></i>
                  </span>
                  {t("rules")}
                </h3>
                <Form.Check
                  type="switch"
                  className="form-check form-switch"
                  checked={meetingModules?.includes("rules")}
                  onChange={() => toggleActiveModule("rules")}
                />
              </div>
              <p>{t("define_rule")}</p>
            </div>

            <div className="module-setting-item">
              <div className="module-setting-item-top">
                <h3 className="module-title">
                  <span className="module-icon">
                    <i className="ico-survey"></i>
                  </span>
                  {t("survey")}
                </h3>
                <Form.Check
                  type="switch"
                  className="form-check form-switch"
                  checked={meetingModules?.includes("survey")}
                  onChange={() => toggleActiveModule("survey")}
                />
              </div>
              <p>{t("prepare_survey")}</p>
            </div>

            <div className="module-setting-item">
              <div className="module-setting-item-top">
                <h3 className="module-title">
                  <span className="module-icon">
                    <i className="ico-indicator"></i>
                  </span>
                  {t("indicator")}
                </h3>
                <Form.Check
                  type="switch"
                  className="form-check form-switch"
                  checked={meetingModules?.includes("indicator")}
                  onChange={() => toggleActiveModule("indicator")}
                />
              </div>
              <p>{t("list_indicator")}</p>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={cancelModuleConfig}>
          {t("cancel")}
        </Button>
        <Button variant="primary" onClick={applyModuleConfig}>
          {t("save_change")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ModalEnableModule };
