import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Form } from "react-bootstrap";

interface ModalCreateGroupeItem {
  showCreateGroupModal: boolean;
  handleCloseCreateGroupModal: () => void;
  creatGroupeText: string;
  handleCreateNewGroup: () => void;
  handleChangeGroupeText: (event: any) => void;
}

const ModalCreateGroupe: FC<ModalCreateGroupeItem> = ({
  showCreateGroupModal,
  handleCloseCreateGroupModal,
  handleChangeGroupeText,
  creatGroupeText,
  handleCreateNewGroup,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showCreateGroupModal}
      onHide={handleCloseCreateGroupModal}
      backdrop="static"
      keyboard={false} // centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal-group-title">
            <div className="modal-group-icon">
              <i className="ico-modal-title"></i>
            </div>
            <div>
              <h5 className="modal-title">{t("create_group")}</h5>
              <h6 className="modal-subtitle">Meeteo</h6>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="form-group" controlId="group">
            <Form.Label>{t("group")}</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                handleChangeGroupeText(e);
              }}
              value={creatGroupeText}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleCloseCreateGroupModal}>
          {t("cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleCreateNewGroup();
          }}
        >
          {t("add")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ModalCreateGroupe };
