import React from 'react';
import sun from '../../assets/images/sun.svg';

const Sunny = (props:any) => {
  return(
    <div className={"sunny-item " + props.class}>
        <figure>
            <img src={sun} alt="sunny" />
        </figure>
        <div className="sunny-info"><span>{props.text}</span> <span>{props.point ? props.point + ' pts' : null}</span></div>
    </div>
  )
}

export default Sunny; 
