import { useEffect, useState, useMemo, useCallback } from "react";
import { MeetingService } from "../../../services/MeetingService";
import { addDays, format } from "date-fns";
import * as microsoftTeams from "@microsoft/teams-js";
import i18n from "../../../i18n";
import { useGraph } from "@microsoft/teamsfx-react";

type dataType = "good" | "average" | "bad";

const useLogic = () => {
  const meetingService = new MeetingService();
  const [dataTalkTimeAdminTab, setDataTaklTimeAdminTab] = useState<any[]>([]);
  const [dataAverageUser, setDataAverageUser] = useState<any[]>();
  const [dataAverageAnimator, setDataAverageAnimator] = useState<any[]>();
  const [dataAverageAllParticipant, setDataAverageAllParticipant] =
    useState<any[]>();
  const [dataWheatherAverage, setDataWheatherAverage] = useState<any[]>();
  const [context, setContext] = useState<any>();
  const [isActiveButton, setisActiveButton] = useState<"week" | "month">(
    "week"
  );
  const [userInformation, setUserInformation] = useState<any>();
  const [userScore, setUserScore] = useState<any>();

  const [organisation, setOrganisation] = useState<any>();

  useGraph(
    async (graph: any) => {
      // Call graph api directly to get user profile information and to initialize token
      await graph.api("me/").get();

      const organizationData = await graph
        .api("organization/")
        .get()
        .catch((error: any) => {});
      setOrganisation(() => organizationData.value[0]);
    },
    {
      scope: ["User.Read"],
    }
  );

  const generateDataChart = useCallback(
    (data: string[], type: dataType) => {
      var temp: number[] = [];
      data.forEach((element) => {
        const current = dataTalkTimeAdminTab.find(
          (x) => x.date.toLowerCase() === element.toLowerCase()
        );
        const numberMeeting = current ? current[type] : 0;
        temp.push(numberMeeting);
      });
      return temp;
    },
    [dataTalkTimeAdminTab]
  );

  const generateDataAverage = (data: string[], dataAverage: any[]): any => {
    var temp: number[] = [];
    data.forEach((element) => {
      if (dataAverage) {
        const result = Object.getOwnPropertyNames(dataAverage);
        let valueAverage = null;
        for (let index = 0; index < result.length; index++) {
          if (dataAverage[index].endDateFormat === element) {
            valueAverage = dataAverage[index].avg;
          }
        }
        if (valueAverage !== null) {
          temp.push(valueAverage);
        } else {
          temp.push(0);
        }
      }
    });
    return temp;
  };

  const getLabels = useMemo(() => {
    let datesString: string[] = [];
    const datenow = new Date();
    let sizeArray: number = 0;
    let beginDate = addDays(datenow, -7);
    if (isActiveButton === "week") {
      beginDate = addDays(datenow, -7);
      sizeArray = 7;
    } else if (isActiveButton === "month") {
      beginDate = addDays(datenow, -30);
      sizeArray = 30;
    }
    for (let index = 0; index < sizeArray; index++) {
      const dateToPush = addDays(beginDate, index);
      datesString.push(format(dateToPush, "LLL dd"));
    }
    return datesString;
  }, [isActiveButton]);

  const getData = (context: any, isActiveButton: string) => {
    const date = new Date();
    const datenow = date.toISOString().substring(0, 10);
    const idOrganisation = organisation.id;
    let referenceUser: string = context.userObjectId;
    meetingService
      .getaverageTalkTimeAdminTab(idOrganisation, datenow, isActiveButton)
      .then(async (dataTalkTime) => {
        dataTalkTime.json().then((data) => {
          setDataTaklTimeAdminTab(data);
        });
      });
    meetingService
      .getAverageByUserOrganization(
        idOrganisation,
        datenow,
        referenceUser,
        isActiveButton
      )
      .then(async (dataAverageUser) => {
        dataAverageUser.json().then((data) => {
          setDataAverageUser({ ...data });
        });
      });
    meetingService
      .getAverageByAnimator(idOrganisation, datenow, "animator", isActiveButton)
      .then(async (dataAverageAnimator) => {
        dataAverageAnimator.json().then((data) => {
          setDataAverageAnimator({ ...data });
        });
      });
    meetingService
      .getAverageByAllParticipant(idOrganisation, datenow, isActiveButton)
      .then(async (dataAverageAllParticipant) => {
        dataAverageAllParticipant.json().then((data) => {
          setDataAverageAllParticipant({ ...data });
        });
      });
    meetingService
      .getWheatherAverageByAllParticipant(
        idOrganisation,
        datenow,
        isActiveButton
      )
      .then(async (dataWheather) => {
        dataWheather.json().then((data) => {
          setDataWheatherAverage({ ...data });
        });
      });
    meetingService.getuserScore(referenceUser).then(async (userScore) => {
      userScore.json().then((data) => {
        setUserScore(data);
      });
    });
    meetingService.getuserInformation(referenceUser).then(async (userScore) => {
      userScore.json().then((data) => {
        setUserInformation(data);
      });
    });
  };

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((contextFrame) => {
      setContext({ ...contextFrame });

      if (
        contextFrame.locale.toString().toLocaleLowerCase() === "fr-fr" ||
        contextFrame.locale.toString().toLocaleLowerCase() === "fr" ||
        contextFrame.locale.toString().toLocaleLowerCase() === "fr-ca"
      ) {
        i18n.changeLanguage("fr");
      } else {
        i18n.changeLanguage("en");
      }
    });
  }, []);

  useEffect(() => {
    if (context && organisation) {
      getData(context, isActiveButton);
    }
  }, [context, isActiveButton, organisation]);

  return {
    getLabels,
    dataAverageUser,
    dataAverageAnimator,
    dataAverageAllParticipant,
    dataWheatherAverage,
    userInformation,
    userScore,
    generateDataAverage,
    setisActiveButton,
    generateDataChart,
    isActiveButton,
  };
};

export default useLogic;
