import { Participant, Meeting } from "../models/Meeting";
import { TFunction } from "react-i18next";
const getTimeZone = (d: Date): string => {
  const date = new Date(d);
  // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const dateAsString = date.toString();
  const timeZoneArray = dateAsString.match(/([A-Z]+[\+-][0-9]+.*)/);
  const timeZone = timeZoneArray && timeZoneArray[0] ? timeZoneArray[0] : "";

  return timeZone;
};

const MailTemplate = (
  meeting: Meeting,
  event: any,
  t: TFunction<"translation", undefined>
) => {
  let temp = `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
      <title> ${t("meeteo_planning")}</title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a {
          padding: 0;
        }
    
        body {
          margin: 0;
          padding: 0;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }
    
        table,
        td {
          border-collapse: collapse;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
    
        img {
          border: 0;
          height: auto;
          line-height: 100%;
          outline: none;
          text-decoration: none;
          -ms-interpolation-mode: bicubic;
        }
    
        p {
          display: block;
          margin: 13px 0;
        }
    
      </style>
      <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
      <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
      <!--[if !mso]><!-->
      <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
      <style type="text/css">
        @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
    
      </style>
      <!--<![endif]-->
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
          }
    
          .mj-column-px-600 {
            width: 600px !important;
            max-width: 600px;
          }
        }
    
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 {
          width: 100% !important;
          max-width: 100%;
        }
    
        .moz-text-html .mj-column-px-600 {
          width: 600px !important;
          max-width: 600px;
        }
    
      </style>
      <style type="text/css">
      </style>
      <style type="text/css">
        .title div {
          font-size: 18px !important;
          font-weight: bold !important;
          color: #242424 !important;
          text-align: center !important;
          margin-bottom: 8px !important;
        }
    
        .sub-title div {
          text-align: center !important;
        }
    
        .section-space {
          height: 50px;
        }
    
        .section-header-title div {
          font-size: 12px !important;
          font-weight: bold !important;
          color: #ffffff !important;
        }
    
        .section-body-title div {
          font-size: 12px !important;
          color: #6F6F6F !important;
        }
    
        .section-body-title.center div {
          text-align: center !important;
        }
    
      </style>
    </head>
    
    <body style="word-spacing:normal;">
      <div style="">
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-title-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div class="section-title" style="margin:0px auto;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:50px 0 25px 0;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                      <tbody>
                        <tr>
                          <td align="left" class="title" style="font-size:0px;padding:0px;word-break:break-word;">
                            <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                              "meeteo_planning"
                            )}</div>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" class="sub-title" style="font-size:0px;padding:0px;word-break:break-word;">
                            <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${""}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Mail -->
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
            <tbody>
              <tr>
                <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                      <tbody>
                        <tr>
                          <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                            <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${""}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div class="section-body" style="margin:0px auto;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
            <tbody>
              <tr>
                <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                      <tbody>
                        <tr>
                          <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                            <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${""}.</div>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                              
                              <!-- ? ICI -->
                              <tr>
                                <td>
                                  <table style="border-bottom: 1px solid #e0e0e0; margin-bottom: 8px; width: 100%">
                                    <tr>
                                      <td style="padding: 0 8px">
                                        <div>
                                          <div><span style="color: #6F6F6F; font-size:12px">${t(
                                            "meeting_name"
                                          )} : </span><span>${
    meeting.subject
  }</span></div>
                                          ${
                                            meeting &&
                                            meeting.participants &&
                                            meeting.participants
                                              .map(
                                                (
                                                  participant: Participant,
                                                  index: number
                                                ) => {
                                                  if (
                                                    participant.role ===
                                                      "admin" ||
                                                    participant.role ===
                                                      "animator"
                                                  ) {
                                                    return `<div><span style="color: #6F6F6F; font-size:12px">${t(
                                                      "participant_module.role." +
                                                        participant.role
                                                    )}: </span><span>${
                                                      participant.name
                                                    }</span></div>`;
                                                  }
                                                  return "";
                                                }
                                              )
                                              .join("")
                                          }
                                            <div><span style="color: #6F6F6F; font-size:12px">${"Date"} : </span><span>${new Date(
    Date.parse(meeting && meeting.startDate ? meeting.startDate.toString() : "")
  ).toLocaleDateString()}
                                           </span></div>

                                          <div><span style="color: #6F6F6F; font-size:12px">${t(
                                            "hours"
                                          )} : </span><span>${new Date(
    Date.parse(meeting && meeting.startDate ? meeting.startDate.toString() : "")
  ).toLocaleTimeString()} - ${new Date(
    Date.parse(meeting && meeting.endDate ? meeting.endDate.toString() : "")
  ).toLocaleTimeString()}  ${getTimeZone(
    new Date(
      Date.parse(meeting && meeting.endDate ? meeting.endDate.toString() : "")
    )
  )}</span></div>


                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                              <td>
                                <table style="border-bottom: 1px solid #e0e0e0; margin-bottom: 8px; width: 100%">
                                  <tr>
                                    <td style="padding: 0 8px">
                                      <div>
                                        <div><span style="color: #6F6F6F; font-size:12px">${t(
                                          "vous_invite",
                                          {
                                            name: event.organizer.emailAddress
                                              .name,
                                            meeting: meeting.subject,
                                          }
                                        )}</span></div>
                                        <a href="${
                                          meeting.link
                                        }">${"Meeteo"}</a>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                              
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
     
        <!--[if mso | IE]></td></tr></table><![endif]-->
        <div style="height:25px;line-height:25px;">&#8202;</div>
      </div>
    </body>
    
    </html>
    `;
  return temp;
};

export { MailTemplate };
