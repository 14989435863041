import React, { FC } from "react";
import { Form, Button } from "react-bootstrap";
import { Meeting, Rule } from "../../../models/Meeting";
import sun from "../../assets/images/sun.svg";
import useLogic from "../../Logic/Rule/useLogic";
import { useTranslation } from "react-i18next";
import IconProfileOutlined from "../Icons/IconProfileOutlined";
interface StepProps {
  meeting: Meeting;
  context: any;
  profile: any;
}

const StepThree: FC<StepProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    title,
    showDeleteConfirmation,
    role,
    indexRuleStep,
    handleRuleChange,
    addRule,
    deleteRule,
    setShowDeleteConfirmation,
    handleDeleteRuleStep,
  } = useLogic(props);

  return (
    <>
      <div className="inmeeting-meeteo-bot">
        <div className="inmeeting-meeteo-bot-content">
          {showDeleteConfirmation ? (
            <p>{t("in_meetings.step.add_rules_items")}</p>
          ) : (
            <div className="bot-content-actions-confirmation">
              <h3>{t("in_meetings.step.delete_rule")}</h3>
              <p>{t("remove.rule.confirmation")}</p>
              <div className="bot-actions">
                <Button
                  className="btn btn-cancel"
                  onClick={() => setShowDeleteConfirmation(true)}
                  variant="link"
                >
                  {t("cancel")}
                </Button>
                <Button
                  className="btn-delete"
                  variant="link"
                  onClick={() => deleteRule(indexRuleStep)}
                >
                  {t("delete")}
                </Button>
              </div>
            </div>
          )}
        </div>
        <figure>
          <img src={sun} alt="Sunny" />
        </figure>
      </div>

      <div className="step-labels">
        <div className="step-label-item active">
          <span>1</span>
        </div>
        <div className="step-label-item active">
          <span>2</span>
        </div>
        <div className="step-label-item active">
          <span>3</span>
        </div>
      </div>

      <h3 className="title">{t("rules")}</h3>
      <p>{t("in_meetings.step.add_rule_in_meeting")}</p>

      <Form.Group className="form-group" controlId="type">
        <Form.Control
          type="text"
          placeholder={t("rule_module.type")}
          onChange={handleRuleChange}
          value={title}
          name="title"
        />
        <Button
          className="btn btn-outline-primary add h-32"
          title="Add Agenda"
          onClick={addRule}
          disabled={title ? false : true}
        >
          {t("add")}
        </Button>
      </Form.Group>

      <div className="module-add-list-wrapper custom-scroll">
        <ul className="module-add-list">
          {meeting.rules?.map((rule: Rule, index: number) => {
            return (
              <li
                className="module-list-item module-list-item-rule"
                key={index}
              >
                <span className="type">{rule.title}</span>
                <div className="type-actions-right">
                  <span className="person">
                    {!rule.isAdmin && <IconProfileOutlined />}
                  </span>
                  <Button
                    className="btn remove-list-item"
                    variant="link"
                    disabled={
                      role !== "admin" &&
                      role !== "animator" &&
                      rule?.owner !== (props?.context?.userObjectId as string)
                    }
                    title="Delete Rule"
                    onClick={() => handleDeleteRuleStep(index)}
                  >
                    <i className="ico-delete"></i>
                  </Button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {showDeleteConfirmation ? null : <div className="inmeeting-mask"></div>}
    </>
  );
};

export default StepThree;
