import { Survey } from "../../models/Meeting";
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface SurveyModalInterface {
  setSurveyListe: React.Dispatch<React.SetStateAction<Survey | undefined>>;
  surveyListe: Survey | undefined;
  showAddSurveyModal: boolean;
  setShowAddSurveyModal: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
  editData?: Survey;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditData?: React.Dispatch<React.SetStateAction<Survey | undefined>>;
}

const AddSurveyModal: React.FC<SurveyModalInterface> = ({
  setEditData,
  setIsEdit,
  isEdit,
  editData,
  setSurveyListe,
  surveyListe,
  showAddSurveyModal,
  setShowAddSurveyModal,
}) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState<string>("");
  const [isMultichoice, setIsMultichoice] = useState<boolean>(false);
  const [listeChoice, setListeChoice] = useState<string[]>([]);
  const [isBoutonAddDisabled, setIsBoutonAddDisabled] = useState(true);

  const handleQuestion = (e: any) => {
    setQuestion(e.target.value);
  };
  const handleSwitch = (e: any) => {
    setIsMultichoice(!isMultichoice);
  };

  useEffect(() => {
    if (showAddSurveyModal) {
      if (isEdit && editData) {
        setQuestion(editData.question as string);
        setListeChoice(editData.options as string[]);
        setIsMultichoice(editData.isMultichoice as boolean);
      } else {
        setListeChoice(["", ""]);
        setQuestion("");
        setIsMultichoice(false);
      }
    }
  }, [showAddSurveyModal]);

  useEffect(() => {
    if (question.trim() === "" || isOprtionEmpty()) {
      setIsBoutonAddDisabled(true);
    } else {
      setIsBoutonAddDisabled(false);
    }
  }, [question, listeChoice]);

  const isOprtionEmpty = (): boolean => {
    var temp = false;
    listeChoice.forEach((element) => {
      if (element.trim() === "") {
        temp = true;
      }
    });
    return temp;
  };

  const addOption = () => {
    let temp = listeChoice;
    temp.push("");
    setListeChoice([...temp]);
  };

  const addSurvey = () => {
    var temp = {
      question: question,
      isMultichoice: isMultichoice,
      options: listeChoice,
    };
    setSurveyListe(temp);
  };

  const editSurvey = () => {
    var temp = {
      _id: editData && editData._id ? editData._id : "",
      question: question,
      isMultichoice: isMultichoice,
      options: listeChoice,
      resultat: editData && editData.resultat ? editData.resultat : [],
      isShared: editData && editData.isShared ? editData.isShared : false,
      creator: editData && editData.creator ? editData.creator : "",
    };
    setSurveyListe(temp);
  };

  const handleListe = (e: any, index: number) => {
    let temp = listeChoice;
    temp[index] = e.target.value;
    setListeChoice([...temp]);
  };

  const resetEditMode = () => {
    if (setEditData) {
      setEditData(undefined);
    }
  };

  const handleCloseAddSurveyModal = () => {
    if (isEdit) {
      resetEditMode();
      if (setIsEdit) setIsEdit(false);
    }
    setShowAddSurveyModal(false);
  };

  const handleAddSurveyModal = () => {
    if (isEdit) {
      editSurvey();
    } else {
      addSurvey();
    }
    setShowAddSurveyModal(false);
  };

  const handleShowDeleteOption = (index: number) => {
    var temp = listeChoice;
    temp.splice(index, 1);

    setListeChoice([...temp]);
  };

  return (
    <>
      <Modal
        show={showAddSurveyModal}
        onHide={handleCloseAddSurveyModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-group-title">
              <div className="modal-group-icon">
                <i className="ico-modal-title"></i>
              </div>
              <div>
                {isEdit && (
                  <h5 className="modal-title">{t("survey_module.edit")}</h5>
                )}
                {!isEdit && (
                  <h5 className="modal-title">{t("survey_module.add")}</h5>
                )}

                <h6 className="modal-subtitle">Meeteo</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isEdit && <p>{t("survey_module.modify")}</p>}
          {!isEdit && <p>{t("survey_module.useful_survey")}</p>}

          <Form>
            <Form.Group className="form-group" controlId="question">
              <Form.Label>{t("survey_module.question")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={question}
                onChange={handleQuestion}
              />
            </Form.Group>

            <Form.Group className="form-group" controlId="question">
              <Form.Label>{t("survey_module.options")}</Form.Label>
              <span className="add-option" onClick={addOption}>
                <i className="ico-add-circle"></i>
                {t("survey_module.add_option")}
              </span>
            </Form.Group>

            <Form.Group
              className="form-check d-flex p-0 form-switch mb-3"
              controlId="question"
            >
              <Form.Check
                type="switch"
                className="form-check p-0 form-switch"
                checked={isMultichoice}
                onChange={handleSwitch}
              />
              <label className="form-check-label">
                {t("survey_module.choose_options")}
              </label>
            </Form.Group>

            <div className="option-list custom-scroll">
              {listeChoice?.map((choice: string, index: number) => (
                <div key={index} className="form-group form-inline">
                  <span className="form-label">
                    {t("survey_module.option")} {index}:
                  </span>
                  <Form.Control
                    type="text"
                    value={choice}
                    onChange={(event) => {
                      handleListe(event, index);
                    }}
                    placeholder=""
                    id={`option${index}`}
                  />
                  {index > 1 && (
                    <span
                      className="btn"
                      title="Delete Survey"
                      onClick={() => {
                        handleShowDeleteOption(index);
                      }}
                    >
                      <i className="ico-delete"></i>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleCloseAddSurveyModal}>
            {t("cancel")}
          </Button>
          {isEdit && (
            <Button
              disabled={isBoutonAddDisabled}
              variant="primary"
              onClick={handleAddSurveyModal}
            >
              {t("edit")}
            </Button>
          )}
          {!isEdit && (
            <Button
              disabled={isBoutonAddDisabled}
              variant="primary"
              onClick={handleAddSurveyModal}
            >
              {t("add")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSurveyModal;
