import React, { FC } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { FormControl, MenuItem, Select } from "@material-ui/core";

interface ModalEditModuleInterface {
  showEditModal: boolean;
  handleCloseEditModal: () => void;
  info: any;
  type: any;
  handleTypeClicked: (event: any) => void;
  objective: string | undefined;
  handleObjectiveChange: (event: any) => void;
  handleSaveChange: () => void;
}

const ModalEditModule: FC<ModalEditModuleInterface> = ({
  showEditModal,
  handleCloseEditModal,
  info,
  type,
  handleTypeClicked,
  objective,
  handleObjectiveChange,
  handleSaveChange,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showEditModal}
      onHide={handleCloseEditModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-group-title">
          <div className="modal-group-icon">
            <i className="ico-modal-title"></i>
          </div>
          <div>
            <h5 className="modal-title">{t("edit_details")}</h5>
            <h6 className="modal-subtitle">Meeteo</h6>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("edit_functionality")}</p>

        <Form>
          <Form.Group className="form-group" controlId="title">
            <Form.Label>{t("title")}</Form.Label>
            <Form.Control
              type="text"
              value={info?.subject ? info.subject : t("meeting_title")}
              readOnly
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="type">
            <Form.Label id="type">{t("type")}</Form.Label>
            <FormControl fullWidth>
              <Select
                labelId="type-label"
                id="type"
                value={type}
                variant="outlined"
                onChange={handleTypeClicked}
                IconComponent={() => <KeyboardArrowDownIcon />}
              >
                <MenuItem value="Daily review">{t("daily_review")}</MenuItem>
                <MenuItem value="Commercial">{t("commercial")}</MenuItem>
                <MenuItem value="Direction">{t("direction")}</MenuItem>
                <MenuItem value="Finance">{t("finance")}</MenuItem>
              </Select>
            </FormControl>
          </Form.Group>

          <Form.Group className="form-group" controlId="objective">
            <Form.Label>{t("objective")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={objective}
              name="objective"
              onChange={handleObjectiveChange}
              placeholder={t("objective_placeholder")}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleCloseEditModal}>
          {t("cancel")}
        </Button>
        <Button variant="primary" onClick={handleSaveChange}>
          {t("save_change")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export { ModalEditModule };
