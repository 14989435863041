import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Meeting, Survey } from "../../models/Meeting";
import AddSurveyModal from "./AddSurveyModal";
import { useTranslation } from "react-i18next";
import DataContext from "../../Providers/DataProvider/DataContext";

interface props {
  meeting: Meeting;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  deactivateModule: Function;
}

const SurveyModule: React.FC<props> = ({
  meeting,
  setMeeting,
  deactivateModule,
}) => {
  const { t } = useTranslation();
  const {
    insertMeeting,
    addSurveysData,
    updateSurveysData,
    deleteSurveysData,
  } = useContext(DataContext);

  const [showAddSurveyModal, setShowAddSurveyModal] = useState<boolean>(false);
  const [surveyList, setSurveyList] = useState<Survey>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<Survey>();
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentDeleteId, setcurrentDeleteId] = useState<number>(-1);

  const deleteText = {
    title: t("remove.survey.title"),
    text: t("remove.survey.confirmation"),
  };

  const addSurvey = () => {
    if (surveyList) {
      meeting.surveys?.push({ ...surveyList });
      addSurveysData(surveyList, meeting);
    }
  };

  const editSurvey = () => {
    if (surveyList && editData) {
      meeting.surveys?.splice(editIndex, 1, surveyList);
      updateSurveysData(surveyList as Survey, meeting);
    }
    setIsEdit(false);
    setEditData(undefined);
  };

  const deleteSurvey = (index: number) => {
    let temp =
      meeting && meeting.surveys
        ? (meeting.surveys[index] as Survey)
        : ({} as Survey);
    meeting.surveys?.splice(index, 1);
    deleteSurveysData(temp, meeting);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (surveyList) {
      if (isEdit) {
        editSurvey();
      } else {
        addSurvey();
      }
    }
  }, [surveyList]);

  const handleEditSurveyModal = (data: Survey, index: number) => {
    setEditIndex(index);
    setEditData(data);
    setIsEdit(true);
    setShowAddSurveyModal(true);
  };

  const handleShowDeleteModal = (index: number) => {
    setShowDeleteModal(true);
    setcurrentDeleteId(index);
  };

  const handleAddSurveyModal = () => {
    setShowAddSurveyModal(true);
  };

  return (
    <>
      <div className="module-item survey">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-survey"></i>
            </span>
            {t("survey")}
          </h3>

          <span
            className="remove-module"
            onClick={(e) => deactivateModule("survey")}
          >
            <i className="ico-remove-circle"></i>
          </span>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">
            {t("survey_module.add_module")}
          </p>

          <div className="module-main-content module-main-content-survey">
            <Form>
              <div className="survey-list custom-scroll">
                {meeting.surveys?.map((survey: Survey, index: number) => (
                  <div className="survey-item" key={index}>
                    <div className="survey-item-content">
                      <label>{t("survey_module.question")}:</label>
                      <p>{survey.question}</p>

                      <label>{t("survey_module.options")}:</label>
                      <ul>
                        {survey?.options?.map((op: string, index: number) => (
                          <div key={index}>
                            <li>{op}</li>
                          </div>
                        ))}
                      </ul>
                    </div>

                    <div className="survey-item-actions">
                      <span
                        className="btn"
                        title="Edit Survey"
                        onClick={() => {
                          handleEditSurveyModal(survey, index);
                        }}
                      >
                        <i className="ico-edit"></i>
                      </span>
                      <span
                        className="btn"
                        title="Delete Survey"
                        onClick={() => handleShowDeleteModal(index)}
                      >
                        <i className="ico-delete"></i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Form>
          </div>

          <div className="btn-wrapper right">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              onClick={() => {
                handleAddSurveyModal();
              }}
            >
              {t("survey_module.add")}
            </button>
          </div>
        </div>
      </div>

      {!isEdit && (
        <AddSurveyModal
          showAddSurveyModal={showAddSurveyModal}
          setShowAddSurveyModal={setShowAddSurveyModal}
          setSurveyListe={setSurveyList}
          surveyListe={surveyList}
        ></AddSurveyModal>
      )}

      {isEdit && (
        <AddSurveyModal
          showAddSurveyModal={showAddSurveyModal}
          setShowAddSurveyModal={setShowAddSurveyModal}
          setSurveyListe={setSurveyList}
          surveyListe={surveyList}
          isEdit={true}
          editData={editData}
          setIsEdit={setIsEdit}
          setEditData={setEditData}
        ></AddSurveyModal>
      )}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{deleteText.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="remove">
          <p>{deleteText.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteSurvey(currentDeleteId)}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SurveyModule;
