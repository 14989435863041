import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Template = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 16">
      <path
        id="ic_fluent_stack_star_24_filled"
        d="M4,4A2,2,0,0,0,2,6v7a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2ZM9.5,6.1a.5.5,0,0,1,.447.276l.88,1.761,1.734.217a.5.5,0,0,1,.258.88l-1.266,1.055.434,1.953a.5.5,0,0,1-.753.533L9.5,11.69,7.765,12.774a.5.5,0,0,1-.753-.533l.434-1.953L6.18,9.234a.5.5,0,0,1,.258-.88l1.734-.217.88-1.761A.5.5,0,0,1,9.5,6.1Zm-3,11.4A2,2,0,0,1,4.563,16H15.5A2.5,2.5,0,0,0,18,13.5V6.563A2,2,0,0,1,19.5,8.5v5a4,4,0,0,1-4,4ZM9,20a2,2,0,0,1-1.937-1.5H16.25a4.25,4.25,0,0,0,4.25-4.25V9.063A2,2,0,0,1,22,11v3.25A5.75,5.75,0,0,1,16.25,20Z"
        transform="translate(-2 -4)"
        fill="#242424"
      />
    </SvgIcon>
  );
};

export default Template;
