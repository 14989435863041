import React from 'react';
import { Meeting } from "../../../models/Meeting";
import IndicatorComponent from './IndicatorComponent';

interface props {
  meeting: Meeting;
  context:any;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  deactivateModule: Function;
}

const IndicatorModule: React.FC<props> = ({ meeting, context, setMeeting, deactivateModule }) =>{

  return(
      <IndicatorComponent meeting={meeting} context={context} setMeeting={setMeeting} deactivateModule={deactivateModule} />
  )
}

export default IndicatorModule;
