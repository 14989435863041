import react, { useState, useMemo } from "react";

export const useSunny = () => {
  const [weatherID, setweatherID] = useState<number>(6);

  return useMemo(
    () => ({ weatherID, setweatherID }),
    [weatherID, setweatherID]
  );
};
