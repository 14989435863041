import React, { useContext, useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import sunshine from "../../assets/images/sunshine.svg";
import partlySunny from "../../assets/images/partly-sunny.svg";
import partyCloudy from "../../assets/images/partly-cloudy.svg";
import cloudy from "../../assets/images/cloudy.svg";
import rain from "../../assets/images/rain.svg";
import thunderstorm from "../../assets/images/thunderstorm.svg";
import EmptyStar from "../../Components/Icons/IconEmptyStar";

import FilledStar from "../../Components/Icons/IconFilledStar";
import { useTranslation } from "react-i18next";
import DataContext from "../../../Providers/DataProvider/DataContext";
import { Meeting, Action, Participant } from "../../../models/Meeting";
import { chatItemSlotClassNames } from "@fluentui/react-northstar";

interface RateInterface {}

const Rate: React.FC<RateInterface> = () => {
  const { t } = useTranslation();
  const { meeting } = useContext(DataContext);
  const [wheatherMoyen, setWheatherMoyen] = useState<number>(0);
  const [timeTalekMoyen, settimeTalekMoyen] = useState<number>(0);
  const [effectivenessMoyen, seteffectivenessMoyen] = useState<number>(0);
  const [numberEffectiveness, setnumberEffectiveness] = useState<number>(0);
  const [numberTimeTalk, setnumberTimeTalk] = useState<number>(0);

  const weatherCalcul = () => {
    let temp = meeting?.participants as Participant[];
    let s = 0;
    let se = 0;
    let st = 0;
    let effectiveness = 0;
    let timeTalk = 0;
    let totalP = 0;

    temp?.forEach((element) => {
      s += element.wheather ? element.wheather : 6;
      effectiveness += element.effectiveness ? element.effectiveness : 2.5;
      timeTalk += element.timeTalk ? element.timeTalk : 2.5;
      if (element.effectiveness !== undefined) {
        se += 1;
      }
      if (element.timeTalk !== undefined) {
        st += 1;
      }
      totalP++;
    });

    setWheatherMoyen(Math.floor((s / totalP) as number));
    settimeTalekMoyen(Math.floor((timeTalk / totalP) as number));
    seteffectivenessMoyen(Math.floor((effectiveness / totalP) as number));
    setnumberEffectiveness(se);
    setnumberTimeTalk(st);
  };

  const wheatherImg = (choice: number) => {
    switch (choice) {
      case 1:
        return (
          <div className="weather-meeting">
            <figure>
              <img src={thunderstorm} alt="" />
            </figure>
            <h2>{t("thunderstorm")}</h2>
          </div>
        );

      case 2:
        return (
          <div className="weather-meeting">
            <figure>
              <img src={rain} alt="" />
            </figure>
            <h2>{t("rain")}</h2>
          </div>
        );
      case 3:
        return (
          <div className="weather-meeting">
            <figure>
              <img src={cloudy} alt="" />
            </figure>
            <h2>{t("Cloudy")}</h2>
          </div>
        );
      case 4:
        return (
          <div className="weather-meeting">
            <figure>
              <img src={partyCloudy} alt="" />
            </figure>
            <h2>{t("partly_cloudy")}</h2>
          </div>
        );
      case 5:
        return (
          <div className="weather-meeting">
            <figure>
              <img src={partlySunny} alt="" />
            </figure>
            <h2>{t("partly_sunny")}</h2>
          </div>
        );
      case 6:
        return (
          <div className="weather-meeting">
            <figure>
              <img src={sunshine} alt="" />
            </figure>
            <h2>{t("partly_sunny")}</h2>
          </div>
        );
      default:
        return <div className="weather-meeting"></div>;
    }
  };
  useEffect(() => {
    if (meeting && meeting.participants) weatherCalcul();
  }, [meeting.participants]);

  return (
    <div className="module-item rate">
      <div className="module-header">
        <h3 className="module-title">
          <span className="module-icon">
            <i className="ico-rate"></i>
          </span>
          {t("rate")}
        </h3>
      </div>

      <div className="module-item-content">
        <div className="module-main-content module-main-content-rate">
          <ul className="module-rate-list">
            <li className="module-rate-list-item">
              <p className="text-center">{t("rate_effectiv_meeting")}:</p>
              <div className="rate-stars">
                <div className="stars">
                  <Rating
                    value={effectivenessMoyen}
                    readOnly
                    icon={<FilledStar />}
                    emptyIcon={<EmptyStar />}
                  />
                </div>

                <p className="label">{t("in_meetings.tabs.voters_number")}:</p>
                <p className="voters">
                  {numberEffectiveness}/
                  {meeting?.participants ? meeting?.participants?.length : "0"}
                  {""}
                  <span>
                    (
                    {meeting?.participants
                      ? Math.floor(
                          (numberEffectiveness /
                            meeting?.participants?.length) *
                            100
                        )
                      : 0}
                    %)
                  </span>
                </p>
              </div>
            </li>

            <li className="module-rate-list-item">
              <p className="text-center">{t("rate_of_level")}:</p>
              <div className="rate-stars">
                <div className="stars">
                  <Rating
                    value={timeTalekMoyen}
                    readOnly
                    icon={<FilledStar />}
                    emptyIcon={<EmptyStar />}
                  />
                </div>
                <p className="label">{t("in_meetings.tabs.voters_number")}:</p>
                <p className="voters">
                  {numberTimeTalk}/
                  {meeting?.participants ? meeting?.participants?.length : "0"}
                  {""}
                  <span>
                    (
                    {meeting?.participants
                      ? Math.floor(
                          (numberTimeTalk / meeting?.participants?.length) * 100
                        )
                      : 0}
                    %)
                  </span>
                </p>
              </div>
            </li>

            <li className="module-rate-list-item">
              <p className="text-center">{t("average_weather")}:</p>
              <div className="rate-stars">
                <div className="weather-meeting">
                  {wheatherImg(wheatherMoyen)}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Rate;
