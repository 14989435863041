import { useEffect, useState, useMemo } from "react";
import { MeetingService } from "../../../services/MeetingService";
import { addDays, format } from "date-fns";
import * as microsoftTeams from "@microsoft/teams-js";
import i18n from "../../../i18n";

const useLogic = () => {
  const meetingService = new MeetingService();
  const [dataTalkTime, setDataTaklTime] = useState([]);
  const [dataAverageUser, setDataAverageUser] = useState<any[]>();
  const [dataAverageAnimator, setDataAverageAnimator] = useState<any[]>();
  const [dataAverageAllParticipant, setDataAverageAllParticipant] =
    useState<any[]>();
  const [context, setContext] = useState<any>();
  const [userInformation, setUserInformation] = useState<any>();
  const [userScore, setUserScore] = useState<any>();
  const [timeTrend, setTimeTrend] = useState<"low" | "medium" | "well">("low");
  const [percentTrend, setPercentTrend] = useState<number>(0);

  const generateDataAverage = (data: string[], dataAverage: any[]): any => {
    var temp: number[] = [];
    data.forEach((element) => {
      if (dataAverage) {
        const result = Object.getOwnPropertyNames(dataAverage);
        let valueAverage = null;
        for (let index = 0; index < result.length; index++) {
          if (dataAverage[index].endDateFormat === element) {
            valueAverage = dataAverage[index].avg;
          }
        }
        if (valueAverage !== null) {
          temp.push(valueAverage);
        } else {
          temp.push(0);
        }
      }
    });
    return temp;
  };

  const getLabels = useMemo(() => {
    let datesString: string[] = [];
    const datenow = new Date();
    const beginDate = addDays(datenow, -7);
    for (let index = 0; index < 7; index++) {
      const dateToPush = addDays(beginDate, index);
      datesString.push(format(dateToPush, "LLL dd"));
    }
    return datesString;
  }, []);

  const getSpeakingTimeTrend = (dataTalkTime: any) => {
    const result = Object.getOwnPropertyNames(dataTalkTime);
    let sum = 0;
    let maxNombre = dataTalkTime[0];
    for (let index = 0; index < result.length; index++) {
      sum += dataTalkTime[index];
    }
    for (let index = 1; index < result.length; index++) {
      if (index === 1 && dataTalkTime[index] > maxNombre) {
        maxNombre = dataTalkTime[index];
        setTimeTrend("medium");
      }
      if (index === 2 && dataTalkTime[index] > maxNombre) {
        maxNombre = dataTalkTime[index];
        setTimeTrend("well");
      }
    }
    const percent = (100 * maxNombre) / sum;
    setPercentTrend(percent);
  };

  const getData = (context: any) => {
    const date = new Date();
    const datenow = date.toISOString().substring(0, 10);
    let referenceUser: string = context.userObjectId;
    const filterCalcul: string = "week";
    meetingService
      .getaverageTalkTimePersonalTab(referenceUser, false)
      .then(async (dataTalkTime: any) => {
        dataTalkTime.json().then((data: any) => setDataTaklTime({ ...data }));
      });
    meetingService
      .getAverageByUser(datenow, referenceUser, filterCalcul)
      .then(async (dataAverageUser: any) => {
        dataAverageUser.json().then((data: any) => {
          setDataAverageUser({ ...data });
        });
      });
    meetingService
      .getAverageByAnimatorForUser(
        referenceUser,
        datenow,
        "animator",
        filterCalcul
      )
      .then(async (dataAverageAnimator: any) => {
        dataAverageAnimator.json().then((data: any) => {
          setDataAverageAnimator({ ...data });
        });
      });
    meetingService
      .getAverageByAllParticipantForUser(referenceUser, datenow, filterCalcul)
      .then(async (dataAverageAllParticipant: any) => {
        dataAverageAllParticipant.json().then((data: any) => {
          setDataAverageAllParticipant({ ...data });
        });
      });
    meetingService.getuserScore(referenceUser).then(async (userScore: any) => {
      userScore.json().then((data: any) => {
        setUserScore(data);
      });
    });
    meetingService
      .getuserInformation(referenceUser)
      .then(async (userInfo: any) => {
        userInfo.json().then((data: any) => {
          setUserInformation({ ...data });
        });
      });
  };

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((contextFrame) => {
      setContext({ ...contextFrame });

      if (
        contextFrame.locale.toString().toLocaleLowerCase() === "fr-fr" ||
        contextFrame.locale.toString().toLocaleLowerCase() === "fr" ||
        contextFrame.locale.toString().toLocaleLowerCase() === "fr-ca"
      ) {
        i18n.changeLanguage("fr");
      } else {
        i18n.changeLanguage("en");
      }
    });
  }, []);

  useEffect(() => {
    if (context) {
      getData(context);
    }
  }, [context]);

  useEffect(() => {
    getSpeakingTimeTrend(dataTalkTime);
  }, [dataTalkTime]);

  return {
    getLabels,
    dataAverageUser,
    dataAverageAnimator,
    dataAverageAllParticipant,
    dataTalkTime,
    userInformation,
    userScore,
    timeTrend,
    percentTrend,
    generateDataAverage,
  };
};

export default useLogic;
