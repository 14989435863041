import { Nav, Tab } from "react-bootstrap";
import { Button } from "react-bootstrap";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const NotAccess = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const lienMarketplace = "https://appsource.microsoft.com/en-us/product/web-apps/neworizon1666340032943.offer_meeteo?tab=Overview";

    return (
        <div>
            <div className="module-setting">
                <p className="meeting-status no-type">
                    {t("All_meeting_done")}
                </p>
            </div>
            <div className="module-content module-content-inmeeting">
                <div className="module-content-top">
                    <p className="module-title-edit">
                        {t("buy_licence")} 
                        {/*<a href={lienMarketplace} target="_blank" rel="noopener noreferrer"> {t("meeteo_licence")}</-a>*/}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotAccess;