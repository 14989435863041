import React from "react";
import { useTeamsFx } from "@microsoft/teamsfx-react/build/cjs/useTeamsFx";
import figureTab from "../components/assets/images/meeteoTab.png";
import "../index.css";
import { authConfig } from "../utils/teamsFxConfig";

export default function Tab() {
  const { themeString } = useTeamsFx(authConfig);
  return (
    <div className={themeString === "default" ? "" : "dark"}>
      {/* <Welcome showFunction={showFunction} /> */}
      <div className="figure-tab">
        <img src={figureTab} title="figureTab" alt="figureTab" />
      </div>
    </div>
  );
}
