import React, { useState, useContext } from "react";
import Premeeting from "./Premeeting";
import InMeeting from "./Containers/InMeeting/Inmeeting";
import PostMeeting from "./Containers/PostMeeting/PostMeeting";
import DataContext from "../Providers/DataProvider/DataContext";
import CustomLoader from "./Components/InMeetingSteps/CustomLoader";
import useLogic from "./Logic/Meeting/useLogic";
import AuthModal from "./Components/AuthModal";
export default function Meeting() {
  const { meeting } = useContext(DataContext);
  const [isPostmeeting, setIsPostmeeting] = useState<boolean>(false);
  const { context, FRAME, frameContext, openAuthModal, setOpenAuthModal } =
    useLogic({
      setIsPostmeeting,
    });

  return (
    <>
      {meeting && (
        <>
          {frameContext === FRAME.MEETING && !isPostmeeting && (
            <>
              <AuthModal
                open={openAuthModal}
                setOpen={setOpenAuthModal}
              ></AuthModal>
              <Premeeting context={context}></Premeeting>
            </>
          )}
          {frameContext === FRAME.IN_MEETING_PANEL && (
            <InMeeting context={context}></InMeeting>
          )}
          {frameContext === FRAME.IN_MEETING_STAGE && (
            <InMeeting context={context}></InMeeting>
          )}
          {frameContext === FRAME.MEETING && isPostmeeting && (
            <PostMeeting></PostMeeting>
          )}
        </>
      )}
      {!meeting && (
        <>
          <CustomLoader showButtonReload={false}></CustomLoader>
        </>
      )}
    </>
  );
}
export { Meeting };
