import { Meeting } from "../models/Meeting";

const API_BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8080"
    : "https://" + process.env.REACT_APP_BACKEND_HOST;

class MeetingService {
  public getMeeting(id: string) {
    return fetch(API_BASE_URL + "/meeting/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public getAverageByUser(
    date: string,
    referenceUser: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-average-user-by-date/" +
        date +
        "/" +
        referenceUser +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getAverageByUserOrganization(
    organisationRef: string,
    date: string,
    referenceUser: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-average-user-by-date-organization/" +
        organisationRef +
        "/" +
        date +
        "/" +
        referenceUser +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public sendMailTo(data: any) {
    return fetch(API_BASE_URL + "/meeting/sendMessage", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mails: data.mails,
        subject: data.subject,
        text: data.text,
        html: data.html,
      }),
    });
    //
  }

  public getAverageByAnimator(
    organizationRef: string,
    date: string,
    roleUser: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-average-animator-by-date/" +
        organizationRef +
        "/" +
        date +
        "/" +
        roleUser +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getAverageByAnimatorForUser(
    userRef: string,
    date: string,
    roleUser: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-average-animator-by-date-user/" +
        userRef +
        "/" +
        date +
        "/" +
        roleUser +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getAverageByAllParticipant(
    organization: string,
    date: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-average-allParticipants-by-date/" +
        organization +
        "/" +
        date +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getAverageByAllParticipantForUser(
    userRef: string,
    date: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-average-allParticipants-by-date-user/" +
        userRef +
        "/" +
        date +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getWheatherAverageByAllParticipant(
    organizationRef: string,
    date: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-wheather-average-allParticipants-by-date/" +
        organizationRef +
        "/" +
        date +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getaverageTalkTimePersonalTab(userRef: string, isAdmin: boolean) {
    return fetch(API_BASE_URL + "/meeting/get-avg/" + userRef, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public getaverageTalkTimeAdminTab(
    organizationRef: string,
    date: string,
    filterCalcul: string
  ) {
    return fetch(
      API_BASE_URL +
        "/meeting/get-talk-time/" +
        organizationRef +
        "/" +
        date +
        "/" +
        filterCalcul,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  public getuserScore(reference: string) {
    return fetch(API_BASE_URL + "/meeting/get-user-score/" + reference, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public getuserInformation(reference: string) {
    return fetch(API_BASE_URL + "/meeting/get-user-information/" + reference, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public createMeeting(meeting: Meeting) {
    return fetch(API_BASE_URL + "/meeting", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(meeting),
    });
  }

  public updateMeeting(meeting: Meeting) {
    return fetch(API_BASE_URL + "/meeting", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(meeting),
    });
  }

  public addAgenda(AgendaData: any) {
    return fetch(API_BASE_URL + "/meeting/add/agenda", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AgendaData),
    });
  }

  public addAction(ActionData: any) {
    return fetch(API_BASE_URL + "/meeting/add/actions", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ActionData),
    });
  }

  public addSurveys(data: any) {
    return fetch(API_BASE_URL + "/meeting/add/surveys", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public addResultat(data: any) {
    return fetch(API_BASE_URL + "/meeting/add/surveys/resultat", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public addRules(data: any) {
    return fetch(API_BASE_URL + "/meeting/add/rules", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public addIndicator(data: any) {
    return fetch(API_BASE_URL + "/meeting/add/indicator", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public addParticipant(data: any) {
    return fetch(API_BASE_URL + "/meeting/add/participant", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  // UPDATE

  public updateParticipant(ParticipantData: any) {
    return fetch(API_BASE_URL + "/meeting/update/participant", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ParticipantData),
    });
  }

  public updateAgenda(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/agenda", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateMain(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/main", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateAction(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/actions", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateSurveys(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/surveys", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateRules(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/rules", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateIndicator(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/indicator", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateModule(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/modules", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateStartTime(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/startTime", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public updateMail(data: any) {
    return fetch(API_BASE_URL + "/meeting/update/mails", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  // DELETE

  public deleteMeeting(id: string) {
    return fetch(API_BASE_URL + "/meeting/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public deleteAgenda(data: any) {
    return fetch(API_BASE_URL + "/meeting/delete/agenda", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public deleteAction(data: any) {
    return fetch(API_BASE_URL + "/meeting/delete/actions", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public deleteSurveys(data: any) {
    return fetch(API_BASE_URL + "/meeting/delete/surveys", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public deleteRules(data: any) {
    return fetch(API_BASE_URL + "/meeting/delete/rules", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public deleteIndicator(data: any) {
    return fetch(API_BASE_URL + "/meeting/delete/indicator", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public deleteParticipant(data: any) {
    return fetch(API_BASE_URL + "/meeting/delete/participant", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
}

export { MeetingService };
