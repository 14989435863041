import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const AddGroup = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <g id="ic_fluent_folder_add_20_filled" transform="translate(-2 -3)">
        <path
          id="Tracé_18594"
          data-name="Tracé 18594"
          d="M9.386,4.289,8.067,3.3a1.5,1.5,0,0,0-.9-.3H4.5A2.5,2.5,0,0,0,2,5.5v1H7.071a.5.5,0,0,0,.362-.156Z"
          fill="#242424"
        />
        <path
          id="Tracé_18595"
          data-name="Tracé 18595"
          d="M10.565,4.5,8.158,7.033A1.5,1.5,0,0,1,7.071,7.5H2v7A2.5,2.5,0,0,0,4.5,17H9.6A5.5,5.5,0,0,1,18,10.257V7l-.005-.164A2.5,2.5,0,0,0,15.5,4.5Z"
          fill="#242424"
        />
        <path
          id="Tracé_18596"
          data-name="Tracé 18596"
          d="M19,14.5A4.5,4.5,0,1,1,14.5,10,4.5,4.5,0,0,1,19,14.5Zm-4-2a.5.5,0,0,0-1,0V14H12.5a.5.5,0,0,0,0,1H14v1.5a.5.5,0,0,0,1,0V15h1.5a.5.5,0,0,0,0-1H15Z"
          fill="#242424"
        />
      </g>
    </SvgIcon>
  );
};

export default AddGroup;
