import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import DataContext from "../../../Providers/DataProvider/DataContext";
import SocketContext from "../../../Providers/SocketProvider/SocketContext";
import { MailTemplate } from "../../../utils/MailTemplate";
import { Participant } from "../../../models/Meeting";
import i18n from "../../../i18n";
import { useGraph } from "@microsoft/teamsfx-react";
import * as microsoftTeams from "@microsoft/teams-js";
import { GraphError } from "@microsoft/microsoft-graph-client";
import { ErrorCode } from "@microsoft/teamsfx";

interface props {
  setIsPostmeeting: React.Dispatch<React.SetStateAction<boolean>>;
}

const SCOPE = [
  "User.Read",
  "Chat.Read",
  "OnlineMeetings.Read",
  "OnlineMeetings.ReadWrite",
  "User.Read.All",
  "Calendars.ReadWrite",
];

const FRAME = {
  IN_MEETING_PANEL: "sidePanel",
  IN_MEETING_STAGE: "meetingStage",
  MEETING: "content",
};

const useLogic = ({ setIsPostmeeting }: props) => {
  const { joinRoom } = useContext(SocketContext);
  const [frameContext, setFrameContext] = useState<string>("");
  const [isJoinedRoom, setisJoinedRoom] = useState<boolean>(false);
  const [isUserInitiat, setisUserInitiat] = useState<boolean>(false);
  const [isFailedMeeting, setisFailedMeeting] = useState<boolean>(false);
  const [mailSend, setMailSend] = useState<boolean>(false);
  const [context, setContext] = useState<any>();
  const [graph, setGraph] = useState<any>(null);
  const [setTeamsFxState] = useState<any>();
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    meeting,
    setMeetingInfo,
    profile,
    initiateUser,
    sendMail,
    updateMainData,
    addParticipantsData,
    deleteParticipantsData,
    getUserByRef,
    initiateMeeting,
  } = useContext(DataContext);

  const intialization = () => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((context) => {
      if (context.frameContext) {
        if (
          context.locale.toString().toLocaleLowerCase() === "fr-fr" ||
          context.locale.toString().toLocaleLowerCase() === "fr" ||
          context.locale.toString().toLocaleLowerCase() === "fr-ca"
        ) {
          i18n.changeLanguage("fr");
        } else {
          i18n.changeLanguage("en");
        }

        setFrameContext(context.frameContext.toString());
        initiateMeeting(context);
        setContext({ ...context });
        setMeetingInfo(graph, context);
      }
    });
  };

  const { loading } = useGraph(
    async (graph: any, teamsfx: any) => {
      await graph
        .api("/me")
        .get()
        .then(() => {})
        .catch((error: any) => {
          console.log("ERROR GRAPH", error);
        });

      setGraph(() => graph);

      //TODO
      setTeamsFxState(() => teamsfx);
    },
    {
      scope: [...SCOPE],
    }
  );

  const participantInTeams = (info: any, event: any) => {
    if (
      meeting?.participants &&
      meeting?.participants?.length &&
      info &&
      event
    ) {
      let newParticipant: Participant[] = [];
      let totalParticipant = [] as Participant[];

      const adminMeeting: Participant = {
        name: `${info.participants.organizer.upn}`.split("@")[0],
        role: "admin",
        reference: info.participants.organizer.identity.user.id,
        email: info.participants.organizer.upn,
      };

      newParticipant.push(adminMeeting);

      info.participants.attendees.map((current: any, index: number) => {
        const attendees: Participant = {
          name: event.attendees[index].emailAddress.name
            ? event.attendees[index].emailAddress.name.split("@")[0]
            : `${current.upn}`.split("@")[0],
          role: "member",
          reference: current.identity.user.id
            ? current.identity.user.id
            : current.upn,
          email: current.upn,
        };
        newParticipant.push(attendees);
      });

      if (
        meeting?.participants &&
        newParticipant.length > meeting?.participants?.length
      ) {
        let mails = [] as string[];
        let tempParticipant = newParticipant.filter((element) => {
          let temp = true;
          meeting?.participants?.forEach((elementBD) => {
            if (element.reference === elementBD.reference) {
              temp = false;
            }
          });
          if (temp) {
            return temp;
          }
        });

        tempParticipant.forEach((element) => {
          if (element?.reference) {
            const temp: Participant = element;
            totalParticipant.push(temp);
            meeting?.participants?.push(temp);
            mails.push(element.email as string);
          }
        });

        addParticipantsData(totalParticipant, meeting);
        const template = MailTemplate(meeting, event, t);
        if (meeting.preMailSend === true) {
          if (mails.length > 0) {
            sendMail({
              mails: [...mails],
              subject: t("notification_reunion"),
              text: "",
              html: template,
            });
          }
        }
      } else if (
        meeting?.participants &&
        newParticipant.length < meeting?.participants?.length
      ) {
        let tempParticipant = meeting?.participants?.filter((element) => {
          let temp = true;
          newParticipant?.forEach((elementBD) => {
            if (element.reference === elementBD.reference) {
              temp = false;
            }
          });
          if (temp) {
            return temp;
          }
        });

        tempParticipant.forEach((element) => {
          let toDel = meeting?.participants?.find(
            (data) => element.reference === data.reference
          );
          let id = meeting?.participants?.findIndex(
            (data) => element.reference === data.reference
          );
          if (toDel) {
            totalParticipant.push(toDel);
            if (id) meeting?.participants?.splice(id, 1);
          }
        });

        deleteParticipantsData(totalParticipant, meeting);
      }
    }
  };

  const fetchData = (graph: any, context: any) => {
    setMeetingInfo(graph, context)
      .then((reponse: any) => {
        setOpenAuthModal(() => false);
        const encodedContext = encodeURI(
          `{"chatId": "${context.chatId}","contextType":"chat"}`
        );

        let link = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_ID}/${context.entityId}?context=${encodedContext}`;

        const newMeeting = {
          _id: meeting._id,
          //_id: context.chatId,
          reference: meeting?.reference ? meeting?.reference : context.chatId,
          participants: [
            {
              name: reponse?.event?.organizer?.emailAddress?.name
                ? reponse?.event?.organizer?.emailAddress?.name
                : `${reponse?.info?.participants?.organizer?.upn}`.split(
                    "@"
                  )[0],
              role: "admin",
              reference:
                reponse?.info?.participants?.organizer?.identity?.user?.id,
              email: reponse?.info?.participants?.organizer?.upn,
            },
            ...reponse?.info?.participants?.attendees
              .filter((elm: any) => elm?.identity?.user?.id || elm?.upn)
              .map((current: any, index: number) => {
                return {
                  name: reponse?.event?.attendees[index]?.emailAddress?.name
                    ? reponse?.event.attendees[index].emailAddress.name.split(
                        "@"
                      )[0]
                    : `${current.upn}`.split("@")[0],
                  role: "member",
                  reference: current?.identity?.user?.id
                    ? current?.identity?.user?.id
                    : current?.upn,
                  email: current?.upn,
                };
              }),
          ],
          meetingInfo: reponse?.info,
          subject: reponse?.info?.subject,
          startDate: reponse?.info?.startDateTime,
          endDate: reponse?.info?.endDateTime,
          preMailSend: false,
          link: link,
          organization: reponse?.organization,
        };

        if (!meeting?.participants || meeting?.participants?.length <= 0) {
          let temp = [
            ...newMeeting?.participants.map((particip: Participant) => {
              return particip.email;
            }),
          ];
          const template = MailTemplate(newMeeting, reponse?.event, t);
          let mails = temp as string[];
          if (mails.length > 0 && !mailSend) {
            setMailSend(() => true);
            sendMail({
              mails: [...mails],
              subject: t("notification_reunion"),
              text: "",
              html: template,
            });
          }
          newMeeting.preMailSend = true;

          updateMainData(newMeeting, context);
          setisFailedMeeting(false);
        }
        if (meeting?.participants && meeting.participants.length > 0) {
          participantInTeams(reponse?.info, reponse?.event);
        }
      })
      .catch((error) => {
        console.log("errorCode", error.code);
        if (
          error instanceof GraphError &&
          error.code?.includes(ErrorCode.UiRequiredError)
        ) {
          setOpenAuthModal(() => true);
        }
        setisFailedMeeting(true);
      });
  };

  // GET GRAPH DATA
  useEffect(() => {
    //console.log("useEffect pour Graph avec : ",loading,context,context?.chatId);
    if (!loading && context && graph && meeting?._id) {
      fetchData(graph, context);
    }
  }, [graph, context, meeting?._id, loading]);

  useEffect(() => {
    intialization();
  }, []);

  // INIT USER
  useEffect(() => {
    if (profile && !isUserInitiat) {
      initiateUser();
      setisUserInitiat(true);
    }
  }, [initiateUser, profile, isUserInitiat]);

  // JOIN ROOM FOR SYNCHRONISATION
  useEffect(() => {
    if (meeting._id && !isJoinedRoom) {
      joinRoom(meeting._id);
      setisJoinedRoom(true);
    }
    if (meeting?.isRunning) setIsPostmeeting(true);
  }, [meeting, isJoinedRoom, joinRoom]);

  useEffect(() => {
    if (isFailedMeeting) {
      setisFailedMeeting(() => !isFailedMeeting);
      setTimeout(() => {
        fetchData(graph, context);
      }, 5000);
    }
  }, [isFailedMeeting]);

  useEffect(() => {
    if (meeting?.participants && meeting?.participants.length > 0)
      getUserByRef(meeting.participants);
  }, [meeting.participants]);

  return {
    context,
    setContext,
    FRAME,
    frameContext,
    openAuthModal,
    setOpenAuthModal,
  };
};

export default useLogic;
