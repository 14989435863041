import React, { useCallback, useEffect, useState, useContext } from "react";
import DataContext from "./DataContext";

import { MeetingService } from "../../services/MeetingService";
import { UserService } from "../../services/UserService";
import {
  Meeting,
  Participant,
  Agenda,
  Action,
  Rule,
  Survey,
  Indicator,
  OptionResult,
  Mail,
  Organization,
} from "../../models/Meeting";

import { User } from "../../models/User";
import SocketContext from "../SocketProvider/SocketContext";
import { POINT } from "../../utils/Point";

import { useGraph } from "@microsoft/teamsfx-react/build/cjs/useGraph";

const DataProvider: React.FC = ({ children }) => {
  const { updateMeeting, setUpdateMeeting, requestUpdate } =
    useContext(SocketContext);

  const [meeting, setMeeting] = useState<Meeting>({});

  const meetingService = new MeetingService();

  const userService = new UserService();

  const [isLoading, setIsloading] = useState<boolean>(true);

  const [hasAccess, setHasAccess] = useState<boolean>(true);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const [contextData, setContextData] = useState<any>();

  const [info, setInfo] = useState<any>();

  const [profile, setProfile] = useState<any>();

  const [chatId, setchatId] = useState<string>("");

  const [objective, setObjective] = useState(meeting.objective);

  const [newMeeting, setNewMeeting] = useState<boolean>(false);

  const [meetingModules, setMeetingModules] = useState<string[]>([]);

  const [isMeetingCreated, setisMeetingCreated] = useState<boolean>(false);

  const [user, setUser] = useState<User>({});

  const [event, setEvent] = useState<any>();

  const [meeteoData, setMeeteoData] = useState<User>({});

  const [userSunny, setUserSunny] = useState<User[]>([]);

  const [organization, setOrganization] = useState<Organization>({});

  const { data } = useGraph(
    async (graph) => {
      // Call graph api directly to get all user profile
      const participants = await graph.api("/users").get();
      return { participants };
    },
    { scope: ["User.Read.All"] }
  );

  const update = useCallback(
    (meetingUp: any) => {
      meetingService
        .updateMeeting(meetingUp)
        .then(async (updatedMeeting$) => {
          await updatedMeeting$.json();
        })
        .catch((error) => {});
    },
    [meeting]
  );

  const updateUser = useCallback(
    (userData: any) => {
      userService
        .updateUser(userData)
        .then(async (userData$) => {
          const user = await userData$.json();
          setUser({ ...user });
        })
        .catch((error) => {});
    },
    [meeting]
  );

  const updateParticipant = (participantUp: any) => {
    meetingService
      .updateParticipant(participantUp)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const sendMail = (mail: Mail) => {
    meetingService
      .sendMailTo(mail)
      .then(async (res$) => {
        await res$.json();
      })
      .catch((error) => {});
  };

  const putAction = (actionData: any) => {
    meetingService
      .addAction(actionData)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const putRule = (data: any) => {
    meetingService
      .addRules(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const putSurvey = (data: any) => {
    meetingService
      .addSurveys(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const putResultat = (data: any) => {
    meetingService
      .addResultat(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const putIndicator = (data: any) => {
    meetingService
      .addIndicator(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const putParticipant = (data: any) => {
    meetingService
      .addParticipant(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const putAgenda = (actionData: any) => {
    meetingService
      .addAgenda(actionData)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const deleteActionService = (actionData: any) => {
    meetingService
      .deleteAction(actionData)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const deleteRuleService = (data: any) => {
    meetingService
      .deleteRules(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const deleteSurveyService = (data: any) => {
    meetingService
      .deleteSurveys(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const deleteIndicatorService = (data: any) => {
    meetingService
      .deleteIndicator(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const deleteParticipantService = (data: any) => {
    meetingService
      .deleteParticipant(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const deleteAgendaService = (actionData: any) => {
    meetingService
      .deleteAgenda(actionData)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateActionService = (actionData: any) => {
    meetingService
      .updateAction(actionData)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateRuleService = (data: any) => {
    meetingService
      .updateRules(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateSurveyService = (data: any) => {
    meetingService
      .updateSurveys(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateIndicatorService = (data: any) => {
    meetingService
      .updateIndicator(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateModuleService = (data: any) => {
    meetingService
      .updateModule(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateAgendaService = (actionData: any) => {
    meetingService
      .updateAgenda(actionData)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateMainService = (data: any) => {
    meetingService
      .updateMain(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateStartTimeService = (data: any) => {
    meetingService
      .updateStartTime(data)
      .then(async (updatedMeeting$) => {
        await updatedMeeting$.json();
      })
      .catch((error) => {});
  };

  const updateMailService = (data: any) => {
    meetingService
      .updateMail(data)
      .then(async (updatedMeeting$) => {})
      .catch((error) => {});
  };

  const createNewMeeting = (context: any) => {
    if (!isMeetingCreated && !isLoading) {
      setIsloading(true);
      meetingService
        .getMeeting(context.chatId as string)
        .then(async (meeting$) => {
          const data = await meeting$.json();
          if (data && data.error && data.error.code === 404) {
            const encodedContext = encodeURI(
              `{"chatId": "${context.chatId}","contextType":"chat"}`
            );

            let link = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_ID}/${context.entityId}?context=${encodedContext}`;
            
            //console.log("Contexte 1 : " , context);
            
            let newMeeting: Meeting = {
              reference: context.chatId,
              participants: [],
              modules: ["participants", "agenda", "rules"],
              rules: [],
              subject: "",
              link: link,
            };
            
           // userService.getUserAccess(context);

            if (event && info)
              newMeeting = constructMeeting(context, event, info);



            meetingService
              .createMeeting(newMeeting)
              .then(async (createdMeeting$) => {
                const createdMeeting = await createdMeeting$.json();
                if (createdMeeting && !createdMeeting?.error) {
                  setNewMeeting(false);
                  setisMeetingCreated(true);
                  setMeeting({ ...createdMeeting });
                  setHasAccess(true);

                  //On mets a jour la date du dernier meeting créé par l'utilisateur
                  console.log("Mise a jour de la date du dernier meeting");
                  userService
                  .getUser(info?.participants?.organizer?.identity?.user?.id as string)
                  .then(async (user$) => {
                    const data = await user$.json();

                    if (!(data && data.error && data.error.code === 404)) {
                      const userData: User = data;
                      let dateNow = new Date(Date.now());
                      let serverDif = 0;
                      if (userData?.lastMeetingOwner)
                        serverDif = dateNow.getTime() - userData?.lastMeetingOwner?.getTime(); 
                      if (serverDif > 0) {
                        userData.lastMeetingOwner = dateNow;
                        updateUser(userData);
                      }
                    }
                  })
                  .catch((error) => {});//test

                } else console.log(createdMeeting?.error);
                setIsloading(false);
                
              })
              .catch((error) => {
                setIsloading(false);
              });
          } else {
            setHasAccess(true);
            setMeeting({ ...data });
            setisMeetingCreated(true);
            setNewMeeting(false);
            setMeetingModules([...data?.modules]);
            setIsloading(false);
          }
          setContextData(context);
        })
        .catch((error) => {
          console.log("Creation Error ", error);
          setIsloading(false);
        });
    }
  };

  const constructMeeting = (context: any, event: any, info: any): Meeting => {
    const encodedContext = encodeURI(
      `{"chatId": "${context.chatId}","contextType":"chat"}`
    );

    let link = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_ID}/${context.entityId}?context=${encodedContext}`;

    let newMeeting: Meeting = {
      reference: context.chatId,
      participants: [],
      modules: ["participants", "agenda", "rules", "indicator", "survey"],
      rules: [],
      subject: "",
      link: link,
    };
    if (event && info && organization)
      newMeeting = {
        reference: chatId,
        participants: [
          {
            name: event?.organizer?.emailAddress?.name
              ? event?.organizer?.emailAddress?.name
              : `${info?.participants?.organizer?.upn}`.split("@")[0],
            role: "admin",
            reference: info?.participants?.organizer?.identity?.user?.id,
            email: info?.participants?.organizer?.upn,
          },
          ...info?.participants?.attendees
            .filter((elm: any) => elm?.identity?.user?.id || elm?.upn)
            .map((current: any, index: number) => {
              return {
                name: event?.attendees[index]?.emailAddress?.name
                  ? event.attendees[index].emailAddress.name.split("@")[0]
                  : `${current.upn}`.split("@")[0],
                role: "member",
                reference: current?.identity?.user?.id
                  ? current?.identity?.user?.id
                  : current?.upn,
                email: current?.upn,
              };
            }),
        ],
        modules: ["participants", "agenda", "rules"],
        rules: [],
        meetingInfo: info,
        subject: info?.subject,
        startDate: info?.startDateTime,
        endDate: info?.endDateTime,
        link: link,
        organization: organization,
      };

    // profile: profile,
    return newMeeting;
  };

  const initiateMeeting = (context: any) => {
    // setObjective(meeting.objective);
    if (context && context?.chatId !== undefined) {
      setchatId(context.chatId);
      setContextData(context);
    }

    // INIT PROFIL USER
    let tempProfil = {
      "@odata.context":
        "https://graph.microsoft.com/v1.0/$metadata#users/$entity",
      businessPhones: [],
      displayName: `${context.userPrincipalName}`.split("@")[0],
      givenName: "",
      jobTitle: null,
      mail: context.upn,
      mobilePhone: null,
      officeLocation: null,
      preferredLanguage: context.local,
      surname: "",
      userPrincipalName: context.userPrincipalName,
      id: context.userObjectId,
      userObjectId: context.userObjectId,
    };
    setProfile({ ...tempProfil });

    meetingService
      .getMeeting(context.chatId as string)
      .then(async (meeting$) => {
        const data = await meeting$.json();
        //console.log("Data ",data);
        //la reunion n'a pas encore été créée
        if (data?.error && data?.error?.code === 404) {  
        //const retourAccess = await userAccess;
        //L'utilisateur n'a pas le droit de créer la reunion
          const newMeeting = constructMeeting(context, event, info);
          meetingService
            .createMeeting(newMeeting)
            .then(async (createdMeeting$) => {
              const createdMeeting = await createdMeeting$.json();
              if (createdMeeting && !createdMeeting?.error) {
                setHasAccess(true);
                setMeeting({ ...createdMeeting });
                setObjective(createdMeeting.objective);
                addPointUser(context, POINT.meeteoUsedPoint);
              } else console.log("error initiation", createdMeeting?.error);
            })
            .catch((error) => {});
        } else {
          setHasAccess(true);
          setMeeting({ ...data });
          setObjective(data.objective);
          setMeetingModules([...data.modules]);
          setIsloading(false);
        }
        setContextData(context);
      })
      .catch((error) => {});
  };

  const addPointUser = (context: any, point: number) => {
    if (context)
      userService
        .getUser(context?.userObjectId as string)
        .then(async (user$) => {
          const data = await user$.json();

          if (!(data && data.error && data.error.code === 404)) {
            const userData: User = data;
            if (userData?.score?.point && userData?.score?.total) {
              userData.score.point += point;
              userData.score.total += point;
              insertUser(userData);
            }
          }
        })
        .catch((error) => {});
  };

  const refreshMeeting = useCallback(
    (context) => {
      setIsRefreshing(true);
      meetingService
        .getMeeting(context.chatId.toString())
        .then(async (meeting$) => {
          meeting$
            .json()
            .then((data) => {
              setMeeting({ ...data });
              setUpdateMeeting(false);
              setIsRefreshing(false);
            })
            .catch(() => {
              setUpdateMeeting(false);
            });
        });
    },
    [meeting]
  );

  const setMeetingInfo = (graph: any, context: any) => {
    console.log("Appel de setMeetingInfo");
    return new Promise((resolve, reject) => {
      if (graph && context) {
        graph
          .api("me")
          .get()
          .then((data: any) => {
            console.log("Data GRAPH Meeting Info: ",data);
            //Appel de l'API Graph pour les licences de l'utilisateur
            graph
            .api(`users/${data.id}/usageRights`)
            .version('beta')
            .filter('state in (\'active\') and serviceIdentifier in (\'neworizon1666340032943.offer_meeteo.freeplan\',\'neworizon1666340032943.offer_meeteo.standard_plan\') ')
            .get()
            .then((dataUsage: any) => {
              console.log("Retours usageRights : ",dataUsage);
              if(context.tid === "59e0a973-a1b7-46df-85a4-31e2cc290e2f" || dataUsage?.value.length > 0){
                setHasAccess(true);
                setProfile({ ...data });
                graph
                  .api(`/me/chats/${context.chatId}`)
                  .get()
                  .then((chatDetails: any) => {
                    const meetingID = `1*${chatDetails.onlineMeetingInfo.organizer.id}*0**${context.chatId}`;
                    const encodedMeetingId = btoa(meetingID);
                    graph
                      .api(`/me/onlineMeetings/${encodedMeetingId}`)
                      .get()
                      .then((details: any) => {
                        graph
                          .api(
                            `/me/calendar/events?$filter=subject eq '${details.subject}'`
                          )
                          .get()
                          .then((calendar: any) => {
                            let event = calendar.value.filter((element: any) => {
                              return (
                                element.onlineMeeting.joinUrl === details.joinUrl
                              );
                            });
                            graph
                              .api("organization/")
                              .get()
                              .then((organisationData: any) => {
                                const org = {
                                  id: organisationData?.value[0]?.id,
                                  displayName:
                                    organisationData?.value[0]?.iddisplayName,
                                  postalCode:
                                    organisationData?.value[0]?.postalCode,
                                  state: organisationData?.value[0]?.state,
                                  street: organisationData?.value[0]?.street,
                                };

                                if (event[0]) {
                                  setOrganization(() => org);
                                  setEvent(event[0]);
                                  setInfo(details);
                                  resolve({
                                    event: event[0],
                                    info: details,
                                    profil: data,
                                    organization: org,
                                  });
                                } else reject("fetch error " + event);
                              })
                              .catch((error: any) => {
                                reject(error);
                              });
                          })
                          .catch((error: any) => {
                            reject(error);
                          });
                      })
                      .catch((error: any) => {
                        reject(error);
                      });
                  })
                  .catch((error: any) => {
                    reject(error);
                  });
              }else{
                console.log("L'utilisateur n'a pas de licence");
                setHasAccess(false);
              }
            })    
            .catch((error: any) => {
              console.log("Erreur Usage : ",error);
              setHasAccess(false);
              reject(error);
            });                        
        }).catch((error: any) => {
          reject(error);
        });
      }
    });
  };

  const insertMeeting = (meeting: Meeting) => {
    if (contextData && meeting && !isRefreshing) {
      update(meeting);
    }
    setMeeting(meeting);
  };

  const insertUser = (user: User) => {
    if (user) {
      updateUser(user);
    }
    setUser({ ...user });
  };

  const insertParticipant = (
    participant: Participant,
    _id: any,
    meeting: Meeting
  ) => {
    if (contextData && meeting && meeting.participants && _id) {
      updateParticipant({
        _id: _id,
        reference: participant.reference,
        data: participant,
      });
    }
    setMeeting(() => {
      return { ...meeting };
    });
  };

  //  ADD DATA
  const addAgendaData = (agenda: Agenda, meeting: Meeting) => {
    if (contextData && meeting) {
      putAgenda({
        _id: meeting._id,
        data: agenda,
      });

      addPointUser(contextData, POINT.elementAddPoint);
    }
    setMeeting({ ...meeting });
  };

  const addAction = (action: Action, meeting: Meeting) => {
    if (contextData && meeting) {
      putAction({
        _id: meeting._id,
        data: action,
      });

      addPointUser(contextData, POINT.elementAddPoint);
    }
    setMeeting({ ...meeting });
  };

  const addRules = (rule: Rule, meeting: Meeting) => {
    if (contextData && meeting) {
      putRule({
        _id: meeting._id,
        data: rule,
      });

      addPointUser(contextData, POINT.elementAddPoint);
    }
    setMeeting({ ...meeting });
  };

  const addSurveysData = (survey: Survey, meeting: Meeting) => {
    if (contextData && meeting) {
      putSurvey({
        _id: meeting._id,
        data: survey,
      });

      addPointUser(contextData, POINT.elementAddPoint);
    }
    setMeeting({ ...meeting });
  };

  const addResultatData = (
    resultat: OptionResult,
    meeting: Meeting,
    resultatId: string
  ) => {
    if (contextData && meeting) {
      putResultat({
        _id: meeting._id,
        data: { resultat: resultat, _id: resultatId },
      });
    }
    setMeeting({ ...meeting });
  };

  const addIndicatorData = (indicator: Indicator, meeting: Meeting) => {
    if (contextData && meeting) {
      putIndicator({
        _id: meeting._id,
        data: indicator,
      });

      addPointUser(contextData, POINT.elementAddPoint);
    }
    setMeeting({ ...meeting });
  };

  const addParticipantsData = (
    participants: Participant[],
    meeting: Meeting
  ) => {
    if (contextData && meeting) {
      participants.forEach((participant) => {
        putParticipant({
          _id: meeting._id,
          data: participant,
        });
      });
    }
    setMeeting({ ...meeting });
  };

  // UPDATE DATA
  const updateAgendaData = (agenda: Agenda, meeting: Meeting) => {
    if (contextData && meeting) {
      updateAgendaService({
        _id: meeting._id,
        data: agenda,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateMainData = (meeting: Meeting, context: any) => {
    if (context && meeting) {
      updateMainService({
        _id: meeting._id,
        data: meeting,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateActionData = (action: Action, meeting: Meeting) => {
    if (contextData && meeting) {
      updateActionService({
        _id: meeting._id,
        data: action,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateRulesData = (rule: Rule, meeting: Meeting) => {
    if (contextData && meeting) {
      updateRuleService({
        _id: meeting._id,
        data: rule,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateSurveysData = (survey: Survey, meeting: Meeting) => {
    if (contextData && meeting) {
      updateSurveyService({
        _id: meeting._id,
        data: survey,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateIndicatorData = (indicator: Indicator, meeting: Meeting) => {
    if (contextData && meeting) {
      updateIndicatorService({
        _id: meeting._id,
        data: indicator,
      });
    }
    setMeeting({ ...meeting });
  };
  const updateModuleData = (module: string[], meeting: Meeting) => {
    if (contextData && meeting) {
      updateModuleService({
        _id: meeting._id,
        data: module,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateStartTimeData = (meeting: Meeting) => {
    if (contextData && meeting) {
      updateStartTimeService({
        _id: meeting._id,
        data: meeting,
      });
    }
    setMeeting({ ...meeting });
  };

  const updateMailData = (meeting: Meeting) => {
    if (contextData && meeting) {
      updateMailService({
        _id: meeting._id,
        data: meeting,
      });
    }
    setMeeting({ ...meeting });
  };

  const deleteAgendaData = (agenda: Agenda, meeting: Meeting) => {
    if (contextData && meeting) {
      deleteAgendaService({
        _id: meeting._id,
        data: agenda,
      });
    }
    setMeeting({ ...meeting });
  };

  const deleteActionData = (action: Action, meeting: Meeting) => {
    if (contextData && meeting) {
      deleteActionService({
        _id: meeting._id,
        data: action,
      });
    }
    setMeeting({ ...meeting });
  };

  const deleteRulesData = (rule: Rule, meeting: Meeting) => {
    if (contextData && meeting) {
      deleteRuleService({
        _id: meeting._id,
        data: rule,
      });
    }
    setMeeting({ ...meeting });
  };

  const deleteSurveysData = (survey: Survey, meeting: Meeting) => {
    if (contextData && meeting) {
      deleteSurveyService({
        _id: meeting._id,
        data: survey,
      });
    }
    setMeeting({ ...meeting });
  };

  const deleteIndicatorData = (indicator: Indicator, meeting: Meeting) => {
    if (contextData && meeting) {
      deleteIndicatorService({
        _id: meeting._id,
        data: indicator,
      });
    }
    setMeeting({ ...meeting });
  };

  const deleteParticipantsData = (
    participants: Participant[],
    meeting: Meeting
  ) => {
    if (contextData && meeting) {
      participants.forEach((participant) => {
        deleteParticipantService({
          _id: meeting._id,
          data: participant,
        });
      });
    }
    setMeeting({ ...meeting });
  };

  useEffect(() => {
    if (updateMeeting && contextData) {
      refreshMeeting(contextData);
    }
  }, [updateMeeting]);

  const createNewUser = () => {
    const newUser: User = {
      reference: profile?.id,
      groupe: [],
      score: {
        meetingId: [],
        point: POINT.defaultInitialPoint,
        total: POINT.defaultInitialPoint,
      },
      avatar: {
        equipped: [1],
        current: 1,
      },
    };

    userService
      .createUser(newUser)
      .then(async (createdUser$: any) => {
        const createdUser = await createdUser$.json();

        if (createdUser && !createdUser.error) {
          setUser({ ...createdUser });
        } else console.log(createdUser.error);
      })
      .catch((error: any) => {});
  };

  const initiateUser = () => {
    userService
      .getUser(profile?.id as string)
      .then(async (user$) => {
        const data = await user$.json();

        if (data && data.error && data.error.code === 404) {
          createNewUser();
        } else {
          setUser({ ...data });
        }
      })
      .catch((error) => {});
  };

  const getUserByRef = (participants: Participant[]) => {
    let temp: string[] = [];
    participants.forEach((element: Participant) => {
      if (element.reference) {
        temp.push(element.reference);
      }
    });
    userService
      .getUsers(temp)
      .then(async (user$) => {
        const data = await user$.json();
        if (data && data.error && data.error.code === 404) {
          // setUserSunny([]);
        } else {
          setUserSunny([...data]);
        }
      })
      .catch((error) => {
        // setUserSunny([]);
      });
  };

  const getMeeteoInfo = () => {
    userService
      .getUser("Meeteo_Global")
      .then(async (user$) => {
        const data = await user$.json();

        if (data && data.error && data.error.code === 404) {
          const newUser: User = {
            reference: "Meeteo_Global",
            created: new Date(Date.now()),
          };

          userService
            .createUser(newUser)
            .then(async (createdUser$: any) => {
              const createdUser = await createdUser$.json();

              if (createdUser && !createdUser.error) {
                setMeeteoData({ ...createdUser });
              } else console.log(createdUser.error);
            })
            .catch((error: any) => {});
        } else {
          setMeeteoData({ ...data });
        }
      })
      .catch((error) => {});
  };

  const setUserProfil = (graph: any, context: any, count: number) => {
    if (graph) {
      graph
        .api("me")
        .get()
        .then((data: any) => {
          setProfile({ ...data });
          console.log("Data GRAPH : ",data);
            //Appel de l'API Graph pour les licences de l'utilisateur
            graph
            .api(`users/${data.id}/usageRights`)
            .version('beta')
            .filter('state in (\'active\') and serviceIdentifier in (\'neworizon1666340032943.offer_meeteo.freeplan\',\'neworizon1666340032943.offer_meeteo.standard_plan\') ')
            .get()
            .then((dataUsage: any) => {
              console.log("Retours usageRights : ",dataUsage);
              if(context.tid === "59e0a973-a1b7-46df-85a4-31e2cc290e2f" || dataUsage?.value.length > 0){
                setHasAccess(true);
              }
            }).catch((error: any) => {
              console.log("Erreur Usage : ",error);
              setHasAccess(false);
            });
        })
        .catch(() => {
          if (count > 0) {
            let temp = count - 1;
            setUserProfil(graph, context, temp);
          } else {
            let tempProfil = {
              "@odata.context":
                "https://graph.microsoft.com/v1.0/$metadata#users/$entity",
              businessPhones: [],
              displayName: `${context.userPrincipalName}`.split("@")[0],
              givenName: "",
              jobTitle: null,
              mail: "alexandre.lovergne@wimtim.com",
              mobilePhone: null,
              officeLocation: null,
              preferredLanguage: context.local,
              surname: "",
              userPrincipalName: context.userPrincipalName,
              id: context.userObjectId,
            };
            setProfile({ ...tempProfil });
          }
        });
    }
  };

  return (
    <DataContext.Provider
      value={{
        isLoading,
        meeting,
        setMeeting,
        createNewMeeting,
        initiateMeeting,
        refreshMeeting,
        hasAccess,
        isRefreshing,
        setMeetingInfo,
        setUserProfil,
        profile,
        info,
        setContextData,
        objective,
        setObjective,
        meetingModules,
        setMeetingModules,
        newMeeting,
        // update,
        insertMeeting,
        insertParticipant,

        addAgendaData,
        addAction,
        addIndicatorData,
        addSurveysData,
        addRules,
        addResultatData,
        addParticipantsData,

        updateAgendaData,
        updateMainData,
        updateActionData,
        updateRulesData,
        updateSurveysData,
        updateIndicatorData,
        updateModuleData,

        deleteAgendaData,
        deleteActionData,
        deleteRulesData,
        deleteSurveysData,
        deleteIndicatorData,
        deleteParticipantsData,

        updateStartTimeData,
        updateMailData,

        createNewUser,
        initiateUser,
        user,
        setUser,
        insertUser,
        setEvent,
        event,
        sendMail,
        getMeeteoInfo,

        setMeeteoData,
        meeteoData,

        getUserByRef,
        userSunny,
        addPointUser,
        contextData,
        organization,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
