import React, { useState, useEffect, useContext } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { Form, Modal, Button } from "react-bootstrap";
import profileOutline from "../../assets/images/profile-outline.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Action } from "../../../models/Meeting";
import DataContext from "../../../Providers/DataProvider/DataContext";

interface ActionInterface {}

const ActionPost: React.FC<ActionInterface> = () => {
  const { t } = useTranslation();

  const { meeting, profile, insertMeeting, addAction } =
    useContext(DataContext);
  const [person, setPerson] = React.useState(0);
  const [date, setDate] = React.useState("date");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldAction, setFieldAction] = useState<string>("");
  const [currentDeleteId, setCurrentDeleteId] = useState<number>(-1);
  const [isAddDisable, setIsAddDisable] = useState<boolean>(true);
  const [isAdmin, setisAdmin] = useState<boolean>(false);
  const [isAnimateur, setisAnimateur] = useState<boolean>(false);
  const [user, setUser] = useState("");
  const [role, setrole] = useState<string>("");
  const [deadline, setDeadline] = React.useState(new Date());

  useEffect(() => {
    if (profile !== undefined) setUser(profile?.id);
  }, []);

  useEffect(() => {
    if (user) {
      let temp = meeting?.participants?.find(
        (participant) => participant.reference + "" === user + ""
      );

      setrole(temp && temp?.role ? temp?.role : "member");
      if (temp?.role === "admin") {
        setisAdmin(true);
      }
      if (temp?.role === "animator") {
        setisAnimateur(true);
      }
    }
  }, [user]);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleShowDeleteModal = (index: number) => {
    setShowDeleteModal(true);
    setCurrentDeleteId(index);
  };

  const handleAddAction = () => {
    meeting.actions?.push({
      types: fieldAction,
      inCharge: "",
      date: new Date(),
      checked: false,
      creator: user,
    });

    addAction(
      {
        types: fieldAction,
        inCharge: "",
        date: new Date(),
        checked: false,
        creator: user,
      },
      meeting
    );
    setFieldAction("");
  };

  const handleChangePerson = (index: number, e: any) => {
    let temp =
      meeting !== undefined && meeting?.actions ? meeting?.actions[index] : {};
    temp.inCharge = e.target.value;
    meeting?.actions?.splice(index, 1, temp);
    insertMeeting({ ...meeting });
    setPerson(e.target.value);
  };

  const handleChangeDateCheckBox = (event: any, index: number) => {
    if (event.target.value === "immediate") {
      let temp =
        meeting !== undefined && meeting?.actions
          ? meeting?.actions[index]
          : {};
      temp.date = new Date();
      temp.checked = true;
      meeting.actions?.splice(index, 1, temp);
      insertMeeting({ ...meeting });
      setDeadline(new Date());
    } else {
      let temp =
        meeting !== undefined && meeting?.actions
          ? meeting?.actions[index]
          : {};
      temp.checked = false;
      meeting?.actions?.splice(index, 1, temp);
      insertMeeting({ ...meeting });
    }
  };

  const handleChangeDate = (date: Date, index: number) => {
    let temp =
      meeting !== undefined && meeting?.actions ? meeting?.actions[index] : {};
    temp.date = date;
    meeting?.actions?.splice(index, 1, temp);
    insertMeeting({ ...meeting });
    setDeadline(date);
  };

  const fieldHandler = (e: any) => {
    setFieldAction(e.target.value);
  };

  const deleteAction = (index: number) => {
    meeting?.actions?.splice(index, 1);
    insertMeeting({ ...meeting });
  };

  useEffect(() => {
    if (fieldAction === "") {
      setIsAddDisable(true);
    } else {
      setIsAddDisable(false);
    }
  }, [fieldAction]);

  return (
    <>
      <div className="module-item action">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-data-usage"></i>
            </span>
            Action
          </h3>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">{t("ThetakenAction")}</p>

          <div className="module-main-content">
            <div className="module-add-list-wrapper custom-scroll">
              <ul className="module-add-list">
                {meeting?.actions?.map((action: Action, index: number) => (
                  <li className="module-list-item " key={index}>
                    <div className="module-list-item-action">
                      <span className="type">{action?.types}</span>
                      <span
                        className={
                          isAdmin || isAnimateur || action.creator === user
                            ? "btn remove-list-item"
                            : "btn remove-list-item disabled"
                        }
                        title="Delete Action"
                        onClick={() => {
                          // handleShowDeleteModal(index);
                        }}
                      >
                        {/* <i className="ico-delete"></i> */}
                      </span>
                    </div>

                    <div className="module-list-item-form">
                      <div className="form-group">
                        <label>{t("the_person_in_charge")}:</label>
                        <div className="form-group-input">
                          <span className="icon">
                            <img src={profileOutline} alt="Status Icon" />
                          </span>
                          <Select
                            className="form-control h-28"
                            value={action.inCharge}
                            IconComponent={() => <KeyboardArrowDownIcon />}
                            onChange={(e) => handleChangePerson(index, e)}
                            disabled
                          >
                            <MenuItem disabled value="0">
                              <span className="disable-item">
                                {t("select_a_person")}
                              </span>
                            </MenuItem>
                            {meeting?.participants?.map(
                              (participant, index) => {
                                return (
                                  <MenuItem
                                    value={participant.reference}
                                    key={index}
                                  >
                                    {participant.name}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>{t("deadline")}:</label>
                        <div className="select-deadline">
                          <div className="form-check form-radio">
                            <Form.Check
                              type="radio"
                              // id="dateDeadline-1"
                              disabled
                              name={"selectDeadline" + index}
                              value={"date"}
                              onChange={(event) =>
                                handleChangeDateCheckBox(event, index)
                              }
                              checked={!action.checked}
                            />
                            <label htmlFor="dateDeadline-1">Date</label>
                          </div>
                          <div className="form-check form-radio">
                            <Form.Check
                              type="radio"
                              // id="dateDeadline-2"
                              disabled
                              value={"immediate"}
                              name={"selectDeadline" + index}
                              onChange={(event) =>
                                handleChangeDateCheckBox(event, index)
                              }
                              checked={action.checked}
                            />
                            <label htmlFor="dateDeadline-2">
                              {t("immediate")}
                            </label>
                          </div>
                        </div>
                        <div className="form-group-input">
                          <span className={action.checked ? "disable" : ""}>
                            <i className="ico-calendar2"></i>
                          </span>
                          <DatePicker
                            selected={
                              new Date(Date.parse(action?.date!.toString()))
                            }
                            onChange={(date: Date) =>
                              handleChangeDate(date, index)
                            }
                            className="custom-datepicker form-control h-28"
                            // disabled={action.checked}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Modal delete */}
      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">Delete action</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="remove">
          <p>Are you sure you want delete the item from the action?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCloseDeleteModal}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionPost;
