import { useEffect, useState, useContext } from "react";
import { Indicator, Meeting } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";
import { dateToString } from "../../../utils/FormatDate";
import DataContext from "../../../Providers/DataProvider/DataContext";

interface props {
  meeting: Meeting;
  context: any;
  isDeleteConfirmed: boolean;
  indicatorIndex: number;
  deactivateModule: Function;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setShowDeleteConfirmationAction: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
  setIndicatorIndex: React.Dispatch<React.SetStateAction<number>>;
  activeTitle: number;
}

interface History {
  user: string;
  date: string;
  status: string;
  actions: string;
}

const useLogic = ({
  meeting,
  context,
  deactivateModule,
  isDeleteConfirmed,
  indicatorIndex,
  setMeeting,
  setShowDeleteConfirmationAction,
  setIsDeleteConfirmed,
  setIndicatorIndex,
  setActiveTitle,
  activeTitle,
}: props) => {
  const { t } = useTranslation();

  const {
    insertMeeting,
    addIndicatorData,
    updateIndicatorData,
    deleteIndicatorData,
  } = useContext(DataContext);

  const [newIndicator, setNewIndicator] = useState<Indicator>({
    title: "",
    description: "",
    status: "",
    action: "",
    trend: "",
    history: [],
  });

  const [editedIndicator, setEditedIndicator] = useState<Indicator>({
    title: "",
    description: "",
    status: "",
    action: "",
    trend: "",
    history: [],
  });

  const [deleteText] = useState({
    title: t("remove.indicator.title"),
    text: t("remove.indicator.confirmation"),
  });

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showAddIndicatorModal, setShowAddIndicatorModal] = useState(false);
  const [showAddIndicator, setShowAddIndicator] = useState(false);
  const [currentIndicatorIndex, setCurrentIndicatorIndex] = useState(0);
  const [isShowAction, setIsShowAction] = useState(false);
  const [indicatorActions, setIndicatorAction] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [editedIndex, setEditedIndex] = useState(-1);
  const [showHistory, setShowHistory] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [user, setUser] = useState("");

  useEffect(() => {
    if (meeting?.participants && context) {
      let id = meeting?.participants?.findIndex(
        (element) => element.reference === context.userObjectId
      );
      if (id >= 0) setUser(meeting?.participants[id].name as string);
    }
  }, [meeting.participants]);

  useEffect(() => {
    if (isDeleteConfirmed && activeTitle === 1) {
      deleteIndicator(indicatorIndex);
      setIsDeleteConfirmed(false);
    }
  }, [isDeleteConfirmed]);

  const handleToggleAddModal = () => {
    setShowAddIndicatorModal(!showAddIndicatorModal);
  };

  const handleHistoryIndicatorModalClick = (index: number) => {
    setHistoryIndex(index);
    setShowHistoryModal(!showHistoryModal);
  };

  const handleHistoryIndicatorlClick = (index: number) => {
    setHistoryIndex(index);
    setShowHistory(!showHistory);
  };

  const handleToggleDeleteModal = (index: number) => {
    setShowDeleteModal(true);
    setCurrentIndicatorIndex(index);
    setActiveTitle(1);
    setShowDeleteConfirmationAction(true);
    setIndicatorIndex(index);
    //deleteIndicator(index);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setNewIndicator((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleIndicatorAction = () => {
    setIndicatorAction(!indicatorActions);
  };

  const onConfirmClicked = () => {
    setLoading(true);
    const newDate = new Date();
    let tempHistory: History = {
      user: "",
      date: "",
      status: "",
      actions: "",
    };
    tempHistory.date = dateToString(newDate);
    tempHistory.user = user;
    if (newIndicator && newIndicator.status)
      tempHistory.status = newIndicator.status;
    if (newIndicator && newIndicator.action)
      tempHistory.actions = newIndicator.action;
    newIndicator.history = [];
    newIndicator.history.push(tempHistory);
    meeting.indicator?.push(newIndicator);
    addIndicatorData(newIndicator, meeting);
    setShowAddIndicatorModal(false);
    handleCancelClicked();
    setShowAddIndicator(false);
  };

  const onConfirmChangeClicked = () => {
    if (meeting.indicator && meeting.indicator.length) {
      const newDate = new Date();
      let tempHistory: History = {
        user: "",
        date: "",
        status: "",
        actions: "",
      };
      tempHistory.date = dateToString(newDate);
      tempHistory.user = user;
      if (editedIndicator && editedIndicator.status)
        tempHistory.status = editedIndicator.status;
      if (editedIndicator && editedIndicator.action)
        tempHistory.actions = editedIndicator.action;
      editedIndicator.history?.push(tempHistory);
      meeting.indicator[editedIndex] = editedIndicator;
      updateIndicatorData(editedIndicator, meeting);
    }
    setEditedIndex(-1);
  };

  const deleteIndicator = (index: number) => {
    let temp =
      meeting && meeting.indicator
        ? (meeting.indicator[index] as Indicator)
        : ({} as Indicator);

    meeting.indicator?.splice(index, 1);
    deleteIndicatorData(temp, meeting);
    setShowDeleteModal(false);
  };

  const cancelChangeStatus = (index: number) => {
    setEditedIndicator({});
    setEditedIndex(-1);
  };

  const handleChangeStatus = (event: any, index: number) => {
    const { name, value } = event.target;
    setEditedIndicator((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleIndicatorChangeStatus = (event: any, index: number) => {
    setHistoryIndex(index);
    if (meeting && meeting.indicator?.length) {
      setEditedIndicator({ ...meeting.indicator[index] });
    }
    setEditedIndex(index);
  };

  const handleCancelClicked = () => {
    setNewIndicator({
      title: "",
      description: "",
      status: "",
      action: "",
      trend: "",
      history: [],
    });
    setIndicatorAction(true);
    setIsShowAction(false);
    setShowAddIndicatorModal(false);
    setLoading(false);
  };

  const getStatusOrTrend = (text: string | undefined) => {
    let statusTraduction = "";
    switch (text) {
      case "Good":
        statusTraduction = t("indicator_module.status.good");
        break;
      case "Average":
        statusTraduction = t("indicator_module.status.average");
        break;
      case "Low":
        statusTraduction = t("indicator_module.status.low");
        break;
      case "Ascendant":
        statusTraduction = t("indicator_module.trend.ascendant");
        break;
      case "Stagnant":
        statusTraduction = t("indicator_module.trend.stagnant");
        break;
      case "Descendant":
        statusTraduction = t("indicator_module.trend.descendant");
        break;
      default:
        statusTraduction = "";
        break;
    }
    return statusTraduction;
  };

  return {
    meeting,
    newIndicator,
    deleteText,
    deactivateModule,
    editedIndex,
    isShowAction,
    showHistoryModal,
    showDeleteModal,
    showAddIndicatorModal,
    currentIndicatorIndex,
    indicatorActions,
    loading,
    showAddIndicator,
    showHistory,
    historyIndex,
    handleToggleAddModal,
    handleHistoryIndicatorModalClick,
    handleHistoryIndicatorlClick,
    handleToggleDeleteModal,
    handleChange,
    handleIndicatorAction,
    onConfirmClicked,
    deleteIndicator,
    cancelChangeStatus,
    handleChangeStatus,
    handleIndicatorChangeStatus,
    onConfirmChangeClicked,
    setShowHistoryModal,
    setShowDeleteModal,
    handleCancelClicked,
    setShowAddIndicator,
    setShowHistory,
    getStatusOrTrend,
  };
};

export default useLogic;
