import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Group = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="ic_fluent_folder_20_regular"
        d="M11.042,3a2.625,2.625,0,0,1,1.34.368l.235.157,2.8,2.1H25.625a4.376,4.376,0,0,1,4.338,3.8l.028.283L30,10V23.125a4.375,4.375,0,0,1-4.087,4.366l-.288.009H6.375a4.375,4.375,0,0,1-4.366-4.087L2,23.125V7.375A4.375,4.375,0,0,1,6.087,3.009L6.375,3Zm1.734,7.06a2.625,2.625,0,0,1-1.633.8l-.268.014-7.125,0V23.125a2.625,2.625,0,0,0,2.372,2.613l.253.012h19.25a2.625,2.625,0,0,0,2.613-2.372l.012-.253V10a2.625,2.625,0,0,0-2.372-2.613l-.253-.012H15.33ZM11.042,4.75H6.375A2.625,2.625,0,0,0,3.762,7.122l-.012.253V9.123l7.125,0a.875.875,0,0,0,.527-.177l.106-.095,2.206-2.317L11.567,4.925a.875.875,0,0,0-.384-.164Z"
        transform="translate(-2 -3)"
        fill="#212121"
      />
    </SvgIcon>
  );
};

export default Group;
