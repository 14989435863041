import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ModalInfoModulInterface {
  showInfoModule: boolean;
  handleCloseInfoModule: () => void;
}

const ModalInfoModul: FC<ModalInfoModulInterface> = ({
  showInfoModule,
  handleCloseInfoModule,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showInfoModule}
      onHide={handleCloseInfoModule}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-group-title">
          <div className="modal-group-icon">
            <i className="ico-modal-title"></i>
          </div>
          <div>
            <h5 className="modal-title">RGPD Meeteo</h5>
            <h6 className="modal-subtitle">Meeteo</h6>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="rgpd-content custom-scroll">
          <p>{t("info_module.privacy_policy")} </p>
          <p>{t("info_module.collect_personal_data")}</p>
          <p>{t("info_module.owner_and_data_controller")}</p>
          <p>VAVAWEB LTD</p>
          <p>71-75 Shelton Street</p>
          <p>Covent Garden</p>
          <p>London</p>
          <p>WC2H 9JQ</p>
          <p>{t("info_module.owner_email")}: hello@xdguru.com</p>
          <br />
          <p>________________________________________ </p>
          <p>{t("info_module.data_type_collected")} </p>
          <p>{t("info_module.data_collects")}</p>
          <p>{t("info_module.data_provided")}</p>
          <p>{t("info_module.personal_data_provided")}</p>
          <p>{t("info_module.all_data")}</p>
          <p>{t("info_module.uncertain_user")}</p>
          <p>{t("info_module.any_use_cookie")}</p>
          <p>{t("info_module.user_responsible")}</p>
          <br />
          <p>{t("info_module.processing_data")}</p>
          <p>{t("info_module.processing_method")}</p>
          <p>{t("info_module.data_controller_process")}</p>
          <p>{t("info_module.processing_data_tools")}</p>
          <p>{t("info_module.place")}</p>
          <p>{t("info_module.data_controller")}</p>
          <p>{t("info_module.retention_time")}</p>
          <p>{t("info_module.data_time_provider")}</p>
          <br />
          <p>{t("info_module.collected_data_using")}</p>
          <p>{t("info_module.collected_data_user")}</p>
          <br />
          <p>{t("info_module.personal_data_information")}</p>
          <p>{t("info_module.personal_data_services")}</p>
          <p>{t("info_module.services")}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { ModalInfoModul };
