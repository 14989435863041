import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Arrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 8 8">
      <path
        id="ic_fluent_caret_down_20_filled"
        d="M5.565,7a.768.768,0,0,0-.6,1.251L7.9,11.88a1.153,1.153,0,0,0,1.793,0l2.931-3.629A.768.768,0,0,0,12.025,7Z"
        transform="translate(-7 12.795) rotate(-90)"
        fill="#212121"
      />
    </SvgIcon>
  );
};

export default Arrow;
