import React from "react";
import { Form, Tab, Button } from "react-bootstrap";
import useLogic from "../../../components/Logic/Rule/useLogic";
import { Meeting, Rule } from "../../../../src/models/Meeting";
import { useTranslation } from "react-i18next";
import IconProfileOutlined from "../Icons/IconProfileOutlined";

interface BotConfirmationProps {
  meeting: Meeting;
  context: any;
  profile: any;
  isDeleteConfirmed: boolean;
  ruleIndex: number;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setShowDeleteConfirmationAction: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
  activeTitle: number;
  setIsDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  setRuleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const RuleTab: React.FC<BotConfirmationProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    title,
    role,
    handleRuleChange,
    addRule,
    handlerEmitRules,
    handleDeleteRuleTab,
  } = useLogic(props);

  return (
    <>
      <Tab.Pane eventKey="rule-tabsmeeting">
        <Form className="rule-form">
          <Button
            variant="primary"
            className="btn-share-rule"
            type="button"
            onClick={handlerEmitRules}
          >
            {t("in_meetings.tabs.share_rules")}
          </Button>

          <div className="form-group">
            <div className="form-add-list">
              <Form.Control
                type="text"
                placeholder={t("rule_module.type")}
                value={title}
                name="title"
                onChange={handleRuleChange}
              />
            </div>
            <Button
              variant="outline-primary"
              className="h-32"
              title="Add Rule"
              onClick={addRule}
              disabled={title ? false : true}
            >
              {t("add")}
            </Button>
          </div>

          <div className="module-add-list-wrapper custom-scroll">
            <ul className="module-add-list">
              {meeting.rules?.map((rule: Rule, index: number) => {
                return (
                  <li key={index} className="module-list-item ">
                    <span className="type">{rule.title}</span>
                    <div className="type-actions-right">
                      <span className="person">
                        {!rule.isAdmin && <IconProfileOutlined />}
                      </span>
                      <Button
                        className="btn remove-list-item"
                        variant="link"
                        disabled={
                          role !== "admin" &&
                          role !== "animator" &&
                          rule?.owner !==
                            (props?.context?.userObjectId as string)
                        }
                        title="Delete Rule"
                        onClick={() => handleDeleteRuleTab(index)}
                      >
                        <i className="ico-delete"></i>
                      </Button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Form>
      </Tab.Pane>
    </>
  );
};

export default RuleTab;
