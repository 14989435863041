import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Meeting } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";

interface indicatorProps {
  meeting: Meeting;
}

const Indicator: React.FC<indicatorProps> = ({ meeting }) => {
  const { t } = useTranslation();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const handleShowHistoryModal = (index: number) => {
    setHistoryIndex(index);
    setShowHistoryModal(true);
  };
  const handleCloseHistoryModal = () => setShowHistoryModal(false);

  // Select indicator status
  const [status, setStatus] = React.useState(1);

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
  };

  // Select indicator trend
  const [trend, setTrend] = React.useState(1);

  const handleChangeTrend = (event: any) => {
    setTrend(event.target.value);
  };

  // Change status indicator
  const [indicatorStatus, setIndicatorStatus] = React.useState(true);
  const [isShowAction, setShowIndicatorAction] = React.useState(false);
  const [indicatorActions, setIndicatorAction] = React.useState(true);
  const [isShowButtonActions, setShowButtonAction] = React.useState(true);

  const handleIndicatorAction = () => {
    setIndicatorAction((current) => !current);
  };

  const handleIndicatorChangeStatus = (event: any) => {
    setIndicatorStatus((current) => !current);
    setShowIndicatorAction((current) => !current);
    setShowButtonAction(false);
  };

  return (
    <>
      <div className="module-item indicator">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-indicator"></i>
            </span>
            {t("indicator")}
          </h3>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">
            {t("indicator_module.add_module")}
          </p>
          <div className="module-main-content module-main-content-indicator">
            <div className="indicator-list custom-scroll">
              {meeting?.indicator?.map((indicatorElement, index) => (
                <div className="indicator-item" key={index}>
                  <div className="indicator-item-top">
                    <div>
                      <h3>{indicatorElement?.title}</h3>
                      <p>{indicatorElement?.description}</p>
                    </div>

                    <div className="indicator-item-actions">
                      <span
                        className="btn"
                        title="History indicator"
                        onClick={() => handleShowHistoryModal(index)}
                      >
                        <i className="ico-calendar"></i>
                      </span>
                    </div>
                  </div>

                  <div className="indicator-item-bottom">
                    <Form action="" className="indicator-form disable">
                      <div className="form-performance">
                        <FormControl disabled={indicatorStatus}>
                          <label className="form-label">{t("status")}:</label>
                          <Select
                            className="form-control form-select-status"
                            IconComponent={() => <KeyboardArrowDownIcon />}
                            onChange={handleChangeStatus}
                            defaultValue={indicatorElement?.status}
                            name="status"
                          >
                            <MenuItem value="Good">
                              <span className="icon">
                                <i className="ico-good"></i>
                              </span>{" "}
                              {t("indicator_module.status.good")}
                            </MenuItem>
                            <MenuItem value="Average">
                              <span className="icon">
                                <i className="ico-average"></i>
                              </span>{" "}
                              {t("indicator_module.status.average")}
                            </MenuItem>
                            <MenuItem value="Low">
                              <span className="icon">
                                <i className="ico-low"></i>
                              </span>{" "}
                              {t("indicator_module.status.low")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="form-performance">
                        <FormControl disabled={indicatorStatus}>
                          <label className="form-label">{t("trend")}:</label>
                          <Select
                            className="form-control form-select-trend"
                            IconComponent={() => <KeyboardArrowDownIcon />}
                            onChange={handleChangeTrend}
                            defaultValue={indicatorElement?.trend}
                          >
                            <MenuItem value="Ascendant">
                              <span className="icon">
                                <i className="ico-ascendant"></i>
                              </span>
                              {t("indicator_module.trend.ascendant")}
                            </MenuItem>
                            <MenuItem value="Stagnant">
                              <span className="icon">
                                <i className="ico-stagnant"></i>
                              </span>
                              {t("indicator_module.trend.stagnant")}
                            </MenuItem>
                            <MenuItem value="Descendant">
                              <span className="icon">
                                <i className="ico-descendant"></i>
                              </span>
                              {t("indicator_module.trend.descendant")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="form-performance">
                        <div className="form-check form-switch">
                          {isShowAction ? (
                            <Form.Check
                              type="switch"
                              className="form-check form-switch"
                              onChange={handleIndicatorAction}
                              id="actions"
                            />
                          ) : null}
                          <label htmlFor="actions" className="form-label">
                            Actions:
                          </label>
                        </div>
                        <>{indicatorElement?.action}</>
                        {/* <Form.Control
                          as="textarea"
                          rows={2}
                          disabled={indicatorActions}
                          placeholder="There are no action..."
                          defaultValue={indicatorElement.action}
                        ></Form.Control> */}
                      </div>
                    </Form>

                    <div className="form-performance-btn" hidden>
                      {isShowButtonActions ? (
                        <button
                          className="btn btn-outline-primary btn-change-status"
                          onClick={handleIndicatorChangeStatus}
                        >
                          Change status
                        </button>
                      ) : (
                        <div className="d-flex">
                          <button className="btn btn-outline-primary btn-change-cancel">
                            <i className="ico-cancel"></i> Cancel
                          </button>

                          <button className="btn btn-outline-primary btn-change-confirm">
                            <i className="ico-checkmark"></i> Confirm
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Modal survey result */}
      <Modal
        show={showHistoryModal}
        onHide={handleCloseHistoryModal}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-survey"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-group-title">
              <div className="modal-group-icon">
                <i className="ico-modal-title"></i>
              </div>
              <div>
                <h5 className="modal-title">{t("history.title")}</h5>
                <h6 className="modal-subtitle">Meeteo</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {meeting?.indicator &&
            meeting?.indicator[historyIndex]?.history
              ?.slice(0)
              .reverse()
              .map((historyElement, index) => (
                <div className="history-list custom-scroll" key={index}>
                  <div className="history-item">
                    <div className="history-item-left">
                      <h4>
                        <i className="ico-calendar"></i>
                        {historyElement.date}
                      </h4>
                      <p>
                        <span className="name">{historyElement?.user}</span>{" "}
                        {t("indicator_module.history.change_into")} "
                        {historyElement?.status}".
                      </p>
                      <h5>Action: "{historyElement?.actions}"</h5>
                    </div>
                    <span
                      className={`history-status ${historyElement?.status}`}
                    >
                      {historyElement?.status}
                    </span>
                  </div>
                </div>
              ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseHistoryModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Indicator;
