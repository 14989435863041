import React, { FC } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ModalDisactivateModuleInterface {
  showDeactivateModule: boolean;
  handleCloseDeactivateModule: () => void;
  moduleName: string;
  cancelDeactivateModule: () => void;
  applyDeactivateModule: () => void;
}

const ModalDisactivateModule: FC<ModalDisactivateModuleInterface> = ({
  showDeactivateModule,
  handleCloseDeactivateModule,
  moduleName,
  cancelDeactivateModule,
  applyDeactivateModule,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showDeactivateModule}
      onHide={handleCloseDeactivateModule}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title">{t("deactivate")}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="remove">
        <p>
          {" "}
          {t("confirm_deactivate")} "{moduleName}"?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={cancelDeactivateModule}>
          {t("cancel")}
        </Button>
        <Button variant="primary" onClick={applyDeactivateModule}>
          {t("deactivate")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export { ModalDisactivateModule };
