const ROLE = {
  MEMBER: "member",
  ADMIN: "admin",
  ANIMATOR: "animator",
  NOTE_TAKER: "note_taker",
  TIME_KEEPER: "time_keeper",
  OWNER: "owner",
  ORGANIZER: "organizer",
};

const HOST_CLIENT_TYPE = {
  ANDOIRD: "android",
  IOS: "ios",
  WEB: "web",
  DESKTOP: "desktop",
  RIGEL: "rigel",
};
export { ROLE, HOST_CLIENT_TYPE };
