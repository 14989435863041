/* eslint-disable no-unused-vars */
import { rejects } from "assert";
import React, { createContext } from "react";
import {
  Meeting,
  Participant,
  Agenda,
  Action,
  Rule,
  Survey,
  Indicator,
  OptionResult,
  Mail,
  Organization,
} from "../../models/Meeting";

import { User } from "../../models/User";

export interface DataContextInterface {
  isLoading: boolean;
  meeting: Meeting;
  createNewMeeting: (context: any) => void;
  initiateMeeting: (context: any) => void;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setMeeteoData: React.Dispatch<React.SetStateAction<User>>;
  refreshMeeting: (context: any) => void;
  hasAccess: boolean;
  isRefreshing: boolean;
  setMeetingInfo: (graph: any, context: any) => Promise<unknown>;
  setUserProfil: (graph: any, context: any, count: number) => void;
  profile: any;
  info: any;
  event: any;
  meeteoData: User;
  setContextData: React.Dispatch<any>;
  objective: string | undefined;
  setObjective: React.Dispatch<React.SetStateAction<string | undefined>>;
  meetingModules: string[];
  setMeetingModules: React.Dispatch<React.SetStateAction<string[]>>;
  newMeeting: boolean;
  // update: () => void;
  insertMeeting: (meeting: Meeting) => void;
  insertParticipant: (
    participant: Participant,
    _id: any,
    meeting: Meeting
  ) => void;
  addAgendaData: (agenda: Agenda, meeting: Meeting) => void;
  addAction: (action: Action, meeting: Meeting) => void;
  addRules: (rule: Rule, meeting: Meeting) => void;
  addSurveysData: (survey: Survey, meeting: Meeting) => void;
  addIndicatorData: (indicator: Indicator, meeting: Meeting) => void;
  addResultatData: (
    resultat: OptionResult,
    meeting: Meeting,
    resultatId: string
  ) => void;

  updateMainData: (meeting: Meeting, context: any) => void;
  updateAgendaData: (agenda: Agenda, meeting: Meeting) => void;
  updateActionData: (action: Action, meeting: Meeting) => void;
  updateRulesData: (rule: Rule, meeting: Meeting) => void;
  updateSurveysData: (survey: Survey, meeting: Meeting) => void;
  updateIndicatorData: (indicator: Indicator, meeting: Meeting) => void;
  updateModuleData: (module: string[], meeting: Meeting) => void;
  addParticipantsData: (participants: Participant[], meeting: Meeting) => void;

  updateStartTimeData: (meeting: Meeting) => void;
  updateMailData: (meeting: Meeting) => void;

  deleteAgendaData: (agenda: Agenda, meeting: Meeting) => void;
  deleteActionData: (action: Action, meeting: Meeting) => void;
  deleteRulesData: (rule: Rule, meeting: Meeting) => void;
  deleteSurveysData: (survey: Survey, meeting: Meeting) => void;
  deleteIndicatorData: (indicator: Indicator, meeting: Meeting) => void;
  deleteParticipantsData: (
    participants: Participant[],
    meeting: Meeting
  ) => void;

  createNewUser: () => void;
  initiateUser: () => void;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  setEvent: React.Dispatch<any>;

  insertUser: (user: User) => void;

  sendMail: (mail: Mail) => void;

  getMeeteoInfo: () => void;

  getUserByRef: (participants: Participant[]) => void;

  userSunny: User[];

  addPointUser: (context: any, point: number) => void;
  contextData: any;
  organization: Organization;
}

const DataContext = createContext<DataContextInterface>({
  isLoading: false,
  meeting: {},
  createNewMeeting: (context: any) => null,
  refreshMeeting: (context: any) => null,
  initiateMeeting: () => null,
  setMeeting: () => null,
  setMeeteoData: () => null,
  hasAccess: false,
  isRefreshing: false,
  setMeetingInfo: (graph: any, context: any) => new Promise(() => {}),
  setUserProfil: (graph: any, context: any, count: number) => null,
  setEvent: () => null,
  event: {},
  profile: {},
  info: {},
  meeteoData: {},
  setContextData: () => null,
  objective: "",
  setObjective: () => null,
  meetingModules: [],
  setMeetingModules: () => null,
  newMeeting: false,
  // update: () => null,
  insertMeeting: (meeting: Meeting) => null,
  insertParticipant: (participant: Participant, _id: any, meeting: Meeting) =>
    null,
  updateMainData: (meeting: Meeting, context: any) => null,
  addAgendaData: (agenda: Agenda, meeting: Meeting) => null,
  addAction: (action: Action, meeting: Meeting) => null,
  addRules: (rule: Rule, meeting: Meeting) => null,
  addSurveysData: (survey: Survey, meeting: Meeting) => null,
  addIndicatorData: (indicator: Indicator, meeting: Meeting) => null,
  addResultatData: (
    resultat: OptionResult,
    meeting: Meeting,
    resultatId: string
  ) => null,
  addParticipantsData: (participants: Participant[], meeting: Meeting) => null,

  updateAgendaData: (agenda: Agenda, meeting: Meeting) => null,
  updateActionData: (action: Action, meeting: Meeting) => null,
  updateRulesData: (rule: Rule, meeting: Meeting) => null,
  updateSurveysData: (survey: Survey, meeting: Meeting) => null,
  updateIndicatorData: (indicator: Indicator, meeting: Meeting) => null,

  updateStartTimeData: (meeting: Meeting) => null,
  updateMailData: (meeting: Meeting) => null,
  updateModuleData: (module: string[], meeting: Meeting) => null,

  deleteAgendaData: (agenda: Agenda, meeting: Meeting) => null,
  deleteActionData: (action: Action, meeting: Meeting) => null,
  deleteRulesData: (rule: Rule, meeting: Meeting) => null,
  deleteSurveysData: (survey: Survey, meeting: Meeting) => null,
  deleteIndicatorData: (indicator: Indicator, meeting: Meeting) => null,
  deleteParticipantsData: (participants: Participant[], meeting: Meeting) =>
    null,

  createNewUser: () => null,
  initiateUser: () => null,

  insertUser: (user: User) => null,

  user: {},
  setUser: () => null,

  sendMail: (mail: Mail) => null,

  getMeeteoInfo: () => null,

  getUserByRef: (participants: Participant[]) => null,

  userSunny: [],

  addPointUser: (context: any, point: number) => null,
  contextData: {},
  organization: {},
});

export default DataContext;
