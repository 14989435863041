import React, { FC } from "react";
import { MenuItem, Select, FormControl } from "@material-ui/core";
import { Tab, Button, Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import useLogic from "../../Logic/Indicator/uselogic";
import { Meeting } from "../../../models/Meeting";
interface BotConfirmationProps {
  meeting: Meeting;
  isDeleteConfirmed: boolean;
  indicatorIndex: number;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setShowDeleteConfirmationAction: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
  setIndicatorIndex: React.Dispatch<React.SetStateAction<number>>;
  activeTitle: number;
}

const IndicatorTab: FC<BotConfirmationProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    newIndicator,
    indicatorActions,
    showAddIndicator,
    editedIndex,
    isShowAction,
    showHistory,
    historyIndex,
    handleChange,
    handleIndicatorAction,
    handleChangeStatus,
    handleIndicatorChangeStatus,
    cancelChangeStatus,
    onConfirmChangeClicked,
    onConfirmClicked,
    handleToggleDeleteModal,
    handleHistoryIndicatorlClick,
    setShowAddIndicator,
    setShowHistory,
    getStatusOrTrend,
  } = useLogic(props);

  return (
    <>
      <Tab.Pane eventKey="indicator-tabsmeeting">
        <div className="module-main-content indicator-list-form">
          <Button
            className="btn btn-primary btn-add-indicator"
            type="button"
            onClick={() => {
              setShowAddIndicator(true);
            }}
          >
            {t("indicator_module.add")}
          </Button>

          {/* Add indicator form */}
          {showAddIndicator ? (
            <div className="add-idicator-form">
              <Button
                variant="link"
                className="btn-add-indicator-back"
                type="button"
                title="Back"
                onClick={() => setShowAddIndicator(false)}
              >
                <i className="ico-arrow"></i> {t("back")}
              </Button>

              <div className="indicator-list">
                <div className="indicator-item">
                  <div className="indicator-item-top">
                    <h2>{t("indicator_module.add")} </h2>
                    <p>{t("indicator_module.add_performance")} </p>
                  </div>

                  <div className="indicator-item-bottom">
                    <Form className="indicator-form">
                      <div className="custom-scroll">
                        <div className="form-group">
                          <label htmlFor="title" className="form-label">
                            {t("title")}
                          </label>
                          <Form.Control
                            type="text"
                            name="title"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="description" className="form-label">
                            {t("indicator_module.description")}
                          </label>

                          <Form.Control
                            as="textarea"
                            rows={2}
                            id="description"
                            name="description"
                            onChange={handleChange}
                          ></Form.Control>
                        </div>

                        <div className="form-performance-modal">
                          <div className="form-performance">
                            <FormControl>
                              <label className="form-label">
                                {t("indicator_module.status.title")}
                              </label>
                              <Select
                                className="form-control form-select-status"
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                name="status"
                                onChange={handleChange}
                              >
                                <MenuItem value="Good">
                                  <span className="icon">
                                    <i className="ico-good"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.good")}
                                </MenuItem>
                                <MenuItem value="Average">
                                  <span className="icon">
                                    <i className="ico-average"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.average")}
                                </MenuItem>
                                <MenuItem value="Low">
                                  <span className="icon">
                                    <i className="ico-low"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.low")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="form-performance">
                            <FormControl>
                              <label className="form-label">
                                {t("indicator_module.trend.title")}
                              </label>
                              <Select
                                className="form-control form-select-trend"
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                name="trend"
                                onChange={handleChange}
                              >
                                <MenuItem value="Ascendant">
                                  <span className="icon">
                                    <i className="ico-ascendant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.ascendant")}
                                </MenuItem>
                                <MenuItem value="Stagnant">
                                  <span className="icon">
                                    <i className="ico-stagnant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.stagnant")}
                                </MenuItem>
                                <MenuItem value="Descendant">
                                  <span className="icon">
                                    <i className="ico-descendant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.descendant")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="form-performance">
                            <label className="form-label">
                              {t("indicator_module.action")}
                            </label>
                            <FormControl>
                              <Form.Check
                                type="switch"
                                className="form-check form-switch"
                                onChange={handleIndicatorAction}
                              />
                            </FormControl>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              disabled={indicatorActions}
                              placeholder={t("indicator_module.no_action")}
                              name="action"
                              onChange={handleChange}
                            ></Form.Control>
                          </div>
                        </div>
                      </div>

                      <div className="submit-button">
                        <button
                          className="btn btn-primary"
                          title="Create"
                          onClick={onConfirmClicked}
                          disabled={
                            newIndicator.title &&
                            newIndicator.description &&
                            newIndicator.status &&
                            newIndicator.trend
                              ? false
                              : true
                          }
                        >
                          {t("create")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Show History Indicator */}
          {showHistory ? (
            <div className="history-indicator">
              <Button
                variant="link"
                className="btn-history-indicator-back"
                type="button"
                title="Back"
                onClick={() => setShowHistory(false)}
              >
                <i className="ico-arrow"></i> {t("back")}
              </Button>

              <h2>{t("indicator_module.history.title")}</h2>
              <p>
                {t("indicator_module.history.change_status")} "
                {meeting.indicator && meeting.indicator[historyIndex].title
                  ? meeting.indicator[historyIndex].title
                  : ""}
                ".
              </p>

              {meeting.indicator &&
                meeting.indicator[historyIndex]?.history
                  ?.slice(0)
                  .reverse()
                  .map((story, index) => {
                    return (
                      <div className="history-list custom-scroll" key={index}>
                        <div className="history-item">
                          <div className="history-item-left">
                            <h4>
                              <i className="ico-calendar"></i>
                              {story.date}
                            </h4>
                            <p>
                              <span className="name">{story.user}</span>{" "}
                              {t("indicator_module.history.change_into")} "
                              {getStatusOrTrend(story.status)}".
                            </p>
                            <h5>
                              {t("indicator_module.action")}: "{story.actions}"
                            </h5>
                          </div>
                          <span className={`history-status ${story.status}`}>
                            {getStatusOrTrend(story.status)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
            </div>
          ) : null}

          {/* List Indicator */}
          <div className="module-add-list-wrapper custom-scroll">
            <div className="indicator-list">
              {meeting.indicator?.map((indicator, index) => {
                return (
                  <div className="indicator-item" key={index}>
                    <div className="indicator-item-top">
                      <div>
                        <h3>{indicator.title}</h3>
                        <p>{indicator.description}</p>
                      </div>

                      <div className="indicator-item-actions">
                        <span
                          className="btn btn-indicator-history"
                          title="History indicator"
                          onClick={() => handleHistoryIndicatorlClick(index)}
                        >
                          <i className="ico-calendar"></i>
                        </span>
                        <span
                          className="btn remove-list-item"
                          title="Delete indicator"
                          onClick={() => {
                            handleToggleDeleteModal(index);
                          }}
                        >
                          <i className="ico-delete"></i>
                        </span>
                      </div>
                    </div>

                    <div className="indicator-item-bottom">
                      <Form className="indicator-form disable">
                        <div className="form-performance">
                          {editedIndex !== index ? (
                            <>
                              <label className="form-label">
                                {t("indicator_module.status.title")}:
                              </label>
                              <div className="form-status-disable">
                                <span className="icon">
                                  <i className={`ico-${indicator.status}`}></i>
                                </span>{" "}
                                {getStatusOrTrend(indicator.status)}
                              </div>
                            </>
                          ) : (
                            <FormControl>
                              <label className="form-label">
                                {t("indicator_module.status.title")}:
                              </label>
                              <Select
                                className="form-control form-select-status"
                                defaultValue={indicator.status}
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                name="status"
                                onChange={(e) => handleChangeStatus(e, index)}
                              >
                                <MenuItem value="Good">
                                  <span className="icon">
                                    <i className="ico-good"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.good")}
                                </MenuItem>
                                <MenuItem value="Average">
                                  <span className="icon">
                                    <i className="ico-average"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.average")}
                                </MenuItem>
                                <MenuItem value="Low">
                                  <span className="icon">
                                    <i className="ico-low"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.low")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </div>

                        <div className="form-performance">
                          {editedIndex !== index ? (
                            <>
                              <div className="form-status-disable">
                                <label className="form-label">
                                  {t("indicator_module.trend.title")}:
                                </label>
                                <span className="icon">
                                  <i className={`ico-${indicator.trend}`}></i>
                                </span>{" "}
                                {getStatusOrTrend(indicator.trend)}
                              </div>
                            </>
                          ) : (
                            <FormControl>
                              <label className="form-label">
                                {t("indicator_module.trend.title")}:
                              </label>
                              <Select
                                className="form-control form-select-trend"
                                defaultValue={indicator.trend}
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                name="trend"
                                onChange={(e) => handleChangeStatus(e, index)}
                              >
                                <MenuItem value="Ascendant">
                                  <span className="icon">
                                    <i className="ico-ascendant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.ascendant")}
                                </MenuItem>
                                <MenuItem value="Stagnant">
                                  <span className="icon">
                                    <i className="ico-stagnant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.stagnant")}
                                </MenuItem>
                                <MenuItem value="Descendant">
                                  <span className="icon">
                                    <i className="ico-descendant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.descendant")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </div>

                        <div className="form-performance">
                          <div className="form-check form-switch">
                            {isShowAction ? (
                              <Form.Check
                                type="switch"
                                className="form-check form-switch"
                                onChange={handleIndicatorAction}
                                id="action-1"
                              />
                            ) : null}
                            <label htmlFor="action-1" className="form-label">
                              {t("action_in_progress")}:
                            </label>
                          </div>

                          {editedIndex !== index ? (
                            <>{indicator.action}</>
                          ) : (
                            <Form.Control
                              as="textarea"
                              rows={2}
                              placeholder="There are no action..."
                              defaultValue={indicator.action}
                              name="action"
                              onChange={(e) => handleChangeStatus(e, index)}
                            ></Form.Control>
                          )}
                        </div>
                      </Form>

                      <div className="form-performance-btn">
                        {editedIndex !== index ? (
                          <button
                            className="btn btn-outline-primary btn-change-status"
                            onClick={(event) =>
                              handleIndicatorChangeStatus(event, index)
                            }
                          >
                            {t("indicator_module.change_status")}
                          </button>
                        ) : (
                          <div className="d-flex">
                            <button
                              className="btn btn-outline-primary btn-change-cancel"
                              onClick={(event) => cancelChangeStatus(index)}
                            >
                              <i className="ico-cancel"></i> {t("cancel")}
                            </button>

                            <button
                              className="btn btn-outline-primary btn-change-confirm"
                              onClick={onConfirmChangeClicked}
                            >
                              <i className="ico-checkmark"></i> {t("confirm")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Tab.Pane>
    </>
  );
};

export default IndicatorTab;
