import React, { useEffect } from "react";
import { Button, Tab } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Agenda, Meeting } from "../../../models/Meeting";
import useLogic from "../../Logic/Agenda/useLogic";
import AgendaTimer from "./AgendaTimer";
import { useTranslation } from "react-i18next";
interface BotConfirmationProps {
  meeting: Meeting;
  profile: any;
  context: any;
  isAdminRight: boolean;
  isEditedIndex: boolean[];
  currentIndex: number;
  setCurrentChildsRef: any;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setShowClosingConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const AgendaTab = (props: BotConfirmationProps) => {
  const { t } = useTranslation();
  const {
    childRef,
    meeting,
    editedIndex,
    isEditedIndex,
    setCurrentChildsRef,
    handleIsUsedClick,
    handleCloseAgenda,
  } = useLogic(props);

  useEffect(() => {
    childRef.current = childRef.current.slice(0, meeting.agenda?.length);
    setCurrentChildsRef(childRef.current);
  }, [meeting.agenda]);

  return (
    isEditedIndex && (
      <>
        <Tab.Pane eventKey="agenda-tabsmeeting">
          <Form className="agenda-form">
            <div className="module-add-list-wrapper custom-scroll">
              <ul className="module-add-list">
                {meeting.agenda?.map((agenda: Agenda, index: number) => {
                  return (
                    <li className="module-list-item " key={index}>
                      <div>
                        <span className="type">{agenda.title}</span>
                        <div className="estimate-used">
                          <div>
                            <span>{t("in_meetings.tabs.estimate")}:</span>
                            {props.isAdminRight && (
                              <Button
                                className="btn btn-outline-primary btn-start"
                                onClick={() => {
                                  handleIsUsedClick(index);
                                }}
                                disabled={!props.isAdminRight}
                              >
                                Start
                              </Button>
                            )}
                            <span>
                              <i className="ico-timer"></i>{" "}
                              {("0" + agenda.duration).slice(-2)} mn
                            </span>
                          </div>
                          {agenda.isUsed && !agenda.isClosed ? (
                            <AgendaTimer
                              duration={agenda.duration}
                              index={index}
                              ref={(el: any) => (childRef.current[index] = el)}
                              restart={true}
                            />
                          ) : null}
                          {agenda.isUsed && agenda.isClosed ? (
                            <AgendaTimer
                              duration={agenda.duration}
                              index={index}
                              ref={(el: any) => (childRef.current[index] = el)}
                              restart={true}
                            />
                          ) : null}
                        </div>
                      </div>

                      {props.isAdminRight && (
                        <Form.Check
                          type="checkbox"
                          id="agenda-1"
                          onChange={() => {
                            if (props.isAdminRight) {
                              handleCloseAgenda(index);
                            }
                          }}
                          disabled={
                            agenda.isClosed || !props.isAdminRight
                              ? true
                              : false
                          }
                          checked={agenda.isClosed ? true : false}
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Form>
        </Tab.Pane>
      </>
    )
  );
};

export default AgendaTab;
