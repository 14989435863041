import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const FilledStar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="ic_fluent_star_12_filled"
        d="M11.6,2.2a1.979,1.979,0,0,1,3.55,0L17.9,7.777l6.151.894a1.979,1.979,0,0,1,1.1,3.376L20.7,16.385l1.051,6.126A1.979,1.979,0,0,1,18.874,24.6l-5.5-2.892L7.872,24.6A1.979,1.979,0,0,1,5,22.511L6.05,16.385,1.6,12.047A1.979,1.979,0,0,1,2.7,8.671l6.151-.894Z"
        transform="translate(-1 -1.1)"
        fill="#fd0"
      />
    </SvgIcon>
  );
};

export default FilledStar;
