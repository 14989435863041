import React, { useEffect, useState, useContext } from "react";
import { Meeting } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";
import { Survey, Indicator, Agenda } from "../../../models/Meeting";
import { Groupe, Avatar, Score } from "../../../models/User";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DataContext from "../../../Providers/DataProvider/DataContext";
import { SUNNY_PRICE } from "../../../utils/Point";

interface props {
  context: any;
}

interface selectedItem {
  indexGroupe: number;
  indexItem: number;
}

interface interfaceModule {
  isAgendaOn: boolean;
  isRulesOn: boolean;
  isParticipantsOn: boolean;
  isSurveyOn: boolean;
  isIndicatorOn: boolean;
}

const useLogic = ({ context }: props) => {
  const { t } = useTranslation();
  const {
    meeting,
    setMeeting,
    profile,
    info,
    objective,
    setObjective,
    meetingModules,
    setMeetingModules,
    insertMeeting,
    updateModuleData,
    user,
    setUser,
    insertUser,
  } = useContext(DataContext);

  const [type, setType] = useState(meeting.type);
  const [currentModule, setCurrentModule] = useState<string>("");
  const [moduleName, setModuleName] = useState<string>("");
  const [showEnableDisableModule, setShowEnableDisableModule] =
    useState<boolean>(false);
  const [showInfoModule, setShowInfoModule] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showSaveMeetingModal, setShowSaveMeetingModal] =
    useState<boolean>(false);
  const [showUseReccurentModal, setShowUseReccurentModal] =
    useState<boolean>(false);
  const [showCreateGroupModal, setShowCreateGroupModal] =
    useState<boolean>(false);
  const [showDeactivateModule, setShowAddDeactivateModule] =
    useState<boolean>(false);
  const [selectedGroupeSave, setselectedGroupeSave] = useState<number>(-1);
  const [creatGroupeText, setcreatGroupeText] = useState<string>("");
  const [createSaveTitle, setCreateSaveTitle] = useState<string>("");
  const [tips, setTips] = useState<boolean>(false);
  const [tipsNumber, setTipsNumber] = useState<number>(0);
  const [selectedDropItem, setselectedDropItem] = useState<selectedItem>({
    indexGroupe: -1,
    indexItem: -1,
  });

  const [gotIt, setGotIt] = React.useState(false);
  const [botContent, setBotContent] = React.useState(true);
  const [isSavedExit, setisSavedExit] = useState<boolean>(false);
  const [module, setModule] = useState<interfaceModule>({
    isAgendaOn: false,
    isRulesOn: false,
    isParticipantsOn: false,
    isSurveyOn: false,
    isIndicatorOn: false,
  });

  const handleCreateNewGroup = () => {
    user.groupe?.push({ name: creatGroupeText, listeMeeting: [] });
    insertUser({ ...user });
    setShowCreateGroupModal(false);
  };

  const selecteDropItem = (indexGroupe: number, indexItem: number) => {
    setselectedDropItem({ indexGroupe, indexItem });
  };

  const handleLoadReccurentModal = () => {
    if (
      selectedDropItem.indexGroupe !== -1 &&
      selectedDropItem.indexItem !== -1
    ) {
      let tempGroupe =
        user && user.groupe && user.groupe[selectedDropItem.indexGroupe]
          ? (user.groupe[selectedDropItem.indexGroupe] as Groupe)
          : null;
      let tempMeeting =
        tempGroupe &&
        tempGroupe.listeMeeting &&
        tempGroupe.listeMeeting[selectedDropItem.indexItem]
          ? tempGroupe.listeMeeting[selectedDropItem.indexItem]
          : {};
      if (tempMeeting?.meeting) {
        meeting.agenda =
          tempMeeting.meeting && tempMeeting.meeting.agenda
            ? tempMeeting.meeting.agenda
            : [];
        meeting.indicator =
          tempMeeting.meeting && tempMeeting.meeting.indicator
            ? tempMeeting.meeting.indicator
            : [];
        meeting.modules =
          tempMeeting.meeting && tempMeeting.meeting.modules
            ? tempMeeting.meeting.modules
            : [];
        meeting.rules =
          tempMeeting.meeting && tempMeeting.meeting.rules
            ? tempMeeting.meeting.rules
            : [];
        meeting.surveys =
          tempMeeting.meeting && tempMeeting.meeting.surveys
            ? tempMeeting.meeting.surveys
            : [];
        meeting.title =
          tempMeeting.meeting && tempMeeting.meeting.title
            ? tempMeeting.meeting.title
            : meeting.title;

        setMeeting({
          ...meeting,
        });
        insertMeeting(meeting);
        setShowUseReccurentModal(false);
      }
    }
  };
  const handleCreateGroupModal = () => {
    setcreatGroupeText("");
    setShowCreateGroupModal(true);
  };

  const handleDeleteSaveMeetingListe = (
    indexGroupe: number,
    indexList: number
  ) => {
    let tempGroupe = user.groupe as Groupe[];
    if (tempGroupe[indexGroupe] && tempGroupe[indexGroupe].listeMeeting) {
      tempGroupe[indexGroupe].listeMeeting?.splice(indexList, 1);
      insertUser(user);
    }
  };

  const handleDeleteSaveMeetingGroupe = (indexGroupe: number) => {
    let tempGroupe = user.groupe as Groupe[];
    if (tempGroupe[indexGroupe]) {
      tempGroupe.splice(indexGroupe, 1);
      insertUser(user);
    }
  };

  const handleSaveMeeting = () => {
    let temp = user?.groupe as Groupe[];
    let tempSurvey = meeting.surveys as Survey[];
    let saveSurvey: Survey[] = [];

    let tempIndicator = meeting.indicator as Indicator[];
    let saveIndicator: Indicator[] = [];

    let tempAgenda = meeting.agenda as Agenda[];
    let saveAgenda: Agenda[] = [];

    tempSurvey?.forEach((element) => {
      saveSurvey.push({
        question: element.question,
        options: element.options,
        isMultichoice: element.isMultichoice,
        creator: profile?.id,
      });
    });

    tempIndicator?.forEach((element) => {
      saveIndicator.push({
        title: element.title,
        description: element.description,
        status: element.status,
        trend: element.trend,
        action: element.action,
      });
    });

    tempAgenda?.forEach((element) => {
      saveAgenda.push({
        title: element.title,
        owner: profile?.id,
        duration: element.duration ? element.duration : 5,
        isAdmin: element.isAdmin,
      });
    });

    let meetingSave = {
      referenceM: meeting.reference,
      title: info?.subject,
      modules: meeting.modules,
      agenda: saveAgenda,
      rules: meeting.rules,
      surveys: saveSurvey,
      indicator: saveIndicator,
    };
    temp[selectedGroupeSave].listeMeeting?.push({
      date: new Date(),
      meeting: meetingSave,
    });

    user.groupe = temp;

    insertUser({ ...user });
    setShowSaveMeetingModal(false);
  };

  const handleSaveMeetingModal = () => setShowSaveMeetingModal(true);
  const handleCloseSaveMeetingModal = () => setShowSaveMeetingModal(false);

  const handleUseReccurentModal = () => setShowUseReccurentModal(true);
  const handleCloseUseReccurentModal = () => setShowUseReccurentModal(false);

  const handleCloseCreateGroupModal = () => setShowCreateGroupModal(false);

  const toggleShowEnableDisableModule = () =>
    setShowEnableDisableModule(!showEnableDisableModule);

  const handleShowInfoModule = () => setShowInfoModule(true);
  const handleCloseInfoModule = () => setShowInfoModule(false);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleShowEditModule = () => {
    setShowEditModal(true);
  };

  const handleTypeClicked = (event: any) => {
    setType(event.target.value);
  };

  const handleObjectiveChange = (event: any) => {
    setObjective(event.target.value);
  };

  const handleChangeGroupeText = (event: any) => {
    setcreatGroupeText(event.target.value);
  };

  const handleChangeTileText = (event: any) => {
    setCreateSaveTitle(event.target.value);
  };

  const handleSaveChange = () => {
    if (type) meeting.type = type;
    if (objective) meeting.objective = objective;
    if (meeting.type || meeting.objective) insertMeeting({ ...meeting });
    handleCloseEditModal();
  };

  const setNameOfModule = (module: string) => {
    switch (module) {
      case "participants":
        setModuleName(t("participant_module.title"));
        break;
      case "agenda":
        setModuleName(t("agenda"));
        break;
      case "rules":
        setModuleName(t("rules"));
        break;
      case "survey":
        setModuleName(t("survey"));
        break;
      case "indicator":
        setModuleName(t("indicator"));
        break;
    }
  };

  const handleCloseDeactivateModule = () => {
    setShowAddDeactivateModule(false);
  };

  const deactivateModule = (moduleName: string) => {
    setCurrentModule(moduleName);
    setShowAddDeactivateModule(true);
  };

  const applyDeactivateModule = () => {
    const moduleIndex = meeting.modules?.indexOf(currentModule) as number;

    if (moduleIndex >= 0) {
      meeting.modules?.splice(moduleIndex, 1);
      if (meeting?.modules) updateModuleData(meeting?.modules, meeting);
      setMeetingModules([...(meeting.modules as string[])]);
    }

    setShowAddDeactivateModule(false);
  };

  const cancelDeactivateModule = () => {
    setShowAddDeactivateModule(false);
  };

  const toggleActiveModule = (moduleName: string) => {
    const moduleIndex = meetingModules.indexOf(moduleName);
    if (moduleIndex >= 0) {
      meetingModules.splice(moduleIndex, 1);
    } else {
      meetingModules.push(moduleName);
    }
    setMeetingModules([...meetingModules]);
  };

  const applyModuleConfig = () => {
    updateModuleData(meetingModules, meeting);
    toggleShowEnableDisableModule();
  };

  const cancelModuleConfig = () => {
    setMeetingModules([...(meeting.modules as string[])]);
    toggleShowEnableDisableModule();
  };

  const isActiveModule = () => {
    let test = true;
    if (meeting?.participants && meeting?.participants?.length <= 0)
      return false;
    meeting.participants?.forEach((participant) => {
      if (participant?.reference && participant?.reference === profile?.id) {
        if (
          (participant?.role && participant?.role === "admin") ||
          participant?.role === "animator"
        ) {
          test = false;
        }
      }
    });
    return test;
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: function (index: any) {
      if (index === 2) {
        setGotIt(true);
      } else {
        setGotIt(false);
      }
    },
  };

  // Sunny config items

  const isSunnyEquiped = (id: number) => {
    let temp = false;
    user.avatar?.equipped?.forEach((element) => {
      if (element.toString() === id.toString()) {
        temp = true;
      }
    });
    return temp;
  };

  const buySunny = (sunnyTypeid: number) => {
    let tempAvatar = user.avatar as Avatar;
    let tempScore = user.score as Score;
    if (
      !isSunnyEquiped(sunnyTypeid) &&
      user.score &&
      user.score?.point !== undefined &&
      user.score?.point >= SUNNY_PRICE[sunnyTypeid - 1].point
    ) {
      if (tempScore.point !== undefined)
        tempScore.point -= SUNNY_PRICE[sunnyTypeid - 1].point;
      tempAvatar.equipped.push(sunnyTypeid);
      tempAvatar.current = sunnyTypeid;

      insertUser({ ...user, score: tempScore, avatar: tempAvatar });
    }
    if (isSunnyEquiped(sunnyTypeid)) {
      tempAvatar.current = sunnyTypeid;

      insertUser({ ...user, avatar: tempAvatar });
    }
  };

  const handleSubItem = (index: number) => {
    let temp = user.groupe as Groupe[];
    if (temp[index].dropDown) {
      temp[index].dropDown = false;
    } else {
      temp[index].dropDown = true;
    }
    setUser((prev: any) => ({ ...prev, groupe: temp }));
  };

  //New feature
  const [newFeature, setNewFeature] = useState(false);

  // const handleNewFeature = () => {
  //   setNewFeature(true);
  //   setBotContent(false);
  //   setTimeout(() => setNewFeature(false), 3000);
  // };

  const checkUserSavedMeeting = () => {
    setisSavedExit(false);
    user.groupe?.forEach((element) => {
      element.listeMeeting?.forEach((m) => {
        if (
          m.meeting?.referenceM?.toString() === meeting.reference?.toString()
        ) {
          setisSavedExit(true);
          return true;
        }
      });
    });
    return false;
  };

  const dateReccurenceDiff = (d: Date): string => {
    let temp = new Date(Date.now());
    let t1 = d.getTime();
    let t2 = temp.getTime();

    let dateDiff = Math.abs(t1 - t2) / 1000;
    dateDiff = Math.round(dateDiff / 60 / 60 / 24);
    if (
      d.getFullYear() === temp.getFullYear() &&
      d.getUTCMonth() === temp.getUTCMonth() &&
      temp.getUTCDate() - d.getUTCDate() < 2
    ) {
      if (temp.getUTCDate() - d.getUTCDate() === 0) {
        return "( " + t("today") + " )";
      } else {
        return "( " + t("yesterday") + " )";
      }
    } else if (dateDiff < 2) {
      if (dateDiff < 1) {
        return "( " + t("today") + " )";
      } else {
        return "( " + t("yesterday") + " )";
      }
    } else {
      return "( " + d.toLocaleDateString().replaceAll("/", "-") + " )";
    }
  };

  useEffect(() => {
    setTipsNumber(Math.floor(Math.random() * 18));
    setTimeout(() => {
      setTips(true);
      setBotContent(false);
      setNewFeature(false);
    }, 70000);
  }, []);

  useEffect(() => {
    checkUserSavedMeeting();
  }, [user]);

  useEffect(() => {
    setNameOfModule(currentModule);
  }, [currentModule]);

  useEffect(() => {
    if (meeting && meeting.modules) {
      setMeetingModules([...meeting.modules]);
      const isAgendaOn = meeting.modules?.includes("agenda");
      const isRulesOn = meeting.modules?.includes("rules");
      const isParticipantsOn = meeting.modules?.includes("participants");
      const isSurveyOn = meeting.modules?.includes("survey");
      const isIndicatorOn = meeting.modules?.includes("indicator");
      setModule({
        isAgendaOn,
        isRulesOn,
        isParticipantsOn,
        isSurveyOn,
        isIndicatorOn,
      });
    }
  }, [meeting]);

  return {
    toggleShowEnableDisableModule,
    isActiveModule,
    handleSaveMeetingModal,
    handleShowEditModule,
    handleUseReccurentModal,
    handleShowInfoModule,
    deactivateModule,
    buySunny,
    isSunnyEquiped,
    module,
    isSavedExit,
    botContent,
    settings,
    gotIt,
    setBotContent,
    newFeature,
    tips,
    tipsNumber,
    setTips,
    showEnableDisableModule,
    meetingModules,
    toggleActiveModule,
    cancelModuleConfig,
    applyModuleConfig,
    showDeactivateModule,
    handleCloseDeactivateModule,
    moduleName,
    cancelDeactivateModule,
    applyDeactivateModule,
    showEditModal,
    handleCloseEditModal,
    handleTypeClicked,
    handleObjectiveChange,
    handleSaveChange,
    showInfoModule,
    handleCloseInfoModule,
    showSaveMeetingModal,
    handleCloseSaveMeetingModal,
    selectedGroupeSave,
    setselectedGroupeSave,
    handleCreateGroupModal,
    handleSaveMeeting,
    showUseReccurentModal,
    handleCloseUseReccurentModal,
    handleSubItem,
    handleDeleteSaveMeetingGroupe,
    selecteDropItem,
    dateReccurenceDiff,
    Date,
    handleDeleteSaveMeetingListe,
    handleLoadReccurentModal,
    selectedDropItem,
    showCreateGroupModal,
    handleCloseCreateGroupModal,
    handleChangeGroupeText,
    creatGroupeText,
    handleCreateNewGroup,
    type,
  };
};

export default useLogic;
