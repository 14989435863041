import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Form, Tab } from "react-bootstrap";
import sunshine from "../../assets/images/sunshine.svg";
import partlySunny from "../../assets/images/partly-sunny.svg";
import partlyCloudy from "../../assets/images/partly-cloudy.svg";
import cloudy from "../../assets/images/cloudy.svg";
import rain from "../../assets/images/rain.svg";
import thunderstorm from "../../assets/images/thunderstorm.svg";
import DataContext from "../../../Providers/DataProvider/DataContext";
import { useTranslation } from "react-i18next";
import { BADGES_PRICE } from "../../../utils/Point";

import lvl1 from "../../assets/images/badges/niveau-1.png";
import lvl2 from "../../assets/images/badges/niveau-2.png";
import lvl3 from "../../assets/images/badges/niveau-3.png";
import lvl4 from "../../assets/images/badges/niveau-4.png";
import lvl5 from "../../assets/images/badges/niveau-5.png";
import lvl6 from "../../assets/images/badges/niveau-6.png";
import lvl7 from "../../assets/images/badges/niveau-7.png";
import lvl8 from "../../assets/images/badges/niveau-8.png";
import lvl9 from "../../assets/images/badges/niveau-9.png";
import { useSunny } from "./participantsTabLogic/useSunny";
interface InterfaceParticipant {
  context: any;
  info: any;
  profile: any;
}

const ParticipantsTab: React.FC<InterfaceParticipant> = ({ context }) => {
  const { t } = useTranslation();

  const { weatherID, setweatherID } = useSunny();
  const [userId, setuserId] = useState<number>(0);
  const { meeting, insertParticipant, profile, userSunny } =
    useContext(DataContext);

  useEffect(() => {
    let id = meeting?.participants?.findIndex(
      (element) => element?.reference === context?.userObjectId
    );
    setuserId(id !== undefined ? id : -1);
    setweatherID(
      meeting?.participants && id !== undefined
        ? (meeting?.participants[id]?.wheather as number)
        : -1
    );
  }, []);

  const onAdminStatusChange = (event: any) => {
    if (meeting?.participants) {
      let temp = [...meeting.participants];
      if (temp.length > 0) {
        temp[userId].wheather = event.target.value;
        insertParticipant(temp[userId], meeting?._id, {
          ...meeting,
          participants: temp,
        });
      }
      setweatherID(event.target.value);
    }
  };

  const weather = () => {
    switch (parseInt(weatherID + "")) {
      case 6:
        return <img src={sunshine} alt="Status icon" />;
      case 5:
        return <img src={partlySunny} alt="Status icon" />;
      case 4:
        return <img src={partlyCloudy} alt="Status icon" />;
      case 3:
        return <img src={cloudy} alt="Status icon" />;
      case 2:
        return <img src={rain} alt="Status icon" />;
      case 1:
        return <img src={thunderstorm} alt="Status icon" />;
      default:
        return <img src={sunshine} alt="Status icon" />;
    }
  };

  const badges = (reference: string | undefined) => {
    const user = userSunny?.find((user) => user?.reference === reference);
    const total = user?.score?.total ? user.score.total : 0;
    switch (true) {
      case total < BADGES_PRICE[1]:
        return <img src={lvl1} alt="Status icon" />;

      case total < BADGES_PRICE[2]:
        return <img src={lvl2} alt="Status icon" />;

      case total < BADGES_PRICE[3]:
        return <img src={lvl3} alt="Status icon" />;

      case total < BADGES_PRICE[4]:
        return <img src={lvl4} alt="Status icon" />;

      case total < BADGES_PRICE[5]:
        return <img src={lvl5} alt="Status icon" />;

      case total < BADGES_PRICE[6]:
        return <img src={lvl6} alt="Status icon" />;

      case total < BADGES_PRICE[7]:
        return <img src={lvl7} alt="Status icon" />;

      case total < BADGES_PRICE[8]:
        return <img src={lvl8} alt="Status icon" />;

      case total < BADGES_PRICE[9]:
        return <img src={lvl9} alt="Status icon" />;

      case total >= BADGES_PRICE[9]:
        return <img src={lvl9} alt="Status icon" />;

      default:
        return <img src={lvl1} alt="Status icon" />;
    }
  };

  return (
    <Tab.Pane eventKey="participants-tabsmeeting">
      <Form className="participant-form">
        <label>{t("you")}:</label>
        <div className="participant-status">
          <div className="participant-status-left">
            {badges(profile?.id)}
            <div className="participant-info">
              <span className="participant-name">
                {meeting?.participants && meeting?.participants[userId]?.name
                  ? meeting?.participants[userId]?.name
                  : profile?.displayName}
              </span>
              <span className="participant-role">
                {meeting?.participants && meeting?.participants[userId]
                  ? t(
                      "participant_module.role." +
                        meeting?.participants[userId]?.role
                    )
                  : ""}
              </span>
            </div>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdownlistStatus">
              {meeting?.participants && weather()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <li>
                <label htmlFor="meeteoSunshine">
                  <img src={sunshine} alt="Status" />
                  <span>{t("sunshine")}</span>
                </label>
                <div className="form-check form-radio"></div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    id="meeteoSunshine"
                    name="meeteoStatus"
                    value={6}
                    onChange={onAdminStatusChange}
                    checked={
                      meeting?.participants && userId !== undefined
                        ? meeting?.participants[userId]?.wheather === 6
                        : false
                    }
                  />
                </div>
              </li>

              <li>
                <label htmlFor="meeteoPartlySunny">
                  <img src={partlySunny} alt="Status" />
                  <span>{t("partly_sunny")}</span>
                </label>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    id="meeteoPartlySunny"
                    name="meeteoStatus"
                    value={5}
                    onChange={onAdminStatusChange}
                    checked={
                      meeting?.participants && userId !== undefined
                        ? meeting?.participants[userId]?.wheather === 5
                        : false
                    }
                  />
                </div>
              </li>

              <li>
                <label htmlFor="meeteoPartlyCloudy">
                  <img src={partlyCloudy} alt="Status" />
                  <span>{t("partly_cloudy")}</span>
                </label>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    id="meeteoPartlyCloudy"
                    name="meeteoStatus"
                    value={4}
                    onChange={onAdminStatusChange}
                    checked={
                      meeting?.participants && userId !== undefined
                        ? meeting?.participants[userId]?.wheather === 4
                        : false
                    }
                  />
                </div>
              </li>

              <li>
                <label htmlFor="meeteoCloudy">
                  <img src={cloudy} alt="Status" />
                  <span>{t("Cloudy")}</span>
                </label>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    id="meeteoCloudy"
                    name="meeteoStatus"
                    value={3}
                    onChange={onAdminStatusChange}
                    checked={
                      meeting?.participants && userId !== undefined
                        ? meeting?.participants[userId]?.wheather === 3
                        : false
                    }
                  />
                </div>
              </li>

              <li>
                <label htmlFor="meeteoRain">
                  <img src={rain} alt="Status" />
                  <span>{t("rain")}</span>
                </label>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    id="meeteoRain"
                    name="meeteoStatus"
                    value={2}
                    onChange={onAdminStatusChange}
                    checked={
                      meeting?.participants && userId !== undefined
                        ? meeting?.participants[userId]?.wheather === 2
                        : false
                    }
                  />
                </div>
              </li>

              <li>
                <label htmlFor="meeteomeeteoRain">
                  <img src={thunderstorm} alt="Status" />
                  <span>{t("thunderstorm")}</span>
                </label>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    id="meeteomeeteoRain"
                    name="meeteoStatus"
                    value={1}
                    onChange={onAdminStatusChange}
                    checked={
                      meeting?.participants && userId !== undefined
                        ? meeting?.participants[userId]?.wheather === 1
                        : false
                    }
                  />
                </div>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <label>{t("others")}:</label>
        <div className="participant-status-list-wrapper custom-scroll">
          <div className="participant-status-list">
            {meeting && (
              <>
                {meeting?.participants?.map((participant, index) => {
                  if (index !== userId) {
                    return (
                      <div className="participant-status" key={index}>
                        <div className="participant-status-left">
                          {badges(participant?.reference)}
                          <div className="participant-info">
                            <span className="participant-name">
                              {participant.name}
                            </span>
                            <span className="participant-role">
                              {t("participant_module.role." + participant.role)}
                            </span>
                          </div>
                        </div>

                        <div className="status">
                          {(() => {
                            switch (participant?.wheather) {
                              case 6:
                                return <img src={sunshine} alt="Status icon" />;
                              case 5:
                                return (
                                  <img src={partlySunny} alt="Status icon" />
                                );
                              case 4:
                                return (
                                  <img src={partlyCloudy} alt="Status icon" />
                                );
                              case 3:
                                return <img src={cloudy} alt="Status icon" />;
                              case 2:
                                return <img src={rain} alt="Status icon" />;
                              case 1:
                                return (
                                  <img src={thunderstorm} alt="Status icon" />
                                );
                              default:
                                return <img src={sunshine} alt="Status icon" />;
                            }
                          })()}
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
        </div>
      </Form>
    </Tab.Pane>
  );
};

export default ParticipantsTab;
