import React, { useEffect } from "react";
import "./App.css";
import { app, pages } from "@microsoft/teams-js";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */

const TabConfig = () => {
  const { t } = useTranslation();

  const init = async () => {
    // initialize SDK
    await app.initialize();

    const context = await app.getContext();

    // configure language

    if (context && context?.app?.locale) {
      if (
        context.app.locale.toString().toLocaleLowerCase() === "fr-fr" ||
        context.app.locale.toLocaleLowerCase() === "fr" ||
        context.app.locale.toString().toLocaleLowerCase() === "fr-ca"
      ) {
        i18n.changeLanguage("fr");
      } else {
        i18n.changeLanguage("en");
      }
    }

    /**
     * When the user clicks "Save", save the url for your configured tab.
     * This allows for the addition of query string parameters based on
     * the settings selected by the user.
     */
    pages.config.registerOnSaveHandler(async (saveEvent: any) => {
      app.notifySuccess();
      // ! USE THIS FOR LOCAL TEST
      // const baseUrl = `https://${window.location.hostname}:${window.location.port}`;

      const baseUrl = `https://${window.location.hostname}`;
      const configPromise = pages.config.setConfig({
        suggestedDisplayName: "Meeteo",
        entityId: "meeteoMeeting",
        contentUrl: baseUrl + "/meeting",
        websiteUrl: baseUrl + "/tab",
      });

      configPromise
        .then((result) => {
          saveEvent.notifySuccess();
        })
        .catch((error) => {
          saveEvent.notifyFailure("failure message", error);
        });
    });

    /**
     * After verifying that the settings for your tab are correctly
     * filled in by the user you need to set the state of the dialog
     * to be valid.  This will enable the save button in the configuration
     * dialog.
     */
    pages.config.setValidityState(true);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div>
        <p>
          {t("config_message")}
          <br />
          {t("have_nice")}
          <br />
        </p>
        <a target="blank" href="https://www.meeteo.fr/doc">
          {t("get_started")}
        </a>
      </div>
    </div>
  );
};

export default TabConfig;
