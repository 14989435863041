import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import profile_outline from "./assets/images/profile-outline.svg";
import { useTranslation } from "react-i18next";
import stat from "../components/assets/images/stat.svg";
import statGreen from "../components/assets/images/green.svg";
import statRed from "../components/assets/images/red.svg";
import statYellow from "../components/assets/images/yellow.svg";
import "../index.css";
import { badges, level } from "../utils/Point";
import useLogic from "./Logic/Personal/useLogic";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export default function Personal() {
  const { t } = useTranslation();

  const {
    getLabels,
    dataAverageUser,
    dataAverageAnimator,
    dataAverageAllParticipant,
    userInformation,
    userScore,
    timeTrend,
    percentTrend,
    generateDataAverage,
    dataTalkTime,
  } = useLogic();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const statGraphPicture = {
    low: statRed,
    medium: statYellow,
    well: statGreen,
  };

  // ? DATA  LINECHART
  const data = {
    labels: getLabels,
    datasets: [
      {
        label: `${t("personal.title_participant_chart")}`,
        data: generateDataAverage(getLabels, dataAverageUser!),
        borderColor: "rgba(68, 100, 173, 1)",
        backgroundColor: "rgba(68, 100, 173, 0.5)",
      },
      {
        label: `${t("personal.title_animator_chart")}`,
        data: generateDataAverage(getLabels, dataAverageAnimator!),
        borderColor: "rgba(73, 160, 120, 1)",
        backgroundColor: "rgba(73, 160, 120, 0.5)",
      },

      {
        label: `${t("personal.title_company_chart")}`,
        data: generateDataAverage(getLabels, dataAverageAllParticipant!),
        borderColor: "rgba(255, 183, 0, 1)",
        backgroundColor: "rgba(255, 183, 0, 0.5)",
      },
    ],
  };

  // ? DATA  PIECHART
  const dataPie = {
    labels: [
      `${t("personal.well_balanced")}`,
      `${t("personal.mediumm_balanced")}`,
      `${t("personal.low_balanced")}`,
    ],
    datasets: [
      {
        label: "# of Votes",
        data: dataTalkTime,
        backgroundColor: [
          "rgba(73, 160, 120, 1)",
          "rgba(255, 183, 0, 1)",
          "rgba(214, 50, 48, 1)",
        ],
        borderColor: [
          "rgba(73, 160, 120, 1)",
          "rgba(255, 183, 0, 1)",
          "rgba(214, 50, 48, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="appmenu-meeteo">
      <div className="personal-tab">
        <div className="kpi-wrapper">
          <div className="kpi">
            <div className="kpi-profile">
              <figure>
                <img src={profile_outline} alt="Alternate Text" />
                {/* <i className="ico-profile-outline"></i> */}
              </figure>
              <span>{userInformation?.name}</span>
            </div>
            <div className="kpi-list">
              <div className="kpi-list-item">
                <span>{t("personal.rank")}:</span>
                <p>{(userScore?.total / 100).toFixed(0)}</p>
              </div>
              <div className="kpi-list-item">
                <span>{t("personal.total_points")}:</span>
                <p>{userScore?.point} pts</p>
              </div>
            </div>
          </div>

          <div className="kpi kpi-right">
            <span>
              {t("personal.level")}: {level(userScore)}
            </span>
            <img src={badges(userScore)} alt="badge" className="badge-kpi" />
          </div>
        </div>

        <div className="chart-list custom-scroll">
          <div className="chart-item">
            <h2 className="chart-title">{t("personal.meeting_note")}</h2>

            <Line options={options} data={data} />
            {/* <canvas id="meeting-note"></canvas> */}
          </div>
          <div className="chart-item">
            <h2 className="chart-title">{t("personal.talktime")}</h2>
            <Pie data={dataPie} className="chart-talktime" />
            {/* <canvas id="latest-meeting" width="450" height="315"></canvas> */}
            <div className="speeking-time-trend">
              <div className="speeking-figure">
                <img src={stat} alt="stat" />
              </div>
              <div className="speeking-info">
                <p>{t("personal.time_trend")}</p>
                <p className={`speeking-trend-value ${timeTrend}`}>
                  {percentTrend}%
                  <img src={statGraphPicture[timeTrend]} alt="stat" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export { Personal };
