import { MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Tab, Form, Button } from "react-bootstrap";
import profileOutline from "../../assets/images/profile-outline.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Meeting, Action } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";
import DataContext from "../../../Providers/DataProvider/DataContext";

interface BotConfirmationProps {
  setShowDeleteConfirmationAction: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
  activeTitle: number;
  meeting: Meeting;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  isDeleteConfirmed: boolean;
  setIsDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  profile: any;
}

const ActionsTab = ({
  setShowDeleteConfirmationAction,
  meeting,
  setMeeting,
  isDeleteConfirmed,
  setIsDeleteConfirmed,
  activeTitle,
  setActiveTitle,
  profile,
}: BotConfirmationProps) => {
  const { t } = useTranslation();
  const { insertMeeting, addAction, updateActionData, deleteActionData } =
    useContext(DataContext);

  // select person
  const [person, setPerson] = React.useState(0);
  const [fieldAction, setFieldAction] = useState<string>("");
  const [currentDeleteId, setCurrentDeleteId] = useState<number>(-1);
  const [isAddDisable, setIsAddDisable] = useState<boolean>(true);
  //date
  const [deadline, setDeadline] = React.useState(new Date());

  const [isAdmin, setisAdmin] = useState<boolean>(false);
  const [isAnimateur, setisAnimateur] = useState<boolean>(false);

  const [user, setUser] = useState("");
  const [role, setrole] = useState<string>("");

  useEffect(() => {
    if (profile !== undefined) setUser(profile?.id);
  }, []);

  useEffect(() => {
    if (user) {
      let temp = meeting.participants?.find(
        (participant) => participant.reference + "" === user + ""
      );

      setrole(temp && temp?.role ? temp?.role : "member");
      if (temp?.role === "admin") {
        setisAdmin(true);
      }
      if (temp?.role === "animator") {
        setisAnimateur(true);
      }
    }
  }, [user]);

  //confirm delete
  const handleDeleteClick = (index: any) => {
    setShowDeleteConfirmationAction(true);
    setActiveTitle(4);
    setCurrentDeleteId(index);
  };

  const handleAddAction = () => {
    meeting.actions?.push({
      types: fieldAction,
      inCharge: "",
      date: new Date(),
      checked: false,
      creator: user,
    });

    addAction(
      {
        types: fieldAction,
        inCharge: "",
        date: new Date(),
        checked: false,
        creator: user,
      },
      meeting
    );

    setFieldAction("");
  };

  const handleChangePerson = (index: number, e: any) => {
    let temp =
      meeting !== undefined && meeting.actions ? meeting.actions[index] : {};
    temp.inCharge = e.target.value;
    meeting.actions?.splice(index, 1, temp);
    updateActionData(temp, meeting);
    setPerson(e.target.value);
  };

  const handleChangeDateCheckBox = (event: any, index: number) => {
    if (event.target.value === "immediate") {
      let temp =
        meeting !== undefined && meeting.actions ? meeting.actions[index] : {};
      temp.date = new Date();
      temp.checked = true;
      meeting.actions?.splice(index, 1, temp);
      updateActionData(temp, meeting);
      setDeadline(new Date());
    } else {
      let temp =
        meeting !== undefined && meeting.actions ? meeting.actions[index] : {};
      temp.checked = false;
      meeting.actions?.splice(index, 1, temp);
      updateActionData(temp, meeting);
    }
  };

  const handleChangeDate = (date: Date, index: number) => {
    let temp =
      meeting !== undefined && meeting.actions ? meeting.actions[index] : {};
    temp.date = date;
    meeting.actions?.splice(index, 1, temp);
    updateActionData(temp, meeting);
    setDeadline(date);
  };

  const fieldHandler = (e: any) => {
    setFieldAction(e.target.value);
  };
  const deleteAction = (index: number) => {
    if (meeting && meeting.actions && meeting.actions[index]) {
      let temp =
        meeting && meeting.actions && meeting.actions[index]
          ? (meeting.actions[index] as Action)
          : ({} as Action);
      deleteActionData(temp, meeting);
      meeting.actions?.splice(index, 1);
      setShowDeleteConfirmationAction(false);
    }
  };

  useEffect(() => {
    if (isDeleteConfirmed && activeTitle === 4) {
      deleteAction(currentDeleteId);
      setIsDeleteConfirmed(false);
    }
  }, [isDeleteConfirmed]);

  useEffect(() => {
    if (fieldAction === "") {
      setIsAddDisable(true);
    } else {
      setIsAddDisable(false);
    }
  }, [fieldAction]);

  return (
    <>
      <Tab.Pane eventKey="actions-tabsmeeting">
        <Form className="action-form">
          <div className="form-group">
            <div className="form-add-list">
              <Form.Control
                type="text"
                placeholder={t("type_action") + "......"}
                value={fieldAction}
                onChange={fieldHandler}
              />
            </div>
            <Button
              variant="outline-primary"
              className="h-32 add"
              title="Add Action"
              onClick={handleAddAction}
              disabled={isAddDisable}
            >
              {t("add")}
            </Button>
          </div>

          <div className="module-add-list-wrapper custom-scroll">
            <ul className="module-add-list">
              {meeting.actions?.map((action: Action, index: number) => (
                <li className="module-list-item " key={index}>
                  <div className="module-list-item-action">
                    <span className="type">{action.types}</span>
                    <span
                      className={
                        isAdmin || isAnimateur || action.creator === user
                          ? "btn remove-list-item"
                          : "btn remove-list-item disabled"
                      }
                      title="Delete Action"
                      onClick={() => handleDeleteClick(index)}
                    >
                      <i className="ico-delete"></i>
                    </span>
                  </div>

                  <div className="module-list-item-form">
                    <div className="form-group">
                      <label>{t("the_person_in_charge")}:</label>
                      <div className="form-group-input">
                        <span className="icon">
                          <img src={profileOutline} alt="Status Icon" />
                        </span>
                        <Select
                          className="form-control h-28"
                          value={action.inCharge}
                          IconComponent={() => <KeyboardArrowDownIcon />}
                          onChange={(e) => handleChangePerson(index, e)}
                        >
                          <MenuItem disabled value="0">
                            <span className="disable-item">
                              {t("select_a_person")}
                            </span>
                          </MenuItem>
                          {meeting?.participants?.map((participant, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={participant.reference}
                              >
                                {participant.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>{t("deadline")}:</label>
                      <div className="select-deadline">
                        <div className="form-check form-radio">
                          <Form.Check
                            type="radio"
                            // id="dateDeadline-1"
                            name={"selectDeadline" + index}
                            value={"date"}
                            onChange={(event) =>
                              handleChangeDateCheckBox(event, index)
                            }
                            checked={!action.checked}
                          />
                          <label htmlFor="dateDeadline-1">Date</label>
                        </div>
                        <div className="form-check form-radio">
                          <Form.Check
                            type="radio"
                            // id="dateDeadline-2"
                            value={"immediate"}
                            name={"selectDeadline" + index}
                            onChange={(event) =>
                              handleChangeDateCheckBox(event, index)
                            }
                            checked={action.checked}
                          />
                          <label htmlFor="dateDeadline-2">
                            {t("immediate")}
                          </label>
                        </div>
                      </div>
                      <div className="form-group-input">
                        <span className={action.checked ? "disable" : ""}>
                          <i className="ico-calendar2"></i>
                        </span>
                        <DatePicker
                          selected={
                            new Date(Date.parse(action.date!.toString()))
                          }
                          onChange={(date: Date) =>
                            handleChangeDate(date, index)
                          }
                          className="custom-datepicker form-control h-28"
                          disabled={action.checked}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Form>
      </Tab.Pane>
    </>
  );
};

export default ActionsTab;
