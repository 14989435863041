import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Form } from "react-bootstrap";
import IconGroup from "../Icons/IconGroup";
import { User } from "../../../models/User";
import IconArrow from "../../Components/Icons/IconArrow";
import IconTemplate from "../../Components/Icons/IconTemplate";

interface selectedItem {
  indexGroupe: number;
  indexItem: number;
}

interface ModalReccurentMeetingInterface {
  showUseReccurentModal: boolean;
  handleCloseUseReccurentModal: () => void;
  handleSubItem: (index: number) => void;
  user: User;
  Date: DateConstructor;
  handleDeleteSaveMeetingGroupe: (indexGroupe: number) => void;
  selecteDropItem: (indexGroupe: number, indexItem: number) => void;
  dateReccurenceDiff: (d: Date) => string;
  handleDeleteSaveMeetingListe: (
    indexGroupe: number,
    indexList: number
  ) => void;
  handleLoadReccurentModal: () => void;
  selectedDropItem: selectedItem;
}

const ModalReccurentMeeting: FC<ModalReccurentMeetingInterface> = ({
  showUseReccurentModal,
  handleCloseUseReccurentModal,
  user,
  handleSubItem,
  handleDeleteSaveMeetingGroupe,
  selecteDropItem,
  dateReccurenceDiff,
  Date,
  handleDeleteSaveMeetingListe,
  handleLoadReccurentModal,
  selectedDropItem,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showUseReccurentModal}
      onHide={handleCloseUseReccurentModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="modal-group-title">
            <div className="modal-group-icon">
              <i className="ico-modal-title"></i>
            </div>
            <div>
              <h5 className="modal-title">{t("use_reccurent_meeting")}</h5>
              <h6 className="modal-subtitle">Meeteo</h6>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("choose_template")}</p>
        <Form>
          <Form.Label>{t("group")}</Form.Label>
          <div className="group-content">
            <ul className="group-list custom-scroll">
              {user &&
                user.groupe &&
                user.groupe.map((u, indexGroupe) => (
                  <li className="group-recurrent-item" key={indexGroupe}>
                    <div className="group-recurrent-item-top">
                      <span
                        className="group-name"
                        onClick={() => handleSubItem(indexGroupe)}
                      >
                        <IconArrow
                          className={
                            u.dropDown
                              ? "dropdown-arrow down"
                              : "dropdown-arrow up"
                          }
                        />
                        <IconGroup className="icon-group" /> {u.name}
                      </span>
                      <span
                        className="btn remove-list-item"
                        title="Delete"
                        onClick={() => {
                          handleDeleteSaveMeetingGroupe(indexGroupe);
                        }}
                      >
                        <i className="ico-delete"></i>
                      </span>
                    </div>

                    {u.dropDown ? (
                      <div className="group-dropdown">
                        {u.listeMeeting?.map((liste, indexListe) => (
                          <div
                            className="group-dropdown-item"
                            key={indexListe}
                            style={
                              selectedDropItem.indexGroupe === indexGroupe &&
                              selectedDropItem.indexItem === indexListe
                                ? {
                                    backgroundColor: "#f5f5f5",
                                  }
                                : {}
                            }
                            onClick={() => {
                              selecteDropItem(indexGroupe, indexListe);
                            }}
                          >
                            <span className="sub-group-name">
                              <IconTemplate />{" "}
                              {liste.meeting && liste.meeting.title
                                ? liste.meeting.title
                                : ""}
                              <span className="time">
                                {liste.date &&
                                  dateReccurenceDiff(
                                    new Date(Date.parse(liste.date.toString()))
                                  )}
                              </span>
                            </span>
                            <span
                              className="btn remove-list-item"
                              title="Delete"
                              onClick={() => {
                                handleDeleteSaveMeetingListe(
                                  indexGroupe,
                                  indexListe
                                );
                              }}
                            >
                              <i className="ico-delete"></i>
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </li>
                ))}
            </ul>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={handleCloseUseReccurentModal}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={
            selectedDropItem.indexGroupe === -1 ||
            selectedDropItem.indexItem === -1
          }
          onClick={() => {
            handleLoadReccurentModal();
          }}
        >
          {t("use")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export { ModalReccurentMeeting };
