import { Meeting } from "../../../models/Meeting";
import { Button, Form, Modal } from "react-bootstrap";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import useLogic from "../../Logic/Indicator/uselogic";
import { FC } from "react";
interface indicatorProps {
  meeting: Meeting;
  context: any;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  deactivateModule: Function;
}

const IndicatorComponent: FC<indicatorProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    newIndicator,
    deleteText,
    deactivateModule,
    editedIndex,
    isShowAction,
    showHistoryModal,
    showDeleteModal,
    showAddIndicatorModal,
    currentIndicatorIndex,
    indicatorActions,
    loading,
    historyIndex,
    handleToggleAddModal,
    handleHistoryIndicatorModalClick,
    handleToggleDeleteModal,
    handleIndicatorAction,
    handleChange,
    onConfirmClicked,
    deleteIndicator,
    handleChangeStatus,
    cancelChangeStatus,
    handleIndicatorChangeStatus,
    onConfirmChangeClicked,
    setShowHistoryModal,
    setShowDeleteModal,
    handleCancelClicked,
    getStatusOrTrend,
  } = useLogic(props);

  return (
    <>
      <div className="module-item indicator">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-indicator"></i>
            </span>
            {t("indicator")}
          </h3>
          <span
            className="remove-module"
            onClick={(event) => deactivateModule("indicator")}
          >
            <i className="ico-remove-circle"></i>
          </span>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">
            {t("indicator_module.add_module")}
          </p>

          <div className="module-main-content module-main-content-survey">
            <div className="indicator-list custom-scroll">
              {meeting.indicator?.map((indicator, index) => {
                return (
                  <div className="indicator-item" key={index}>
                    <div className="indicator-item-top">
                      <div>
                        <h3>{indicator.title}</h3>
                        <p>{indicator.description}</p>
                      </div>

                      <div className="indicator-item-actions">
                        <span
                          className="btn"
                          title="History indicator"
                          onClick={() =>
                            handleHistoryIndicatorModalClick(index)
                          }
                        >
                          <i className="ico-calendar"></i>
                        </span>
                        <span
                          className="btn"
                          title="Delete Indicator"
                          onClick={() => handleToggleDeleteModal(index)}
                        >
                          <i className="ico-delete"></i>
                        </span>
                      </div>
                    </div>

                    <div className="indicator-item-bottom">
                      <Form className="indicator-form disable">
                        <div className="form-performance">
                          {editedIndex !== index ? (
                            <>
                              <label className="form-label">
                                {t("indicator_module.status.title")}:
                              </label>
                              <div className="form-status-disable">
                                <span className="icon">
                                  <i className={`ico-${indicator.status}`}></i>
                                </span>{" "}
                                {getStatusOrTrend(indicator.status)}
                              </div>
                            </>
                          ) : (
                            <FormControl>
                              <label className="form-label">
                                {t("indicator_module.status.title")}:
                              </label>
                              <Select
                                className="form-control form-select-status"
                                defaultValue={indicator.status}
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                name="status"
                                onChange={(e) => handleChangeStatus(e, index)}
                              >
                                <MenuItem value="Good">
                                  <span className="icon">
                                    <i className="ico-good"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.good")}
                                </MenuItem>
                                <MenuItem value="Average">
                                  <span className="icon">
                                    <i className="ico-average"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.average")}
                                </MenuItem>
                                <MenuItem value="Low">
                                  <span className="icon">
                                    <i className="ico-low"></i>
                                  </span>{" "}
                                  {t("indicator_module.status.low")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </div>

                        <div className="form-performance">
                          {editedIndex !== index ? (
                            <>
                              <div className="form-status-disable">
                                <label className="form-label">
                                  {t("indicator_module.trend.title")}:
                                </label>
                                <span className="icon">
                                  <i className={`ico-${indicator.trend}`}></i>
                                </span>{" "}
                                {getStatusOrTrend(indicator.trend)}
                              </div>
                            </>
                          ) : (
                            <FormControl>
                              <label className="form-label">
                                {t("indicator_module.trend.title")}:
                              </label>
                              <Select
                                className="form-control form-select-trend"
                                defaultValue={indicator.trend}
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                name="trend"
                                onChange={(e) => handleChangeStatus(e, index)}
                              >
                                <MenuItem value="Ascendant">
                                  <span className="icon">
                                    <i className="ico-ascendant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.ascendant")}
                                </MenuItem>
                                <MenuItem value="Stagnant">
                                  <span className="icon">
                                    <i className="ico-stagnant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.stagnant")}
                                </MenuItem>
                                <MenuItem value="Descendant">
                                  <span className="icon">
                                    <i className="ico-descendant"></i>
                                  </span>{" "}
                                  {t("indicator_module.trend.descendant")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </div>

                        <div className="form-performance">
                          <div className="form-check form-switch">
                            {isShowAction ? (
                              <Form.Check
                                type="switch"
                                className="form-check form-switch"
                                onChange={handleIndicatorAction}
                              />
                            ) : null}
                            <label className="form-label">
                              {t("indicator_module.actions")}
                            </label>
                          </div>

                          {editedIndex !== index ? (
                            <>{indicator.action}</>
                          ) : (
                            <Form.Control
                              as="textarea"
                              rows={2}
                              placeholder={t("indicator_module.no_action")}
                              defaultValue={indicator.action}
                              name="action"
                              onChange={(e) => handleChangeStatus(e, index)}
                            ></Form.Control>
                          )}
                        </div>
                      </Form>

                      <div className="form-performance-btn">
                        {editedIndex !== index ? (
                          <button
                            className="btn btn-outline-primary btn-change-status"
                            onClick={(event) =>
                              handleIndicatorChangeStatus(event, index)
                            }
                          >
                            {t("indicator_module.change_status")}
                          </button>
                        ) : (
                          <div className="d-flex">
                            <button
                              className="btn btn-outline-primary btn-change-cancel"
                              onClick={(event) => cancelChangeStatus(index)}
                            >
                              <i className="ico-cancel"></i> {t("cancel")}
                            </button>

                            <button
                              className="btn btn-outline-primary btn-change-confirm"
                              onClick={onConfirmChangeClicked}
                            >
                              <i className="ico-checkmark"></i> {t("confirm")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <br />
            </div>
          </div>

          <div className="btn-wrapper right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleToggleAddModal}
              title="Add Indicator"
            >
              {t("indicator_module.add")}
            </button>
          </div>
        </div>
      </div>

      {/* Modal history */}
      <Modal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-group-title">
              <div className="modal-group-icon">
                <i className="ico-modal-title"></i>
              </div>
              <div>
                <h5 className="modal-title">
                  {t("indicator_module.history.title")}
                </h5>
                <h6 className="modal-subtitle">Meeteo</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("indicator_module.history.change_status")} "
            <b>
              {meeting.indicator?.length &&
                meeting.indicator[historyIndex]?.title}
            </b>{" "}
            ".
          </p>

          {meeting.indicator &&
            meeting.indicator[historyIndex]?.history
              ?.slice(0)
              .reverse()
              .map((story, index) => {
                return (
                  <div className="history-list custom-scroll" key={index}>
                    <div className="history-item">
                      <div className="history-item-left">
                        <h4>
                          <i className="ico-calendar"></i>
                          {story.date}
                        </h4>
                        <p>
                          <span className="name">{story.user}</span>{" "}
                          {t("indicator_module.history.change_into")} "
                          {getStatusOrTrend(story.status)}".
                        </p>
                        <h5>
                          {t("indicator_module.action")}: "{story.actions}"
                        </h5>
                      </div>
                      <span className={`history-status ${story.status}`}>
                        {getStatusOrTrend(story.status)}
                      </span>
                    </div>
                  </div>
                );
              })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowHistoryModal(false)}
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Delete */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{deleteText.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="remove">
          <p>{deleteText.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteIndicator(currentIndicatorIndex)}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Add */}
      <Modal
        show={showAddIndicatorModal}
        onHide={handleCancelClicked}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div className="modal-group-title">
              <div className="modal-group-icon">
                <i className="ico-modal-title"></i>
              </div>
              <div>
                <h5 className="modal-title">{t("indicator_module.add")}</h5>
                <h6 className="modal-subtitle">Meeteo</h6>
              </div>
            </div>
            <div
              className="btn-close"
              style={{ cursor: "pointer" }}
              onClick={handleCancelClicked}
            >
              {/* <i className="ico-cancel"></i> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("indicator_module.add_performance")}</p>
          <Form>
            <Form.Group className="form-group" controlId="title">
              <Form.Label>{t("title")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="form-group" controlId="title">
              <Form.Label>{t("indicator_module.description")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder=""
                name="description"
                onChange={handleChange}
              ></Form.Control>
            </Form.Group>

            <div className="form-performance-modal">
              <div className="form-performance">
                <FormControl>
                  <label className="form-label">
                    {t("indicator_module.status.title")}:
                  </label>
                  <Select
                    className="form-control form-select-status"
                    IconComponent={() => <KeyboardArrowDownIcon />}
                    name="status"
                    onChange={handleChange}
                  >
                    <MenuItem value="Good">
                      <span className="icon">
                        <i className="ico-good"></i>
                      </span>{" "}
                      {t("indicator_module.status.good")}
                    </MenuItem>
                    <MenuItem value="Average">
                      <span className="icon">
                        <i className="ico-average"></i>
                      </span>{" "}
                      {t("indicator_module.status.average")}
                    </MenuItem>
                    <MenuItem value="Low">
                      <span className="icon">
                        <i className="ico-low"></i>
                      </span>{" "}
                      {t("indicator_module.status.low")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="form-performance">
                <FormControl>
                  <label className="form-label">
                    {t("indicator_module.trend.title")}:
                  </label>
                  <Select
                    className="form-control form-select-trend"
                    IconComponent={() => <KeyboardArrowDownIcon />}
                    name="trend"
                    onChange={handleChange}
                  >
                    <MenuItem value="Ascendant">
                      <span className="icon">
                        <i className="ico-ascendant"></i>
                      </span>{" "}
                      {t("indicator_module.trend.ascendant")}
                    </MenuItem>
                    <MenuItem value="Stagnant">
                      <span className="icon">
                        <i className="ico-stagnant"></i>
                      </span>{" "}
                      {t("indicator_module.trend.stagnant")}
                    </MenuItem>
                    <MenuItem value="Descendant">
                      <span className="icon">
                        <i className="ico-descendant"></i>
                      </span>{" "}
                      {t("indicator_module.trend.descendant")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="form-performance">
                <div className="form-check form-switch">
                  <FormControl>
                    <label className="form-label">
                      {t("indicator_module.action")}
                    </label>
                    <Form.Check
                      type="switch"
                      className="form-check form-switch"
                      onChange={handleIndicatorAction}
                    />
                  </FormControl>
                </div>

                <Form.Control
                  as="textarea"
                  rows={2}
                  disabled={indicatorActions}
                  placeholder={t("indicator_module.no_action")}
                  name="action"
                  onChange={handleChange}
                ></Form.Control>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleCancelClicked}>
            {t("cancel")}
          </Button>

          {loading ? (
            <div className="spinner-border"></div>
          ) : (
            <Button
              variant="primary"
              onClick={onConfirmClicked}
              disabled={
                newIndicator.title &&
                newIndicator.description &&
                newIndicator.status &&
                newIndicator.trend
                  ? false
                  : true
              }
            >
              {t("add")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IndicatorComponent;
