import React from "react";
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useTeamsFx } from "@microsoft/teamsfx-react/build/cjs/useTeamsFx";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import "./App.css";
import TabConfig from "./TabConfig";
import Premeeting from "./Premeeting";
import PostMeeting from "./Containers/PostMeeting/PostMeeting";
import Personal from "./Personal";
import Admin from "./Admin";
import InMeeting from "./Containers/InMeeting/Inmeeting";
import Meeting from "./Meeting";
import Support from "./Support";
import DataProvider from "../Providers/DataProvider/DataProvider";
import SocketProvider from "../Providers/SocketProvider/SocketProvider";
import CustomLoader from "./Components/InMeetingSteps/CustomLoader";
import { ToastProvider } from "react-toast-notifications";
import { authConfig } from "../utils/teamsFxConfig";
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, loading } = useTeamsFx(authConfig);
  return (
    <ToastProvider>
      <Provider
        theme={theme || teamsTheme}
        styles={{ backgroundColor: "#eeeeee" }}
      >
        <SocketProvider>
          <DataProvider>
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/tab" />
                </Route>
                {loading ? (
                  <CustomLoader showButtonReload={false} />
                ) : (
                  <>
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/termsofuse" component={TermsOfUse} />
                    <Route exact path="/tab" component={Tab} />
                    <Route exact path="/config" component={TabConfig} />
                    <Route exact path="/premeeting" component={Premeeting} />
                    <Route exact path="/personal" component={Personal} />
                    <Route exact path="/admin" component={Admin} />
                    <Route exact path="/inmeeting" component={InMeeting} />
                    <Route exact path="/meeting" component={Meeting} />
                    <Route exact path="/postmeeting" component={PostMeeting} />
                    <Route exact path="/support" component={Support} />
                  </>
                )}
              </Switch>
            </BrowserRouter>
          </DataProvider>
        </SocketProvider>
      </Provider>
    </ToastProvider>
  );
}
