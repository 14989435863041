import React, { FC, useState, useEffect, useContext } from "react";
import { Participant } from "../../../models/Meeting";
import user from "../../assets/images/user.jpg";
import sun from "../../assets/images/sun.svg";
import { useTranslation } from "react-i18next";

import DataContext from "../../../Providers/DataProvider/DataContext";

interface participantProps {
  meeting: any;
}

const Participants: FC<participantProps> = ({ meeting }) => {
  const { t } = useTranslation();

  const { userSunny, getUserByRef } = useContext(DataContext);

  const [isUserSunnyInitilized, setIsUserSunnyInitilized] =
    useState<boolean>(false);

  const sunnyTypes = [
    { id: 0, point: 0, src: user },
    { id: 1, point: 1, src: sun },
    { id: 2, point: 50, src: sun },
    { id: 3, point: 100, src: sun },
    { id: 4, point: 150, src: sun },
    { id: 5, point: 200, src: sun },
    { id: 6, point: 250, src: sun },
    { id: 7, point: 300, src: sun },
    { id: 8, point: 350, src: sun },
    { id: 9, point: 400, src: sun },
    { id: 10, point: 450, src: sun },
    { id: 11, point: 500, src: sun },
    { id: 12, point: 550, src: sun },
    { id: 16, point: 600, src: sun },
  ];

  useEffect(() => {
    if (!isUserSunnyInitilized && meeting?.participants) {
      getUserByRef(meeting.participants);
      setIsUserSunnyInitilized(true);
    }
  }, [meeting.participants]);

  const getUserSunnyId = (reference: string): number => {
    const found = userSunny.find((element) => element.reference === reference);
    if (found && found.avatar && found.avatar.current !== undefined) {
      return (found.avatar.current as number) + 1;
    }
    return 0;
  };

  return (
    <div className="module-item role">
      <div className="module-header">
        <h3 className="module-title">
          <span className="module-icon">
            <i className="ico-person"></i>
          </span>
          {t("participant_module.list")}
        </h3>
      </div>

      <div className="module-item-content">
        <p className="module-content-intro">
          {t("participant_module.present_list")}
        </p>
        <div className="module-main-content module-main-content-role">
          {/* custom table */}
          <div className="custom-table">
            {/* table row head */}
            <div className="row-table head">
              <div className="column">
                <i className="ico-name"></i>
                <span>{t("participant_module.name")}</span>
              </div>
              <div className="column">
                <i className="ico-param-role"></i>
                <span>{t("participant_module.role.title")}</span>
              </div>
              <div className="column"></div>
            </div>

            {/* table ro?w */}
            {meeting.participants?.map(
              (participant: Participant, index: number) => {
                return (
                  <div className="row-table-content custom-scroll" key={index}>
                    <div className="row-table">
                      <div className="column column-name">
                        <figure>
                          {meeting?.participants && userSunny ? (
                            <img
                              src={
                                sunnyTypes[
                                  getUserSunnyId(
                                    participant?.reference as string
                                  )
                                ].src
                              }
                              alt="user"
                            />
                          ) : (
                            <img src={user} alt="user" />
                          )}
                        </figure>
                        <span>{participant?.name}</span>
                      </div>
                      <div className="column column-role">
                        {t(`participant_module.role.${participant?.role}`)}
                      </div>
                      <div className="column"></div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Participants;
