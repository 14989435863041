import { Meeting, Rule } from "../../models/Meeting";
import useLogic from "../Logic/Rule/useLogic";
import React, { FC } from "react";

import { Form } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface props {
  meeting: Meeting;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  context: any;
  profile: any;
  deactivateModule: (moduleName: string) => void;
}
const RuleModule: FC<props> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    title,
    deleteText,
    showDeleteModal,
    currentRuleIndex,
    role,
    handleRuleChange,
    addRule,
    deleteRule,
    handleToggleDeleteModal,
    setShowDeleteModal,
    deactivateModule,
  } = useLogic(props);

  return (
    <>
      <div className="module-item rules">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-rules"></i>
            </span>
            {t("rules")}
          </h3>

          <span
            className="remove-module"
            onClick={(event) => deactivateModule("rules")}
          >
            <i className="ico-remove-circle"></i>
          </span>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">{t("rule_module.add_rule")}</p>

          <div className="module-main-content">
            <Form>
              <div className="add-list">
                <div className="form-add-list">
                  <Form.Group className="form-group" controlId="title">
                    <Form.Control
                      type="text"
                      value={title}
                      placeholder={t("rule_module.type")}
                      name="title"
                      onChange={handleRuleChange}
                    />
                  </Form.Group>
                </div>
                <Button
                  onClick={addRule}
                  className="btn btn-outline-primary h-32"
                  title="Add rule"
                  variant="outlined-primary"
                  disabled={title ? false : true}
                >
                  {t("add")}
                </Button>
              </div>

              <ul className="module-add-list custom-scroll">
                {meeting.rules?.map((rule: Rule, index: number) => (
                  <li
                    key={index}
                    className="module-list-item module-list-item-rule"
                  >
                    <span className="type">{rule.title}</span>
                    <span className="person">
                      {!rule.isAdmin && <i className="ico-profile-outline"></i>}
                    </span>
                    <Button
                      className="btn remove-list-item"
                      variant="link"
                      disabled={
                        role !== "admin" &&
                        role !== "animator" &&
                        rule?.owner !== (props?.context?.userObjectId as string)
                      }
                      onClick={() => handleToggleDeleteModal(index)}
                      title="Delete Agenda"
                    >
                      <i className="ico-delete"></i>
                    </Button>
                  </li>
                ))}
              </ul>
            </Form>
          </div>
        </div>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{deleteText.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="remove">
          <p>{deleteText.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteRule(currentRuleIndex)}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RuleModule;
