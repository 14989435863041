import React, { useEffect, useRef, useState, useContext } from "react";
import { Agenda, Meeting } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";

import DataContext from "../../../Providers/DataProvider/DataContext";

interface AgendaProps {
  meeting: Meeting;
  profile: any;
  context: any;
  isEditedIndex: boolean[];
  currentIndex: number;
  setCurrentChildsRef: any;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setShowClosingConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;

  deactivateModule?: (moduleName: string) => void;
  setShowDeleteConfirmationAction?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const useLogic = ({
  meeting,
  isEditedIndex,
  profile,
  context,
  currentIndex,
  setCurrentChildsRef,
  setMeeting,
  deactivateModule,
  setShowDeleteConfirmationAction,
  setShowClosingConfirmation,
  setCurrentIndex,
}: AgendaProps) => {
  const { t } = useTranslation();
  const { insertMeeting, addAgendaData, deleteAgendaData, updateAgendaData } =
    useContext(DataContext);

  const [newAgenda, setNewAgenda] = useState<Agenda>({
    title: "",
    owner: "",
    isAdmin: false,
    duration: 5,
    usedTime: 0,
    isUsed: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentAgendaIndex, setCurrentAgendaIndex] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [deleteText] = useState({
    title: t("remove.agenda.title"),
    text: t("remove.agenda.confirmation"),
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(true);
  const [editedIndex, setEditedIndex] = useState<number[]>([]);
  const [role, setRole] = useState("");
  const childRef = useRef<any>([]);
  const [isEditedAgenda, setIsEditedAgenda] = useState<boolean[]>([]);

  useEffect(() => {
    if (profile) newAgenda.owner = profile?.id;
  }, [profile, newAgenda, meeting]);

  useEffect(() => {
    const indexes = meeting?.agenda?.map(() => -1);
    setEditedIndex(indexes as number[]);
    const isEdited = meeting?.agenda?.map(() => false);
    setIsEditedAgenda(isEdited as boolean[]);
  }, []);

  useEffect(() => {
    if (meeting && meeting.participants && context) {
      let id = meeting.participants?.findIndex(
        (element) => element.reference === context.userObjectId
      );
      setRole(
        meeting?.participants[id]?.role
          ? (meeting?.participants[id]?.role as string)
          : "member"
      );
    }
  }, [meeting.participants]);

  const castObject = (obj: any | undefined): any => {
    if (obj === undefined) {
      return null;
    } else return obj;
  };

  const handleIsUsedClick = (index: number) => {
    setEditedIndex((prev: number[]) => {
      prev[index] = index;
      return [...prev];
    });
    if (
      meeting &&
      meeting.agenda &&
      meeting.agenda[index] &&
      !meeting.agenda[index].isUsed
    ) {
      let tempAgenda = meeting.agenda as Agenda[];
      tempAgenda[index].isUsed = true;
      tempAgenda[index].startTime = new Date(Date.now());
      updateAgendaData(tempAgenda[index], meeting);
    }
  };

  const handleToggleDeleteModal = (index: number) => {
    setCurrentAgendaIndex(index);
    setShowDeleteModal(!showDeleteModal);
  };

  const calculateFullDuration = () => {
    const agendaDurations = meeting.agenda?.map(
      (agenda) => agenda.duration as number
    );
    const durations = agendaDurations?.reduce((acc, curr) => acc + curr, 0);
    if (durations) setTotal(durations);
  };

  const handleAgendaChange = (event: any) => {
    const { name, value } = event.target;
    if (120 - total < 5) {
      newAgenda.duration = 120 - total;
    }
    if (meeting && meeting.participants) {
      let id = castObject(
        meeting.participants?.findIndex(
          (element) => element.reference === context.userObjectId
        )
      );
      let temp = castObject(meeting.participants);
      if (temp[id]?.role !== "admin" && temp[id]?.role !== "animator") {
        newAgenda.isAdmin = false;
      } else {
        newAgenda.isAdmin = true;
      }
    }
    setNewAgenda((prev: any) => ({ ...prev, [name]: value }));
    calculateFullDuration();
  };

  const resetAgenda = () => {
    setNewAgenda({
      title: "",
      owner: "",
      isAdmin: false,
      duration: 5,
      usedTime: 0,
      isUsed: false,
    });
  };

  const addAgenda = () => {
    meeting.agenda?.push({ ...newAgenda });
    addAgendaData(newAgenda, meeting);
    calculateFullDuration();
    resetAgenda();
  };

  const updateAgendaDuration = (event: any, index: number) => {
    if (meeting.agenda && meeting.agenda.length) {
      meeting.agenda[index].duration = parseInt(event.target.value);
      updateAgendaData(meeting.agenda[index], meeting);
      calculateFullDuration();
    }
  };

  const deleteAgenda = (index: number) => {
    let temp =
      meeting && meeting.agenda && meeting.agenda[index]
        ? (meeting?.agenda[index] as Agenda)
        : ({} as Agenda);
    meeting.agenda?.splice(index, 1);
    deleteAgendaData(temp, meeting);
    setShowDeleteConfirmation(true);
    handleToggleDeleteModal(index);
    calculateFullDuration();
    resetAgenda();
  };

  const handleDeleteAgendaStep = (index: number) => {
    setShowDeleteConfirmation(false);
    setCurrentAgendaIndex(index);
  };

  const handleCloseAgenda = (index: number) => {
    setCurrentIndex(index);
    setShowClosingConfirmation(true);
  };

  return {
    childRef,
    meeting,
    newAgenda,
    showDeleteModal,
    total,
    deleteText,
    currentAgendaIndex,
    showDeleteConfirmation,
    editedIndex,
    isEditedIndex,
    isEditedAgenda,
    role,
    currentIndex,
    setCurrentChildsRef,
    setMeeting,
    handleAgendaChange,
    addAgenda,
    deleteAgenda,
    updateAgendaDuration,
    handleToggleDeleteModal,
    setShowDeleteModal,
    setShowDeleteConfirmation,
    setShowDeleteConfirmationAction,
    setShowClosingConfirmation,
    handleIsUsedClick,
    deactivateModule,
    handleDeleteAgendaStep,
    setIsEditedAgenda,
    setCurrentIndex,
    handleCloseAgenda,
  };
};

export default useLogic;
