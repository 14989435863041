import { User } from "../models/User";

const API_BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8080"
    : "https://" + process.env.REACT_APP_BACKEND_HOST;

class UserService {
  public getUser(id: string) {
    return fetch(API_BASE_URL + "/user/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public getUsers(references: string[]) {
    return fetch(API_BASE_URL + `/user/all/${JSON.stringify(references)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public getUserAccess(email: string) {
    return fetch(API_BASE_URL + "/user/access/" + email, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
  
  public createUser(user: User) {
    return fetch(API_BASE_URL + "/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
  }

  public updateUser(user: User) {
    return fetch(API_BASE_URL + "/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
  }
}

export { UserService };
