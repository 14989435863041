import React, { createContext } from "react";
import io from "socket.io-client";

import { Socket } from "socket.io-client";
import {
  DefaultEventsMap
} from "@socket.io/component-emitter";

interface SocketContextInterface {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  emitRule: (meetingId: string) => void;
  emitSurvey: (meetingId: string, surveyId: number) => void;
  emitUpdate: (meetingId: string) => void;
  respondSurvey: (meetingId: string) => void;
  joinRoom: (meetingId: string) => void;
  requestUpdate: (meetingId: string) => void;
  showShareRules: boolean;
  responseUser: boolean;
  setresponseUser: React.Dispatch<React.SetStateAction<boolean>>;
  setresponseRole: React.Dispatch<React.SetStateAction<boolean>>;
  responseRole: boolean;
  sendSurvey: any[];
  setShareRules: React.Dispatch<React.SetStateAction<boolean>>;
  shareID: string;
  updateMeeting: boolean;
  setUpdateMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  emitRejection: (meetingId: string, name: string) => void;
  setrejectedRoleName: (value: React.SetStateAction<string>) => void;
  isRoleRejected: boolean;
  rejectedRoleName: string;
  setisRoleRejected: React.Dispatch<React.SetStateAction<boolean>>;
  emitTest: (meetingId: string) => void;
  requestTime: () => Promise<any>;
}

const SocketContext = createContext<SocketContextInterface>({
  socket: io(""),
  emitRule: (meetingId: string) => {},
  emitUpdate: (meetingId: string) => {},
  emitSurvey: (meetingId: string, surveyId: number) => {},
  respondSurvey: (meetingId: string) => {},
  joinRoom: (meetingId: string) => {},
  showShareRules: false,
  responseUser: false,
  setresponseUser: () => null,
  sendSurvey: [],
  setShareRules: () => null,
  shareID: "",
  setresponseRole: () => null,
  responseRole: false,
  requestUpdate: (meetingId: string) => {},
  updateMeeting: false,
  setUpdateMeeting: () => null,
  emitRejection: (meetingId: string, name: string) => {},
  setrejectedRoleName: (value: React.SetStateAction<string>) => {},
  isRoleRejected: false,
  rejectedRoleName: "",
  setisRoleRejected: () => null,
  emitTest: (meetingId: string) => {},
  requestTime: () => new Promise(() => {}),
});

export default SocketContext;
