import React, { FC } from "react";
import { Form, Button } from "react-bootstrap";
import { Agenda, Meeting } from "../../../models/Meeting";
import sun from "../../assets/images/sun.svg";
import { useTranslation } from "react-i18next";
import useLogic from "../../Logic/Agenda/useLogic";
import IconProfileOutlined from "../Icons/IconProfileOutlined";

interface StepProps {
  meeting: Meeting;
  profile: any;
  context: any;
}

const StepTwo: FC<StepProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    newAgenda,
    showDeleteConfirmation,
    total,
    currentAgendaIndex,
    role,
    handleAgendaChange,
    addAgenda,
    deleteAgenda,
    updateAgendaDuration,
    setShowDeleteConfirmation,
    handleDeleteAgendaStep,
  } = useLogic(props);

  return (
    <>
      <div className="inmeeting-meeteo-bot">
        <div className="inmeeting-meeteo-bot-content">
          {showDeleteConfirmation ? (
            <p>{t("in_meetings.step.add_agenda_items")}</p>
          ) : (
            <div className="bot-content-actions-confirmation">
              <h3>{t("in_meetings.step.delete_agenda")}</h3>
              <p>{t("remove.agenda.confirmation")}</p>
              <div className="bot-actions">
                <Button
                  className="btn btn-cancel"
                  onClick={() => setShowDeleteConfirmation(true)}
                  variant="link"
                >
                  {t("cancel")}
                </Button>
                <Button
                  className="btn-delete"
                  variant="link"
                  onClick={() => deleteAgenda(currentAgendaIndex)}
                >
                  {t("delete")}
                </Button>
              </div>
            </div>
          )}
        </div>
        <figure>
          <img src={sun} alt="Sunny" />
        </figure>
      </div>

      <div className="step-labels">
        <div className="step-label-item active">
          <span>1</span>
        </div>
        <div className="step-label-item active">
          <span>2</span>
        </div>
        <div className="step-label-item">
          <span>3</span>
        </div>
      </div>

      <h3 className="title">{t("agenda")}</h3>
      <p>{t("in_meetings.step.add_agenda_in_meeting")}</p>

      <Form.Group className="form-group" controlId="type">
        <Form.Control
          type="text"
          placeholder={t("agenda_module.type")}
          onChange={handleAgendaChange}
          value={newAgenda.title}
          name="title"
        />
        <Button
          className="btn btn-outline-primary add h-32"
          title="Add Agenda"
          onClick={addAgenda}
          disabled={total < 120 && newAgenda.title ? false : true}
        >
          {t("add")}
        </Button>
      </Form.Group>

      <div className="module-add-list-wrapper custom-scroll">
        <ul className="module-add-list">
          {meeting.agenda?.map((agenda: Agenda, index: number) => (
            <li className="module-list-item module-list-item-role" key={index}>
              <span className="type">{agenda.title}</span>
              <div className="type-actions">
                <div className="form-number">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="ico-timer"></i>
                    </span>
                    <Form.Control
                      type="number"
                      className="form-control"
                      max={30}
                      min={0}
                      value={agenda.duration}
                      onChange={(event) => updateAgendaDuration(event, index)}
                    />
                  </div>
                </div>

                <div className="type-actions-right">
                  <span className="person">
                    {!agenda.isAdmin && <IconProfileOutlined />}
                  </span>
                  <Button
                    className="btn remove-list-item"
                    variant="link"
                    disabled={
                      role !== "admin" && role !== "animator" && agenda.isAdmin
                    }
                    title="Delete Agenda"
                    onClick={() => handleDeleteAgendaStep(index)}
                  >
                    <i className="ico-delete"></i>
                  </Button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {showDeleteConfirmation ? null : <div className="inmeeting-mask"></div>}
    </>
  );
};

export default StepTwo;
