import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import sun from "../../assets/images/sun.svg";
import partlySunny from "../../assets/images/partly-sunny.svg";
import sunShine from "../../assets/images/sunshine.svg";
import partlyCloudy from "../../assets/images/partly-cloudy.svg";
import cloudy from "../../assets/images/cloudy.svg";
import rain from "../../assets/images/rain.svg";
import thunderstorm from "../../assets/images/thunderstorm.svg";
import BotStepObjective from "./BotStepObjective";
import BotStepRole from "./BotStepRole";
import DataContext from "../../../Providers/DataProvider/DataContext";
import { useTranslation } from "react-i18next";
import { Participant } from "../../../models/Meeting";

interface stepOneInterface {
  context?: any;
  profile: any;
}
const StepOne: React.FC<stepOneInterface> = ({ profile, context }) => {
  const { t } = useTranslation();

  const [step, setStep] = React.useState<1 | 2 | 3>(1);
  const [getIt, setGeIt] = React.useState(true);
  const [acceptRole, setAcceptRole] = React.useState(true);
  const [declineRole, setDeclineRole] = React.useState(true);
  const [emptyRole, setEmptyRole] = React.useState(false);
  const [participantWeather, setParticipantWeather] = useState<number>(-1);
  const [me, setMe] = useState<any>();
  const [weather, setWeather] = useState<number>(0);
  const { meeting, insertParticipant } = useContext(DataContext);

  useEffect(() => {
    if (!meeting?.participants || meeting?.participants?.length < 1)
      setEmptyRole(true);
    else setEmptyRole(false);

    if (meeting?.participants) {
      let participant: Participant | undefined = meeting?.participants?.find(
        (element: Participant) => element.reference === context.userObjectId
      );

      if (participant) setMe(participant);

      if (participant?.wheather) setParticipantWeather(participant!.wheather);

      if (!participant?.role) setEmptyRole(true);
    }
  }, [meeting]);

  const handleWeather = (e: any) => {
    if (meeting?.participants) {
      let temp = [...meeting!.participants];
      setWeather(e.target.value);
      let index = meeting!.participants!.findIndex(
        (element: Participant) => element.reference === context.userObjectId
      );

      temp![index].wheather = e.target.value;
      insertParticipant(temp![index], meeting._id, {
        ...meeting,
        participants: temp,
      });
    }
  };
  return (
    <>
      {
        <>
          <div className="inmeeting-meeteo-bot">
            <div className="inmeeting-meeteo-bot-content">
              <div className="bot-content-actions-confirmation">
                {step === 1 ? (
                  <BotStepObjective setGeIt={setGeIt} setStep={setStep} />
                ) : null}

                {step === 2 ? (
                  <BotStepRole
                    setAcceptRole={setAcceptRole}
                    setDeclineRole={setDeclineRole}
                    setStep={setStep}
                    profile={profile}
                  />
                ) : null}

                {step === 3 ? (
                  <>
                    {profile && profile?.displayName ? (
                      <p>
                        {t("welcome")} "{me ? me?.name : profile?.displayName}
                        ", {t("how_do_you_feel")}
                      </p>
                    ) : (
                      <p>
                        {t("welcome")}, {t("how_do_you_feel")}
                      </p>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <figure>
              <img src={sun} alt="Sunny" />
            </figure>
          </div>

          <div className="step-labels">
            <div className="step-label-item active">
              <span>1</span>
            </div>
            <div className="step-label-item">
              <span>2</span>
            </div>
            <div className="step-label-item">
              <span>3</span>
            </div>
          </div>

          <h3 className="title">{t("my_weather")}</h3>
          <p>{t("chose_weather_rappel")}</p>

          <div className="feel-wrapper-list  custom-scroll">
            <ul className="feel-list">
              <li className="feel-item">
                <div className="feel-item-left">
                  <figure>
                    <img src={sunShine} alt="Alternate Text" />
                  </figure>
                  <div className="feel-item-info">
                    <p>{t("i_feel_like")}</p>
                    <h4>{t("sunshine")}</h4>
                  </div>
                </div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    name="meeteoFeel"
                    value={6}
                    onChange={handleWeather}
                    checked={participantWeather === 6}
                  />
                </div>
              </li>

              <li className="feel-item">
                <div className="feel-item-left">
                  <figure>
                    <img src={partlySunny} alt="Weather" />
                  </figure>
                  <div className="feel-item-info">
                    <p>{t("i_feel_like")}</p>
                    <h4>{t("partly_sunny")}</h4>
                  </div>
                </div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    name="meeteoFeel"
                    value={5}
                    onChange={handleWeather}
                    checked={participantWeather === 5}
                  />
                </div>
              </li>

              <li className="feel-item">
                <div className="feel-item-left">
                  <figure>
                    <img src={partlyCloudy} alt="Weather" />
                  </figure>
                  <div className="feel-item-info">
                    <p>{t("i_feel_like")}</p>
                    <h4>{t("partly_cloudy")}</h4>
                  </div>
                </div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    name="meeteoFeel"
                    value={4}
                    onChange={handleWeather}
                    checked={participantWeather === 4}
                  />
                </div>
              </li>

              <li className="feel-item">
                <div className="feel-item-left">
                  <figure>
                    <img src={cloudy} alt="Weather" />
                  </figure>
                  <div className="feel-item-info">
                    <p>{t("i_feel_like")}</p>
                    <h4>{t("cloudy")}</h4>
                  </div>
                </div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    name="meeteoFeel"
                    value={3}
                    onChange={handleWeather}
                    checked={participantWeather === 3}
                  />
                </div>
              </li>

              <li className="feel-item">
                <div className="feel-item-left">
                  <figure>
                    <img src={rain} alt="Weather" />
                  </figure>
                  <div className="feel-item-info">
                    <p>{t("i_feel_like")}</p>
                    <h4>{t("rain")}</h4>
                  </div>
                </div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    name="meeteoFeel"
                    value={2}
                    onChange={handleWeather}
                    checked={participantWeather === 2}
                  />
                </div>
              </li>

              <li className="feel-item">
                <div className="feel-item-left">
                  <figure>
                    <img src={thunderstorm} alt="Weather" />
                  </figure>
                  <div className="feel-item-info">
                    <p>{t("i_feel_like")}</p>
                    <h4>{t("thunderstorm")}</h4>
                  </div>
                </div>
                <div className="form-check form-radio">
                  <Form.Check
                    type="radio"
                    name="meeteoFeel"
                    value={1}
                    onChange={handleWeather}
                    checked={participantWeather === 1}
                  />
                </div>
              </li>
            </ul>
          </div>
          {acceptRole && declineRole && !emptyRole && step !== 3 ? (
            <div className="inmeeting-mask"></div>
          ) : null}
        </>
      }
    </>
  );
};

export default StepOne;
