import { Meeting, Agenda } from "../../models/Meeting";
import React from "react";

import { Form } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useLogic from "../Logic/Agenda/useLogic";

interface props {
  meeting: Meeting;
  profile: any;
  context: any;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  deactivateModule: (moduleName: string) => void;
}

const AgendaModule: React.FC<props> = (props: any) => {
  const { t } = useTranslation();
  const {
    meeting,
    newAgenda,
    showDeleteModal,
    total,
    deleteText,
    currentAgendaIndex,
    role,
    handleAgendaChange,
    addAgenda,
    deleteAgenda,
    updateAgendaDuration,
    handleToggleDeleteModal,
    setShowDeleteModal,
    deactivateModule,
  } = useLogic(props);

  return (
    <>
      <div className="module-item agenda">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-agenda"></i>
            </span>
            {t("agenda")}
          </h3>

          <span
            className="remove-module"
            onClick={(event) => deactivateModule && deactivateModule("agenda")}
          >
            <i className="ico-remove-circle"></i>
          </span>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">
            {t("agenda_module.distribute_time")}
            30 mn.
          </p>
          <div className="module-main-content agenda">
            <Form>
              <div className="add-list">
                <div className="form-add-list">
                  <Form.Group className="form-group" controlId="title">
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder={t("agenda_module.type")}
                      value={newAgenda.title}
                      onChange={handleAgendaChange}
                    />
                  </Form.Group>
                </div>
                <Button
                  className="btn btn-outline-primary h-32"
                  title="Add Agenda"
                  disabled={total < 120 && newAgenda.title ? false : true}
                  onClick={addAgenda}
                  variant="outlined-primary"
                >
                  {t("add")}
                </Button>
              </div>

              <ul className="module-add-list custom-scroll">
                {meeting.agenda?.map((agenda: Agenda, index: number) => (
                  <li
                    key={index}
                    className="module-list-item module-list-item-role"
                  >
                    <span className="type">{agenda.title}</span>
                    <div className="form-number">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ico-timer"></i>
                        </span>
                        <Form.Control
                          type="number"
                          max={30}
                          min={0}
                          className="form-control"
                          value={agenda.duration}
                          onChange={(event) =>
                            updateAgendaDuration(event, index)
                          }
                        />
                      </div>
                    </div>
                    <span className="person">
                      {!agenda.isAdmin && (
                        <i className="ico-profile-outline"></i>
                      )}
                    </span>
                    <Button
                      className="btn remove-list-item"
                      variant="link"
                      disabled={
                        role !== "admin" &&
                        role !== "animator" &&
                        agenda.isAdmin
                      }
                      onClick={() => handleToggleDeleteModal(index)}
                      title="Delete Agenda"
                    >
                      <i className="ico-delete"></i>
                    </Button>
                  </li>
                ))}
              </ul>
            </Form>
          </div>
        </div>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{deleteText.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="remove">
          <p>{deleteText.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteAgenda(currentAgendaIndex)}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AgendaModule;
