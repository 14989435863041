import lvl1 from "../components/assets/images/badges/niveau-1.png";
import lvl2 from "../components/assets/images/badges/niveau-2.png";
import lvl3 from "../components/assets/images/badges/niveau-3.png";
import lvl4 from "../components/assets/images/badges/niveau-4.png";
import lvl5 from "../components/assets/images/badges/niveau-5.png";
import lvl6 from "../components/assets/images/badges/niveau-6.png";
import lvl7 from "../components/assets/images/badges/niveau-7.png";
import lvl8 from "../components/assets/images/badges/niveau-8.png";
import lvl9 from "../components/assets/images/badges/niveau-9.png";

import { Score } from "../models/User";

interface InterfacePoint {
  meeteoUsedPoint: number;
  elementAddPoint: number;
  maxAverageRatePoint: number;
  defaultInitialPoint: number;
  roleAccepted: number;
}

const POINT: InterfacePoint = {
  meeteoUsedPoint: 5,
  elementAddPoint: 5,
  maxAverageRatePoint: 5,
  defaultInitialPoint: 10,
  roleAccepted: 5,
};

const SUNNY_PRICE = [
  { id: 1, point: 1 },
  { id: 2, point: 50 },
  { id: 3, point: 100 },
  { id: 4, point: 200 },
  { id: 5, point: 300 },
  { id: 6, point: 500 },
  { id: 7, point: 700 },
  { id: 8, point: 900 },
  { id: 9, point: 1100 },
  { id: 10, point: 1400 },
  { id: 11, point: 1700 },
  { id: 12, point: 2000 },
  { id: 16, point: 3000 },
];

const BADGES_PRICE = {
  "1": 20,
  "2": 100,
  "3": 200,
  "4": 900,
  "5": 1500,
  "6": 2100,
  "7": 2700,
  "8": 4000,
  "9": 6000,
};

const badges = (userScore: Score) => {
  const total = userScore?.total ? userScore?.total : null;
  switch (true) {
    case total != null && total < BADGES_PRICE[1]:
      return lvl1;

    case total != null && total < BADGES_PRICE[2]:
      return lvl2;

    case total != null && total < BADGES_PRICE[3]:
      return lvl3;

    case total != null && total < BADGES_PRICE[4]:
      return lvl4;

    case total != null && total < BADGES_PRICE[5]:
      return lvl5;

    case total != null && total < BADGES_PRICE[6]:
      return lvl5;

    case total != null && total < BADGES_PRICE[7]:
      return lvl6;

    case total != null && total < BADGES_PRICE[8]:
      return lvl7;

    case total != null && total < BADGES_PRICE[9]:
      return lvl8;

    case total != null && total > BADGES_PRICE[9]:
      return lvl9;

    default:
      return lvl1;
  }
};

const level = (userScore: Score) => {
  const total = userScore?.total ? userScore?.total : null;
  switch (true) {
    case total != null && total < BADGES_PRICE[1]:
      return 1;

    case total != null && total < BADGES_PRICE[2]:
      return 2;

    case total != null && total < BADGES_PRICE[3]:
      return 3;

    case total != null && total < BADGES_PRICE[4]:
      return 4;

    case total != null && total < BADGES_PRICE[5]:
      return 5;

    case total != null && total < BADGES_PRICE[6]:
      return 6;

    case total != null && total < BADGES_PRICE[7]:
      return 7;

    case total != null && total < BADGES_PRICE[8]:
      return 8;

    case total != null && total < BADGES_PRICE[9]:
      return 9;

    case total != null && total > BADGES_PRICE[9]:
      return 10;

    default:
      return 0;
  }
};

export { POINT, BADGES_PRICE, SUNNY_PRICE, badges, level };
