import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  premeeting: {
    '& .nav-pills': {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 22,
      borderRight: '1px solid #E0E0E0',
    
      '& .nav-item': {
        width: '100%',
        paddingRight: 8,
      },
    
      '& .nav-link': {
        width: '100%',
        color: "#242424",
        textAlign: 'left',
        borderRadius: 3,
        padding: '15px 10px 15px 20px',
        fontSize: 16,
        lineHeight: 1,
        transition: 'all .3s ease',
        boxShadow: 'none',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    
        '&.active': {
          background: '#fff',
          color: '#242424',
          transition: 'all .3s ease',
        },
    
        
         '&:hover': {
          background: 'rgba(255,255,255,0.5)',
          transition: 'all .3s ease',
        },
    
        '& i': {
          marginRight: 8,
          color: '#212121',
        },
    
        '&>span': {
          textAlign: 'left',
        },
    
        '@media(max-width:991px)': {
          padding: 8,
          justifyContent: 'center',
    
          '&>span': {
            display: 'none',
          },
    
          '& i': {
            marginRight: 0,
          }
        }
      }
    },

    
  }
}))

export default useStyles