import { ModalBotSection } from "./Components/PreMeetingModal/ModalBotSection";
import { ModalCreateGroupe } from "./Components/PreMeetingModal/ModalCreateGroupe";
import { ModalReccurentMeeting } from "./Components/PreMeetingModal/ModalReccurentMeeting";
import { ModalSaveMeeting } from "./Components/PreMeetingModal/ModalSaveMeeting";
import { ModalInfoModul } from "./Components/PreMeetingModal/ModalInfoModul";
import { ModalEditModule } from "./Components/PreMeetingModal/ModalEditModule";
import { ModalDisactivateModule } from "./Components/PreMeetingModal/ModalDisactivateModule";
import { ModalEnableModule } from "./Components/PreMeetingModal/ModalEnableModule";
import React, { FC, useContext } from "react";
import ParticipantModule from "./modules/Participant";
import AgendaModule from "./modules/Agenda";
import RuleModule from "./modules/Rule";
import IndicatorComponent from "./modules/Indicator/Indicator";
import SurveyModule from "./modules/Survey";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sun from "../../src/components/assets/images/sun.svg";
import addCircle from "../../src/components/assets/images/add-circle.svg";
import removeCircle from "../../src/components/assets/images/remove-circle.svg";
import { Nav, Tab } from "react-bootstrap";
import { Button } from "react-bootstrap";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import Sunny from "../../src/components/Components/Sunny/Sunny";
import IconOutlineStar from "./Components/Icons/IconOutlineStar";
import IconFilledStar from "./Components/Icons/IconFilledStar";
import IconTemplate from "./Components/Icons/IconTemplate";
import DataContext from "../Providers/DataProvider/DataContext";
import useLogic from "./Logic/Premeeting/useLogic";
import { SUNNY_PRICE } from "../utils/Point";
import NotAccess  from "./NotAccess";

interface premeeting {
  context: any;
}

const Premeeting: FC<premeeting> = ({ context }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    meeting,
    setMeeting,
    profile,
    info,
    objective,
    meetingModules,
    user,
    hasAccess,
  } = useContext(DataContext);

  const {
    toggleShowEnableDisableModule,
    isActiveModule,
    handleSaveMeetingModal,
    handleShowEditModule,
    handleUseReccurentModal,
    handleShowInfoModule,
    deactivateModule,
    buySunny,
    isSunnyEquiped,
    module,
    isSavedExit,
    botContent,
    settings,
    gotIt,
    setBotContent,
    newFeature,
    tips,
    tipsNumber,
    setTips,
    showEnableDisableModule,
    toggleActiveModule,
    cancelModuleConfig,
    applyModuleConfig,
    showDeactivateModule,
    handleCloseDeactivateModule,
    moduleName,
    cancelDeactivateModule,
    applyDeactivateModule,
    showEditModal,
    handleCloseEditModal,
    handleTypeClicked,
    handleObjectiveChange,
    handleSaveChange,
    showInfoModule,
    handleCloseInfoModule,
    showSaveMeetingModal,
    handleCloseSaveMeetingModal,
    selectedGroupeSave,
    setselectedGroupeSave,
    handleCreateGroupModal,
    handleSaveMeeting,
    showUseReccurentModal,
    handleCloseUseReccurentModal,
    handleSubItem,
    handleDeleteSaveMeetingGroupe,
    selecteDropItem,
    dateReccurenceDiff,
    Date,
    handleDeleteSaveMeetingListe,
    handleLoadReccurentModal,
    selectedDropItem,
    showCreateGroupModal,
    handleCloseCreateGroupModal,
    handleChangeGroupeText,
    creatGroupeText,
    handleCreateNewGroup,
    type,
  } = useLogic({ context });

  //Dropdown use reccurent meeting
  // const [subItem, showSubitem] = useState(false);

  return (
    <>
      <div className={classes.premeeting}>
        {/* Tab nav */}
        <div className="custom-container premeeting-meeteo">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="pills-meeting"
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="pills-meeting" title="Meeting">
                  <i className="ico-video"></i>
                  <span>{t("meeting")}</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  // onClick={handleNewFeature}
                  eventKey="pills-config"
                  title="Config Sunny"
                >
                  <i className="ico-settings"></i>
                  <span>{t("configuration.config_sunny")}</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="tab-overflow-hidden">
              <Tab.Pane eventKey="pills-meeting">
              {hasAccess === true ? (
                <div className="module-setting">
                  <Button
                    className="module-button-setting"
                    onClick={toggleShowEnableDisableModule}
                    variant="link"
                    disabled={isActiveModule()}
                  >
                    <i className="ico-add-circle"></i>
                    {t("toggle_module")}
                  </Button>
                  <span
                    className="module-button-setting"
                    onClick={handleShowInfoModule}
                    title="Info"
                  >
                    <i className="ico-info"></i>
                  </span>
                </div>
              ) :(
                <NotAccess />
              )}
                {/* ! Module content */}
                {hasAccess === true ? (
                <div className="module-content module-content-inmeeting">
                  <div className="module-content-top">
                    <div>
                      <h2>
                        <span className="module-title-edit">
                          <span
                            className="save-meeting-icon"
                            onClick={handleSaveMeetingModal}
                            // onClick={handleNewFeature}
                            title="Save meeting"
                          >
                            {isSavedExit === true ? (
                              <IconFilledStar />
                            ) : (
                              <IconOutlineStar />
                            )}
                          </span>
                          {info?.subject ? info.subject : t("meeting_title")}
                        </span>
                        <span
                          className="module-button-edit"
                          onClick={handleShowEditModule}
                        >
                          <i className="ico-edit"></i> <span>{t("edit")}</span>
                        </span>
                      </h2>
                      <p className="meeting-status no-type">
                        {meeting.type ? meeting.type : t("no_type")}
                      </p>
                      <p className="meeting-objective">
                        <i className="ico-target"></i>
                        {meeting.objective
                          ? meeting.objective
                          : t("place_of_objective")}
                      </p>
                    </div>
                    <Button
                      variant="secondary"
                      className="reccurent-button"
                      // onClick={handleNewFeature}
                      onClick={handleUseReccurentModal}
                      title="Use current meeting"
                    >
                      <IconTemplate />
                      {t("use_reccurent_meeting")}
                    </Button>
                  </div>

                  {/* Module list */}
                  <div className="module-list custom-scroll">
                    {module?.isParticipantsOn &&
                      meeting?.participants &&
                      meeting?.participants?.length > 0 && (
                        <ParticipantModule
                          meeting={meeting}
                          setMeeting={setMeeting}
                          deactivateModule={deactivateModule}
                          profile={profile}
                          context={context}
                        />
                      )}

                    {module?.isAgendaOn && (
                      <AgendaModule
                        meeting={meeting}
                        setMeeting={setMeeting}
                        profile={profile}
                        context={context}
                        deactivateModule={deactivateModule}
                      />
                    )}

                    {module?.isRulesOn && (
                      <RuleModule
                        meeting={meeting}
                        setMeeting={setMeeting}
                        profile={profile}
                        context={context}
                        deactivateModule={deactivateModule}
                      />
                    )}

                    {module?.isSurveyOn && (
                      <SurveyModule
                        meeting={meeting}
                        setMeeting={setMeeting}
                        deactivateModule={deactivateModule}
                      />
                    )}

                    {module?.isIndicatorOn && (
                      <IndicatorComponent
                        meeting={meeting}
                        context={context}
                        setMeeting={setMeeting}
                        deactivateModule={deactivateModule}
                      />
                    )}
                  </div>
                </div>
                ):(
                  <div >
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="pills-config">
                <div className="module-setting">
                  <span className="module-button-setting">
                    {t("configuration.sunny_configuration")}
                  </span>

                  <span
                    className="module-button-setting"
                    onClick={handleShowInfoModule}
                    title="Info"
                  >
                    <i className="ico-info"></i>
                  </span>
                </div>

                <div className="module-content">
                  <p>{t("configuration.buy_sunny_look")}</p>
                  <p>
                    {t("configuration.my_points")}{" "}
                    <span className="point">{user.score?.point} pts</span>
                  </p>
                  <div className="list-sunny">
                    {SUNNY_PRICE.map((sunnyType, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          buySunny(sunnyType.id);
                        }}
                      >
                        <Sunny
                          key={sunnyType.id}
                          class={
                            user.avatar?.current === sunnyType.id
                              ? "current"
                              : isSunnyEquiped(sunnyType.id)
                              ? "equipped"
                              : "cost"
                          }
                          text={
                            user.avatar?.current === sunnyType.id
                              ? t("Current_avatar")
                              : isSunnyEquiped(sunnyType.id)
                              ? t("Equiped") + "!"
                              : t("Cost") + ":"
                          }
                          point={
                            user.avatar?.current === sunnyType.id
                              ? ""
                              : isSunnyEquiped(sunnyType.id)
                              ? ""
                              : sunnyType.point
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

      {/* Bot section */}
      <ModalBotSection
        botContent={botContent}
        settings={settings}
        addCircle={addCircle}
        removeCircle={removeCircle}
        gotIt={gotIt}
        setBotContent={setBotContent}
        newFeature={newFeature}
        tips={tips}
        tipsNumber={tipsNumber}
        setTips={setTips}
        sun={sun}
      />
      {/* Modal Enable/desable module */}
      <ModalEnableModule
        showEnableDisableModule={showEnableDisableModule}
        toggleShowEnableDisableModule={toggleShowEnableDisableModule}
        meetingModules={meetingModules}
        toggleActiveModule={toggleActiveModule}
        cancelModuleConfig={cancelModuleConfig}
        applyModuleConfig={applyModuleConfig}
      />

      {/* Modal Desactivate module */}
      <ModalDisactivateModule
        showDeactivateModule={showDeactivateModule}
        handleCloseDeactivateModule={handleCloseDeactivateModule}
        moduleName={moduleName}
        cancelDeactivateModule={cancelDeactivateModule}
        applyDeactivateModule={applyDeactivateModule}
      />

      {/* Modal edit module */}
      <ModalEditModule
        showEditModal={showEditModal}
        handleCloseEditModal={handleCloseEditModal}
        info={info}
        type={type}
        handleTypeClicked={handleTypeClicked}
        objective={objective}
        handleObjectiveChange={handleObjectiveChange}
        handleSaveChange={handleSaveChange}
      />

      {/* Modal Info module */}
      <ModalInfoModul
        showInfoModule={showInfoModule}
        handleCloseInfoModule={handleCloseInfoModule}
      />

      {/*Modal save Meeting */}
      <ModalSaveMeeting
        showSaveMeetingModal={showSaveMeetingModal}
        handleCloseSaveMeetingModal={handleCloseSaveMeetingModal}
        info={info}
        user={user}
        selectedGroupeSave={selectedGroupeSave}
        setselectedGroupeSave={setselectedGroupeSave}
        handleCreateGroupModal={handleCreateGroupModal}
        handleSaveMeeting={handleSaveMeeting}
      />

      {/*Modal use reccurent Meeting */}
      <ModalReccurentMeeting
        showUseReccurentModal={showUseReccurentModal}
        handleCloseUseReccurentModal={handleCloseUseReccurentModal}
        user={user}
        handleSubItem={handleSubItem}
        handleDeleteSaveMeetingGroupe={handleDeleteSaveMeetingGroupe}
        selecteDropItem={selecteDropItem}
        dateReccurenceDiff={dateReccurenceDiff}
        Date={Date}
        handleDeleteSaveMeetingListe={handleDeleteSaveMeetingListe}
        handleLoadReccurentModal={handleLoadReccurentModal}
        selectedDropItem={selectedDropItem}
      />

      {/*Modal Create group */}
      <ModalCreateGroupe
        showCreateGroupModal={showCreateGroupModal}
        handleCloseCreateGroupModal={handleCloseCreateGroupModal}
        handleChangeGroupeText={handleChangeGroupeText}
        creatGroupeText={creatGroupeText}
        handleCreateNewGroup={handleCreateNewGroup}
      />
    </>
  );
};

export default Premeeting;
