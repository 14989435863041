import React, { FC, useContext } from "react";
import Participants from "../../Components/PostMeetingComponent/Participants";
import Action from "../../Components/PostMeetingComponent/Action";
import Agenda from "../../Components/PostMeetingComponent/Agenda";
import Rate from "../../Components/PostMeetingComponent/Rate";
import TalkTime from "../../Components/PostMeetingComponent/TalkTime";
import Indicator from "../../Components/PostMeetingComponent/Indicator";
import Survey from "../../Components/PostMeetingComponent/Survey";
import { useTranslation } from "react-i18next";
import DataContext from "../../../Providers/DataProvider/DataContext";

const PostMeeting: FC = () => {
  const { meeting, info } = useContext(DataContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="custom-container postmeeting-meeteo">
        {/* Module content */}
        <div className="module-content">
          <h2>
            <span className="module-title-edit">{info?.subject}</span>
          </h2>
          <p className="meeting-status no-type">
            {meeting?.type ? meeting.type : t("no_type")}
          </p>
          <p className="meeting-objective">
            <i className="ico-target"></i>
            {meeting?.objective ? meeting.objective : t("place_of_objective")}
          </p>

          {/* Module List */}
          <div className="module-list custom-scroll">
            {/* Rate module */}
            <Rate />

            {/* Participants module */}
            <Participants meeting={meeting} />

            {/* Talktime module */}
            {/* <TalkTime /> */}

            {/* Agenda module */}
            <Agenda meeting={meeting} />

            {/* Survey module */}
            <Survey meeting={meeting} />

            {/* Indicator module */}
            <Indicator meeting={meeting} />

            {/* Action module */}
            <Action />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostMeeting;
