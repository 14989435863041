import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface agendaProps {
  meeting: any;
}

const Agenda: FC<agendaProps> = ({ meeting }) => {
  const { t } = useTranslation();

  return (
    <div className="module-item agenda">
      <div className="module-header">
        <h3 className="module-title">
          <span className="module-icon">
            <i className="ico-agenda"></i>
          </span>
          {t("agenda")}
        </h3>
      </div>

      <div className="module-item-content">
        <p className="module-content-intro">{t("agenda_status")}</p>
        <div className="module-main-content agenda">
          <div className="module-add-list-wrapper custom-scroll">
            <ul className="module-add-list">
              {meeting?.agenda?.map((task: any, index: number) => {
                return (
                  <li className="module-list-item" key={index}>
                    <div>
                      <span className="type">{task?.title}</span>
                      {task?.isUsed ? (
                        <div className="estimate-used">
                          <div>
                            <span>{t("in_meetings.tabs.estimate")}:</span>{" "}
                            <span>
                              <i className="ico-timer"></i> {task?.duration} mn
                            </span>
                          </div>
                          <div>
                            <span>{t("in_meetings.tabs.used")}:</span>{" "}
                            <span
                              className={
                                task?.duration > task?.usedTime
                                  ? "lower-estimate"
                                  : "higher-estimate"
                              }
                            >
                              <i className="ico-timer"></i> {task?.usedTime} mn
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="estimate-used">
                          <div>
                            <span>{t("in_meetings.tabs.estimate")}:</span>{" "}
                            <span>
                              <i className="ico-timer"></i> {task?.duration} mn
                            </span>
                          </div>
                          <div>
                            <span>{t("in_meetings.tabs.used")}:</span>{" "}
                            <span className="used">
                              <i className="ico-timer"></i> {task?.usedTime} mn
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
