import React, { useEffect, useContext, useState } from "react";
import StepForm from "../../Components/InMeetingSteps/StepForm";
import DataContext from "../../../Providers/DataProvider/DataContext";
import SocketContext from "../../../Providers/SocketProvider/SocketContext";

interface inMeeting {
  context: any;
}

const InMeeting: React.FC<inMeeting> = ({ context }) => {
  const { profile, info } = useContext(DataContext);

  return (
    <div>
      <StepForm context={context} profile={profile} info={info} />
    </div>
  );
};

export default InMeeting;
