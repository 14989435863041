import React, { useState, useEffect, useContext } from "react";
import { Nav } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Tab } from "react-bootstrap";

import sun from "../../assets/images/sun.svg";
import timer from "../../assets/images/timer.svg";
import ActionsTab from "./ActionsTab";
import AgendaTab from "./AgendaTab";
import IndicatorTab from "./IndicatorTab";
import ParticipantsTab from "./ParticipantsTab";
import RuleTab from "./RuleTabs";
import SurveyTab from "./SurveyTab";
import { useTimer } from "react-timer-hook";
import {
  Meeting,
  Rule,
  Survey,
  OptionResult,
  Participant,
} from "../../../models/Meeting";
import Rating from "@mui/material/Rating";

import EmptyStar from "../Icons/IconEmptyStar";
import FilledStar from "../Icons/IconFilledStar";

import DataContext from "../../../Providers/DataProvider/DataContext";

import SocketContext from "../../../Providers/SocketProvider/SocketContext";

import { useTranslation } from "react-i18next";
import { POINT } from "../../../utils/Point";
import { MailPostMeetingTemplate } from "../../../utils/MailPostMeetingTemplate";

interface inMeeting {
  context: any;
  profile: any;
  info: any;
}

const InmeetingTabs: React.FC<inMeeting> = ({ context, info, profile }) => {
  const { t } = useTranslation();
  //delete text
  const [deleteText] = useState([
    {
      title: t("remove.agenda.title"),
      text: t("remove.agenda.confirmation"),
    },
    {
      title: t("remove.indicator.title"),
      text: t("remove.indicator.confirmation"),
    },
    {
      title: t("remove.rule.title"),
      text: t("remove.rule.confirmation"),
    },
    {
      title: t("remove.survey.title"),
      text: t("remove.survey.confirmation"),
    },
    {
      title: t("remove.action.title"),
      text: t("remove.action.confirmation"),
    },
  ]);

  const [activeTitle, setActiveTitle] = useState(0);
  const [infoTabs, setInfoTabs] = React.useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmationAction] =
    React.useState(false);
  const [showClosingConfirmation, setShowClosingConfirmation] =
    React.useState(false);
  const [showCongratulation, setCongratulation] = React.useState(false);

  const [showSendSurvey, setSendSurvey] = React.useState(false);
  const [isShowVotersNumbers, setShowVotersNumbers] = React.useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState<boolean>(false);
  const [sendSurveyNum, setSendSurveyNum] = useState<number>(-1);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isEditedIndex, setIsEditedIndex] = useState<boolean[]>([]);
  const [showEvalutation, setShowEvalutation] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [ruleIndex, setRuleIndex] = useState(0);
  const [indicatorIndex, setIndicatorIndex] = useState(0);
  const [currentSurvey, setCurrentSurvey] = useState<OptionResult>({});
  const [isSurveyShareNotEmpty, setisSurveyShareNotEmpty] =
    useState<boolean>(false);
  const [currentChildsRef, setCurrentChildsRef] = useState<any>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isRole, setIsRole] = useState<boolean>(false);
  const [isAnimator, setisAnimator] = useState<boolean>(false);
  const [isTimeKeeper, setIsTimekeeper] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [wheatherMoyen, setWheatherMoyen] = useState<number>(0);
  const [numberEffectiveness, setnumberEffectiveness] = useState<number>(0);
  const [numberTimeTalk, setnumberTimeTalk] = useState<number>(0);
  const [showConseil, setShowConseil] = useState<boolean>(false);
  const [tipsNumber, setTipsNumber] = useState<number>(0);
  const [serverTime, setServerTime] = useState<Date>();
  const [timeDiff, setTimeDiff] = useState<number>();

  const [evaluation, setEvaluation] = useState({
    effectiveness: 0,
    timeEvaluation: 0,
  });

  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => console.warn("onExpire called"),
    autoStart: true,
  });

  const {
    meeting,
    setMeeting,
    insertParticipant,
    updateStartTimeData,
    updateAgendaData,
    addResultatData,
    isRefreshing,
    updateMailData,
    sendMail,
    addPointUser,
  } = useContext(DataContext);

  const {
    showShareRules,
    setShareRules,
    shareID,
    sendSurvey,
    respondSurvey,
    responseUser,
    setresponseUser,
    requestTime,
  } = useContext(SocketContext);

  const [isMailSend, setisMailSend] = useState<boolean>(false);

  useEffect(() => {
    let id = meeting?.participants?.findIndex(
      (element) => element.reference === context.userObjectId
    );
    setUserId(id !== undefined ? id : -1);

    if (meeting.participants) {
      meeting.participants.forEach((participant) => {
        if (participant.reference === profile?.id) {
          if (participant.role === "admin" || participant.role === "animator") {
            setIsRole(true);
            if (participant.role === "animator") {
              setisAnimator(true);
            }
          }
          if (participant.role === "time_keeper") {
            setIsTimekeeper(true);
          }
          if (participant.role === "admin") {
            setIsAdmin(true);
          }
        }
      });
    }
  }, []);

  // GET SERVER TIME
  const getTime = async () => {
    const data = await requestTime();
    if (data?.status?.time) {
      const time = new Date(+data?.status?.time);
      setServerTime(() => time);
    } else getTime();
  };

  useEffect(() => {
    const isEditedIndexes = meeting?.agenda?.map(() => false);
    setIsEditedIndex(isEditedIndexes as boolean[]);
    if (!serverTime) getTime().catch(console.error);
    else {
      let now = new Date(Date.now());
      let temp = new Date(serverTime);

      let serverDif = now.getTime() - temp.getTime();

      if (timeDiff) serverDif = timeDiff;
      else {
        setTimeDiff(() => serverDif);
      }

      if (meeting && meeting.isRunning && meeting.startTime) {
        let startTime = new Date(meeting.startTime.toString());
        let t1 = startTime.getTime();
        let t2 = now.getTime();
        let dateDiff = (t1 - t2 + serverDif) / 1000;

        now.setSeconds(now.getSeconds() + sommeSecond() + dateDiff);

        restart(now, true);
      } else {
        let s = sommeSecond();

        now.setSeconds(now.getSeconds() + s);

        let tempParticipant = meeting?.participants as Participant[];
        let isAnimatorPresent = false;

        tempParticipant?.forEach((element) => {
          if (element.role === "animator") {
            isAnimatorPresent = true;
          }
        });

        if (isAnimator || (isRole && !isAnimatorPresent)) {
          restart(now, true);

          updateStartTimeData({ ...meeting, isRunning: true, startTime: now });
        } else {
          restart(now, false);
        }
      }
    }
  }, [isRole, isAnimator, meeting, serverTime]);

  useEffect(() => {
    if (
      minutes + seconds + hours !== 0 &&
      minutes < 1 &&
      meeting?.isRunning &&
      meeting?.participants &&
      !meeting?.participants[userId].timeTalk &&
      !meeting?.participants[userId].effectiveness
    ) {
      setShowEvalutation(true);
    }
  }, [minutes, serverTime]);

  useEffect(() => {
    if (
      minutes + seconds + hours !== 0 &&
      minutes < 1 &&
      meeting &&
      meeting.isRunning &&
      !meeting.postMailSend &&
      !isMailSend &&
      seconds < 3
    ) {
      if (isAdmin) {
        let addrs: string[] = [];
        meeting.participants?.forEach((element) => {
          if (
            element.email &&
            (element.role === "admin" || element.role === "animator")
          ) {
            addrs.push(element.email);
          }
        });

        if (addrs) {
          const mailTemplate = MailPostMeetingTemplate(
            meeting,
            t,
            numberEffectiveness,
            numberTimeTalk,
            wheatherMoyen
          );
          sendMail({
            html: mailTemplate,
            mails: [...addrs],
            subject: t("rapport_meeteo"),
          });

          setisMailSend(true);
          meeting.postMailSend = true;
          updateMailData({ ...meeting });
        }
      }
    }
    if (minutes === 0 && seconds === 1 && meeting.isRunning && isAdmin) {
      let totalEval = 0;
      const totalParticipant = meeting?.participants
        ? meeting.participants.length
        : 1;
      meeting?.participants?.forEach((participant) => {
        if (participant?.effectiveness) totalEval += participant.effectiveness;
        if (participant.timeTalk) totalEval += participant.timeTalk;
      });

      if (totalEval / (totalParticipant * 2) === 5)
        addPointUser(context, POINT.maxAverageRatePoint);
    }
  }, [minutes, seconds]);

  useEffect(() => {
    if (responseUser) {
      if (shareID === meeting._id) {
        setresponseUser(false);
      }
    }
  }, [responseUser]);

  useEffect(() => {
    if (sendSurvey) {
      if (sendSurvey[0].toString() === meeting._id) {
        let temp: OptionResult = { participantReference: "", optionResult: [] };
        if (meeting && meeting.surveys) {
          meeting?.surveys[sendSurvey[1]].options?.forEach((element) => {
            temp.optionResult?.push(false);
          });
        }
        setCurrentSurvey(temp);
        setSendSurveyNum(sendSurvey[1]);
        setSendSurvey(true);
      }
    }
  }, [sendSurvey]);

  useEffect(() => {
    if (
      meeting.participantsDeclineRole &&
      meeting.participantsDeclineRole.length
    ) {
      setShowNotification(true);
    }
  }, [meeting.participantsDeclineRole]);

  const sommeSecond = (): number => {
    let s: number = 0;
    if (meeting && meeting?.agenda) {
      meeting?.agenda.forEach((element) => {
        s += element.duration ? element!.duration : 0;
      });
    }
    s *= 60;
    return s;
  };

  const handleDecline = () => {
    setInfoTabs(false);
    setShowDeleteConfirmationAction(false);
  };

  const handleDelete = () => {
    setInfoTabs(false);
    setIsDeleteConfirmed(true);
    setShowDeleteConfirmationAction(false);
  };

  const handleCancel = () => {
    setIsEditedIndex((prev: boolean[]) => {
      prev[currentIndex] = false;
      return prev;
    });
    setInfoTabs(false);
    setShowClosingConfirmation(false);
  };

  const handleClosing = () => {
    setIsEditedIndex((prev: boolean[]) => {
      prev[currentIndex] = true;
      return prev;
    });
    setInfoTabs(false);
    setShowClosingConfirmation(false);
    setCongratulation(true);
    currentChildsRef[currentIndex].pause();
    if (meeting && meeting.agenda) {
      meeting.agenda[currentIndex].usedTime =
        currentChildsRef[currentIndex]?.minutes;
      meeting.agenda[currentIndex].isUsed = true;
      meeting.agenda[currentIndex].isClosed = true;
      updateAgendaData(meeting.agenda[currentIndex], meeting);
    }
  };

  const handleDotClosing = () => {
    setInfoTabs(false);
    setCongratulation(false);
  };

  const handleShareRules = () => {
    setInfoTabs(false);
    setShareRules(false);
  };

  const addDataWithoutRefresh = (
    resultat: OptionResult,
    meetingT: Meeting,
    resultatId: string
  ) => {
    if (isRefreshing) {
      setTimeout(() => {
        addDataWithoutRefresh(resultat, meetingT, resultatId);
      }, 500);
    } else {
      addResultatData(resultat, meetingT, resultatId);
    }
  };
  const handleSubmitSurvey = () => {
    let temp = meeting.surveys as Survey[];
    let test = temp[sendSurveyNum].resultat?.findIndex(
      (element) => element.participantReference === context.userObjectId
    );
    if (
      sendSurveyNum !== -1 &&
      test === -1 &&
      temp[sendSurveyNum].resultat !== undefined
    ) {
      temp[sendSurveyNum].resultat?.push(currentSurvey);
      let t = temp[sendSurveyNum]._id as string;
      if (temp[sendSurveyNum]._id !== undefined) {
        addResultatData(currentSurvey, meeting, t);
      }

      if (meeting._id) respondSurvey(meeting._id ? meeting._id : "");
    }
    if (
      sendSurveyNum !== -1 &&
      test === undefined &&
      temp[sendSurveyNum].resultat === undefined
    ) {
      temp[sendSurveyNum].resultat = [];
      temp[sendSurveyNum].resultat?.push(currentSurvey);

      let t = temp[sendSurveyNum]._id as string;

      if (temp[sendSurveyNum]._id !== undefined) {
        addDataWithoutRefresh(currentSurvey, meeting, t);
      }

      if (meeting._id) respondSurvey(meeting._id ? meeting._id : "");
    }
    setSendSurvey(false);
    setShowVotersNumbers(true);
    setInfoTabs(false);
    setisSurveyShareNotEmpty(false);
    setSendSurveyNum(-1);
  };

  const handleEffectivnessEval = (newValue: number | null) => {
    if (newValue !== null) {
      setEvaluation(() => {
        return { ...evaluation, effectiveness: newValue };
      });
    }
  };
  const handleTimeEval = (newValue: number | null) => {
    if (newValue !== null) {
      setEvaluation(() => {
        return { ...evaluation, timeEvaluation: newValue };
      });
    }
  };
  const handleEvaluation = () => {
    if (meeting?.participants && meeting?.participants[userId]) {
      let particip = meeting?.participants;
      if (particip && particip[userId]) {
        const user = particip[userId];
        user.effectiveness = evaluation.effectiveness;
        user.timeTalk = evaluation.timeEvaluation;
        insertParticipant(user, meeting._id, meeting);
      }
      setShowEvalutation(false);
    }
  };

  const handleOptionChoice = (e: any, index: number) => {
    let temp = currentSurvey.optionResult as boolean[];
    if (temp[index] !== undefined) {
      temp[index] = !temp[index];
    }
    setCurrentSurvey({
      participantReference: context.userObjectId as string,
      optionResult: temp,
    });
    setisSurveyShareNotEmpty(true);
  };

  const handleOptionChoiceSingle = (e: any, index: number) => {
    let temp = currentSurvey.optionResult as boolean[];
    for (let i = 0; i < temp.length; i++) {
      if (i === index) {
        temp[i] = true;
      } else temp[i] = false;
    }
    setCurrentSurvey({
      participantReference: context.userObjectId as string,
      optionResult: temp,
    });
    setisSurveyShareNotEmpty(true);
  };

  const weatherCalcul = () => {
    let temp = meeting.participants as Participant[];
    let s = 0;
    let se = 0;
    let st = 0;
    let effectiveness = 0;
    let timeTalk = 0;
    let totalP = 0;

    temp.forEach((element) => {
      s += element.wheather ? element.wheather : 6;
      effectiveness += element.effectiveness ? element.effectiveness : 2.5;
      timeTalk += element.timeTalk ? element.timeTalk : 2.5;
      if (element.effectiveness !== undefined) {
        se += 1;
      }
      if (element.timeTalk !== undefined) {
        st += 1;
      }
      totalP++;
    });

    setWheatherMoyen(Math.floor((s / totalP) as number));
    setnumberEffectiveness(se);
    setnumberTimeTalk(st);
  };

  useEffect(() => {
    if (meeting && meeting.participants) weatherCalcul();
  }, [meeting.participants]);

  useEffect(() => {
    setTipsNumber(Math.floor(Math.random() * 18));

    setTimeout(() => {
      setInfoTabs(false);
      setShowConseil(true);
    }, 70000);
  }, []);

  return (
    <>
      <div className="meeteo-tabs">
        <div className="inmeeting-meeteo-bot">
          {showConseil &&
          !showNotification &&
          !showDeleteConfirmation &&
          !showClosingConfirmation &&
          !showCongratulation &&
          !showEvalutation &&
          !showSendSurvey ? (
            <div className="inmeeting-meeteo-bot-content">
              <p className="principal-bot-content">{t(`tips.${tipsNumber}`)}</p>
              <span className="got-it" onClick={() => setShowConseil(false)}>
                {t("got_it")}
              </span>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              {/* <p>
                {t("in_meetings.tabs.your_assistant")}{" "}
                <span>{t("in_meetings.tabs.not_afraid")}</span>
              </p> */}
            </div>
          )}

          {infoTabs ? (
            <div className="inmeeting-meeteo-bot-content">
              <p className="principal-bot-content">{t("guide")}</p>
              <span className="got-it" onClick={() => setInfoTabs(false)}>
                {t("got_it")}
              </span>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              {/* <p>
                {t("in_meetings.tabs.your_assistant")}{" "}
                <span>{t("in_meetings.tabs.not_afraid")}</span>
              </p> */}
            </div>
          )}

          {showNotification && isRole ? (
            <div className="inmeeting-meeteo-bot-content">
              {meeting.participantsDeclineRole?.map((participant, index) => (
                <p className="principal-bot-content" key={index}>
                  {participant.name} has just declined {participant.role}
                </p>
              ))}

              <span
                className="got-it"
                onClick={() => setShowNotification(false)}
              >
                {t("got_it")}
              </span>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              {/* <p>
                {t("in_meetings.tabs.your_assistant")}{" "}
                <span>{t("in_meetings.tabs.not_afraid")}</span>
              </p> */}
            </div>
          )}

          {showDeleteConfirmation ? (
            <div className="inmeeting-meeteo-bot-content">
              <h3>{deleteText[activeTitle].title}</h3>
              <p className="principal-bot-content">
                {deleteText[activeTitle].text}
              </p>
              <div className="bot-actions">
                <Button
                  className="btn btn-decline"
                  variant="link"
                  onClick={handleDecline}
                >
                  {t("decline")}
                </Button>
                <Button
                  className="btn btn-accept"
                  variant="link"
                  onClick={handleDelete}
                >
                  {t("delete")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              {/* <p>
                {t("in_meetings.tabs.your_assistant")}{" "}
                <span>{t("in_meetings.tabs.not_afraid")}</span>
              </p> */}
            </div>
          )}

          {/* Close agenda */}
          {showClosingConfirmation ? (
            <div className="inmeeting-meeteo-bot-content">
              <h3>{t("in_meetings.tabs.closing_agenda")}</h3>
              <p className="principal-bot-content">
                {t("in_meetings.tabs.closing_confirmation")}
              </p>
              <div className="bot-actions">
                <Button
                  className="btn btn-decline"
                  variant="link"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
                <Button
                  className="btn btn-accept"
                  variant="link"
                  onClick={handleClosing}
                >
                  {t("in_meetings.tabs.closing")}
                </Button>
              </div>
            </div>
          ) : null}

          {showCongratulation ? (
            <div className="inmeeting-meeteo-bot-content">
              <h3>{t("in_meetings.tabs.congratulation")}</h3>
              <p className="principal-bot-content">
                {t("in_meetings.tabs.part_completed")}
              </p>
              <div className="bot-actions">
                <Button
                  className="btn"
                  variant="link"
                  onClick={handleDotClosing}
                >
                  {t("got_it")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              {/* <p>
                {t("in_meetings.tabs.your_assistant")}{" "}
                <span>{t("in_meetings.tabs.not_afraid")}</span>
              </p> */}
            </div>
          )}

          {showShareRules && shareID === meeting._id ? (
            <div className="inmeeting-meeteo-bot-content">
              <h3>{t("sharing_rules")}</h3>
              <p className="principal-bot-content">
                {t("unforgettable_rules")}
              </p>
              <ul>
                {meeting.rules?.map((value: Rule, index: number) => {
                  return <li key={index}>{value.title}</li>;
                })}
              </ul>
              <div className="bot-actions">
                <Button
                  className="btn got-it"
                  variant="link"
                  onClick={handleShareRules}
                >
                  {t("got_it")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              {/* <p>
                {t("in_meetings.tabs.your_assistant")}{" "}
                <span>{t("in_meetings.tabs.not_afraid")}</span>
              </p> */}
            </div>
          )}

          {showSendSurvey && sendSurveyNum !== -1 ? (
            <div className="inmeeting-meeteo-bot-content">
              <h3>{t("survey")}</h3>
              <p className="principal-bot-content">
                {meeting &&
                meeting.surveys?.length &&
                sendSurveyNum !== -1 &&
                meeting.surveys[sendSurveyNum]
                  ? meeting.surveys[sendSurveyNum].question
                  : ""}
              </p>
              {meeting &&
                meeting.surveys?.length &&
                sendSurveyNum !== -1 &&
                meeting.surveys[sendSurveyNum] && (
                  <>
                    {meeting.surveys[sendSurveyNum].isMultichoice ? (
                      <>
                        <Form>
                          {(meeting &&
                          meeting.surveys?.length &&
                          meeting.surveys[sendSurveyNum]
                            ? meeting.surveys[sendSurveyNum]
                            : {}
                          ).options?.map((data: string, index: number) => (
                            <div
                              key={index}
                              className="form-check form-checkbox"
                            >
                              <Form.Check
                                type="checkbox"
                                name={"selectSurvey"}
                                id={"tab" + index}
                                multiple={true}
                                onChange={(e) => {
                                  handleOptionChoice(e, index);
                                }}
                                checked={
                                  currentSurvey && currentSurvey.optionResult
                                    ? currentSurvey.optionResult[index]
                                    : false
                                }
                              />
                              <label htmlFor={data}>{data}</label>
                            </div>
                          ))}
                        </Form>
                      </>
                    ) : (
                      <>
                        <Form>
                          {(meeting &&
                          meeting.surveys?.length &&
                          sendSurveyNum !== -1 &&
                          meeting.surveys[sendSurveyNum]
                            ? meeting.surveys[sendSurveyNum]
                            : {}
                          ).options?.map((data: string, index: number) => (
                            <div className="form-check form-radio" key={index}>
                              <Form.Check
                                type="radio"
                                name={"selectSurvey"}
                                id={"tab" + index}
                                onChange={(e) => {
                                  handleOptionChoiceSingle(e, index);
                                }}
                                checked={
                                  currentSurvey && currentSurvey.optionResult
                                    ? currentSurvey.optionResult[index]
                                    : false
                                }
                              />
                              <label htmlFor={data}>{data}</label>
                            </div>
                          ))}
                        </Form>
                      </>
                    )}
                  </>
                )}

              <div className="bot-actions">
                <Button
                  className={
                    isSurveyShareNotEmpty ? "btn submit" : "btn submit disabled"
                  }
                  variant="link"
                  onClick={handleSubmitSurvey}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="inmeeting-meeteo-bot-info">
              <p>
                {t("your_assistant")} <span>{t("dont_be_afraid")}</span>
              </p>
            </div>
          )}

          {showEvalutation && !infoTabs && (
            <div className="inmeeting-meeteo-bot-content">
              <h3>{t("evaluation")}</h3>
              <p>{t("take_the_time")}</p>
              <p>{t("please_note_effectiveness")}</p>
              <div className="rate-stars">
                <div className="stars">
                  <Rating
                    size="large"
                    value={evaluation.effectiveness}
                    icon={<FilledStar />}
                    emptyIcon={<EmptyStar />}
                    onChange={(event, newValue) => {
                      handleEffectivnessEval(newValue);
                    }}
                  />
                </div>
              </div>
              <p>{t("please_note_level")}</p>
              <div className="rate-stars">
                <div className="stars">
                  <Rating
                    value={evaluation.timeEvaluation}
                    icon={<FilledStar />}
                    emptyIcon={<EmptyStar />}
                    onChange={(event, newValue) => {
                      handleTimeEval(newValue);
                    }}
                  />
                </div>
              </div>
              <Button
                variant="link"
                className="submit"
                onClick={() => {
                  handleEvaluation();
                }}
              >
                {t("submit")}
              </Button>
            </div>
          )}

          <figure>
            <img src={sun} alt="Sunny" />
          </figure>
        </div>

        <Tab.Container defaultActiveKey="participants-tabsmeeting">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="participants-tabsmeeting" title="Participant">
                <OverlayTrigger
                  overlay={<Tooltip>{t("participants")}</Tooltip>}
                >
                  <i className="ico-people-community"></i>
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="actions-tabsmeeting" title="Actions">
                <OverlayTrigger
                  overlay={<Tooltip>{t("indicator_module.actions")}</Tooltip>}
                >
                  <i className="ico-data-usage"></i>
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="agenda-tabsmeeting" title="Agenda">
                <OverlayTrigger overlay={<Tooltip>{t("agenda")}</Tooltip>}>
                  <i className="ico-agenda"></i>
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="indicator-tabsmeeting" title="Indicator">
                <OverlayTrigger overlay={<Tooltip>{t("indicator")}</Tooltip>}>
                  <i className="ico-indicator"></i>
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="rule-tabsmeeting" title="Rule">
                <OverlayTrigger overlay={<Tooltip>{t("rules")}</Tooltip>}>
                  <i className="ico-rules"></i>
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="survey-tabsmeeting" title="Survey">
                <OverlayTrigger overlay={<Tooltip>{t("survey")}</Tooltip>}>
                  <i className="ico-survey"></i>
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="tab-content">
            {
              <>
                <ParticipantsTab
                  info={info}
                  context={context}
                  profile={profile}
                />
                <ActionsTab
                  setShowDeleteConfirmationAction={
                    setShowDeleteConfirmationAction
                  }
                  setActiveTitle={setActiveTitle}
                  activeTitle={activeTitle}
                  meeting={meeting}
                  setMeeting={setMeeting}
                  isDeleteConfirmed={isDeleteConfirmed}
                  setIsDeleteConfirmed={setIsDeleteConfirmed}
                  profile={profile}
                />
                <AgendaTab
                  meeting={meeting}
                  profile={profile}
                  context={context}
                  isAdminRight={isAdmin || isTimeKeeper}
                  isEditedIndex={isEditedIndex}
                  currentIndex={currentIndex}
                  setMeeting={setMeeting}
                  setShowClosingConfirmation={setShowClosingConfirmation}
                  setCurrentIndex={setCurrentIndex}
                  setCurrentChildsRef={setCurrentChildsRef}
                />
                <IndicatorTab
                  meeting={meeting}
                  setMeeting={setMeeting}
                  setShowDeleteConfirmationAction={
                    setShowDeleteConfirmationAction
                  }
                  setActiveTitle={setActiveTitle}
                  activeTitle={activeTitle}
                  isDeleteConfirmed={isDeleteConfirmed}
                  setIsDeleteConfirmed={setIsDeleteConfirmed}
                  indicatorIndex={indicatorIndex}
                  setIndicatorIndex={setIndicatorIndex}
                />
                <RuleTab
                  meeting={meeting}
                  context={context}
                  profile={profile}
                  isDeleteConfirmed={isDeleteConfirmed}
                  ruleIndex={ruleIndex}
                  setMeeting={setMeeting}
                  setShowDeleteConfirmationAction={
                    setShowDeleteConfirmationAction
                  }
                  setActiveTitle={setActiveTitle}
                  activeTitle={activeTitle}
                  setIsDeleteConfirmed={setIsDeleteConfirmed}
                  setRuleIndex={setRuleIndex}
                />
                <SurveyTab
                  setSendSurvey={setSendSurvey}
                  isShowVotersNumbers={isShowVotersNumbers}
                  setShowDeleteConfirmationAction={
                    setShowDeleteConfirmationAction
                  }
                  setActiveTitle={setActiveTitle}
                  activeTitle={activeTitle}
                  meeting={meeting}
                  setMeeting={setMeeting}
                  isDeleteConfirmed={isDeleteConfirmed}
                  setIsDeleteConfirmed={setIsDeleteConfirmed}
                  profile={profile}
                />
              </>
            }
          </Tab.Content>
        </Tab.Container>

        <div className="remaining-time">
          <figure>
            <img src={timer} alt="Timer" />
          </figure>
          <div className="counter-info">
            {/* <span className="counter-text">{t("time_remaining")}</span> */}
            <span className="counter-text">{t("time_remaining")}</span>
            <div className="counter-content">
              <span className="counter">{("0" + hours).slice(-2)}</span>
              <span className="counter-separator">:</span>
              <span className="counter">{("0" + minutes).slice(-2)}</span>
              <span className="counter-separator">:</span>
              <span className="counter">{("0" + seconds).slice(-2)}</span>
            </div>
          </div>
        </div>
      </div>
      {infoTabs ||
      showDeleteConfirmation ||
      showClosingConfirmation ||
      showCongratulation ||
      showShareRules ||
      showSendSurvey ||
      showEvalutation ||
      showNotification ? (
        <div className="inmeeting-mask"></div>
      ) : null}
    </>
  );
};

export default InmeetingTabs;
