import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { authConfig, scope } from "../../../utils/teamsFxConfig";
import * as microsoftTeams from "@microsoft/teams-js";

import spinner from "../../../components/assets/images/spinner.svg";

import { TeamsUserCredential } from "@microsoft/teamsfx";
import "./style.css";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

const AuthModal: FC<Props> = ({ setOpen, open }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  //LOGIN GRAPH
  const getAuthorization = async () => {
    setLoading(true);
    const teamsUserCredential = new TeamsUserCredential(authConfig);
    await loginInTeams();
    await teamsUserCredential.login([...scope]);
    setOpen(false);
  };

  // GET TEAMS TOKEN
  const loginInTeams = async () => {
    microsoftTeams.app.initialize();
    return microsoftTeams.authentication.getAuthToken();
  };

  useEffect(() => {
    if (!open) setLoading(false);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-group-title">
              <div className="modal-group-icon">
                <i className="ico-modal-title"></i>
              </div>
              <div>
                <h5 className="modal-title">Permission</h5>
                <h6 className="modal-subtitle">Meeteo</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("permission")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button variant="primary" onClick={getAuthorization}>
            {
              <>
                {!loading ? t("valider") : t("loading") + "..."}
                {loading && (
                  <img className={"spinner"} src={spinner} alt="Weather" />
                )}
              </>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuthModal;
