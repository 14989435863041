import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import Slider from "react-slick";

interface settingInterface {
  dots: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  afterChange: (index: any) => void;
}

interface ModalBotSectionInterface {
  botContent: boolean;
  settings: settingInterface;
  addCircle: string;
  removeCircle: string;
  gotIt: boolean;
  setBotContent: React.Dispatch<React.SetStateAction<boolean>>;
  newFeature: boolean;
  tips: boolean;
  tipsNumber: number;
  setTips: React.Dispatch<React.SetStateAction<boolean>>;
  sun: string;
}

const ModalBotSection: FC<ModalBotSectionInterface> = ({
  botContent,
  settings,
  addCircle,
  removeCircle,
  gotIt,
  setBotContent,
  newFeature,
  tips,
  tipsNumber,
  setTips,
  sun,
}) => {
  const { t } = useTranslation();
  return (
    <Draggable cancel=".click">
      <div className="bot-section">
        {botContent ? (
          <div className="bot-content">
            <div className="bot-content-top bot-slick">
              <Slider className="click" {...settings}>
                <div className="bot-item">
                  <h3>{t("bot_section.hello")}</h3>
                  <p>{t("bot_section.meeteo_assistant")}</p>
                </div>
                <div className="bot-item">
                  <h3>{t("bot_section.module")}</h3>
                  <p>
                    {t("bot_section.manipulate_modules")}{" "}
                    <img src={addCircle} alt="Add" />
                    {t("bot_section.click_icon")}{" "}
                    <img src={removeCircle} alt="Remove" />{" "}
                    {t("bot_section.icon_place")}
                  </p>
                </div>
                <div className="bot-item">
                  <h3>{t("bot_section.advice")}</h3>
                  <p>{t("bot_section.wishing")}</p>
                </div>
              </Slider>
            </div>
            <Slider className="click" {...settings}></Slider>
            {gotIt ? (
              <span
                className="got-it click"
                onClick={() => setBotContent(false)}
              >
                {t("bot_section.got_it")}
              </span>
            ) : null}
          </div>
        ) : null}

        {newFeature === true ? (
          <div className="bot-content">
            <div className="bot-content-info">
              <p>Nouvelle fonctionnalité à venir !</p>
            </div>
          </div>
        ) : null}

        {tips === true ? (
          <div className="bot-content">
            <div className="bot-content-top bot-slick">
              <Slider className="click" {...settings}>
                <div className="bot-item">
                  <h3>{t("bot_section.advice")}</h3>

                  <p>{t(`tips.${tipsNumber}`)}</p>
                </div>
              </Slider>
            </div>
            <Slider className="click" {...settings}></Slider>
            <span className="got-it click" onClick={() => setTips(false)}>
              {t("bot_section.got_it")}
            </span>
          </div>
        ) : null}

        <figure className="bot-image">
          <img src={sun} alt="Bot" />
        </figure>
      </div>
    </Draggable>
  );
};

export { ModalBotSection };
