import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import DataContext from "../../../Providers/DataProvider/DataContext";
import SocketContext from "../../../Providers/SocketProvider/SocketContext";
import { ROLE } from "../../../utils/MeetingVar";
import { POINT } from "../../../utils/Point";
import { useTranslation } from "react-i18next";
interface BotStepRoleProps {
  setDeclineRole: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptRole: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  profile: any;
}

const BotStepRole = ({
  setDeclineRole,
  setAcceptRole,
  setStep,
  profile,
}: BotStepRoleProps) => {
  const { t } = useTranslation();

  const { meeting, insertMeeting, contextData, addPointUser } =
    useContext(DataContext);

  const { emitRejection } = useContext(SocketContext);

  const [role, setRole] = useState<string>("");

  const [showButtonDecline, setShowButtonDecline] = useState<boolean>(false);

  const getRoleParticipant = () => {
    let role = "";
    meeting.participants?.forEach((participant) => {
      if (
        participant?.reference?.toString() ===
        contextData?.userObjectId?.toString()
      ) {
        if (participant.role) {
          role = participant.role;
          setRole(role);
        }
        if (
          participant?.role === ROLE.ANIMATOR ||
          participant?.role === ROLE.NOTE_TAKER ||
          participant?.role === ROLE.TIME_KEEPER
        ) {
          setShowButtonDecline(true);
        }
      }
    });
  };

  const handleDecline = () => {
    let meetingInMeeting = meeting;
    setDeclineRole(false);
    setStep((currentStep) => (currentStep + 1) as 1 | 2 | 3);
    meetingInMeeting.participants?.forEach((participant) => {
      if (participant.reference === profile?.id) {
        if (
          meeting._id &&
          participant.name &&
          participant.role !== ROLE.MEMBER &&
          participant.role !== ROLE.ADMIN &&
          participant.role !== ROLE.ANIMATOR
        ) {
          const participantDeclineRole = {
            reference: participant.reference,
            name: participant.name,
            role: participant.role,
          };
          meeting.participantsDeclineRole?.push(participantDeclineRole);
          participant.role = "member";
          setRole(participant.role);
          emitRejection(meeting._id, participant.name);
        }
      }
    });
    insertMeeting({ ...meetingInMeeting });
  };

  const handleAccept = () => {
    setAcceptRole(false);
    addPointUser(contextData, POINT.roleAccepted);
    setStep((currentStep) => (currentStep + 1) as 1 | 2 | 3);
  };

  useEffect(() => {
    getRoleParticipant();
  }, []);

  return (
    <div className="bot-role">
      <h3>{t("your_assistant")}</h3>
      <p>
        {t("congrats")} {role ? role : t("participant_module.role.member")}.{" "}
        {t("considered_as_participant")}
      </p>
      <p>
        <u>{t("role_to")}</u>
        <br /> {t("reachs_objectives")}
        <br />
        {t("everybody_contribute")} <br />
      </p>
      <div className="bot-actions">
        <Button
          className="btn btn-decline"
          variant="link"
          onClick={handleDecline}
          hidden={!showButtonDecline}
        >
          {t("decline")}
        </Button>
        <Button
          className="btn btn-accept"
          variant="link"
          onClick={handleAccept}
        >
          {t("accept")}
        </Button>
      </div>
    </div>
  );
};

export default BotStepRole;
