import { Meeting, Participant } from "../../models/Meeting";
import React, { useEffect, useState, useContext } from "react";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import user from "../assets/images/user.jpg";
import sun from "../assets/images/sun.svg";

import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import SelectMultiple from "react-select";
import { useTranslation } from "react-i18next";

import { useGraph } from "@microsoft/teamsfx-react/build/cjs/useGraph";

import SocketContext from "../../Providers/SocketProvider/SocketContext";
import DataContext from "../../Providers/DataProvider/DataContext";

interface props {
  meeting: Meeting;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  deactivateModule: Function;
  profile: any;
  context: any;
}

interface ParticipantType {
  value: string;
  label: string;
}

const ParticipantModule: React.FC<props> = ({
  meeting,
  setMeeting,
  deactivateModule,
  profile,
  context,
}) => {
  const { emitUpdate, responseRole, setresponseRole } =
    useContext(SocketContext);
  const {
    refreshMeeting,
    isRefreshing,
    insertMeeting,
    userSunny,
    getUserByRef,
  } = useContext(DataContext);

  const { t } = useTranslation();

  const { data } = useGraph(
    async (graph) => {
      // Call graph api directly to get all user profile
      const participants = await graph.api("/users").get();
      return { participants };
    },
    { scope: ["User.Read.All"] }
  );

  const [selectedParticipant, setselectedParticipant] = useState<string[]>([]);

  const [animatorAvailable, setAnimatorAvailable] = useState<boolean>(false);
  const [adminAvailable, setAdminAvailable] = useState<boolean>(true);

  const [isUserSunnyInitilized, setIsUserSunnyInitilized] =
    useState<boolean>(false);

  const optionParticipants = (participantFilter: string[]) => {
    const participants = data?.participants.value
      .filter((current: any) => {
        return (
          !participantFilter.length ||
          participantFilter.indexOf(current.displayName) === -1
        );
      })
      .map((currentParticipant: any) => {
        return {
          value: currentParticipant.displayName,
          label: currentParticipant.displayName,
        };
      });
    return participants as ParticipantType[];
  };

  const handleShowAddParticipantModal = () => setShowAddParticimantModal(true);
  const handleCloseAddParticipantModal = () => {
    setShowAddParticimantModal(false);
  };

  const [showAddParticimantModal, setShowAddParticimantModal] = useState(false);

  const [newParticipants, setNewParticipants] = useState<string[]>([]);

  const [userDatabase, setUserDatabase] = useState<boolean>(false);

  const [currentParticipantIndex, setCurrentParticipantIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteText] = useState({
    title: t("remove.participant.title"),
    text: t("remove.participant.confirmation"),
  });

  const handleToggleDeleteModal = (index: number) => {
    setCurrentParticipantIndex(index);
    setShowDeleteModal(!showDeleteModal);
  };

  const applyAddParticipants = () => {
    insertMeeting({
      ...meeting,
      participants: meeting.participants?.concat(
        newParticipants.map((current) => ({
          reference: "unknown",
          name: current,
          role: "member",
        }))
      ),
    });
    setNewParticipants([]);
    setShowAddParticimantModal(false);
  };

  const deleteParticipant = (index: number) => {
    meeting.participants?.splice(index, 1);
    insertMeeting({ ...meeting });
    setShowDeleteModal(false);
  };

  const updataParticipantRole = (event: any, index: number) => {
    if (meeting.participants && meeting.participants.length && meeting._id) {
      meeting.participants[index].role = event.target.value;
      emitUpdate(meeting._id);
      insertMeeting({ ...meeting });
    }
  };

  const isActiveAddParticipant = () => {
    let test = true;
    meeting.participants?.forEach((participant) => {
      if (participant.reference && participant.reference === profile?.id) {
        if (
          (participant.role && participant.role === "admin") ||
          participant.role === "animator"
        ) {
          test = false;
        }
      }
    });
    return test;
  };

  const verifyRole = () => {
    if (meeting.participants) {
      const participants = meeting.participants;
      for (
        let indexParticipant = 0;
        indexParticipant < participants.length;
        indexParticipant++
      ) {
        if (participants[indexParticipant].role === "animator") {
          setAnimatorAvailable(true);
          break;
        } else {
          setAnimatorAvailable(false);
        }
      }
      meeting.participants.forEach((participant) => {
        if (participant.reference === profile?.id) {
          setUserDatabase(true);
        }
      });
    }
  };

  useEffect(() => {
    isActiveAddParticipant();
    verifyRole();
  }, [meeting.participants]);

  useEffect(() => {
    if (responseRole) {
      setresponseRole(false);
      refreshMeeting(context);
    }
  }, [responseRole]);

  useEffect(() => {
    const participant = () => {
      const participantBD = [""];
      meeting.participants?.map((current: Participant) => {
        if (current && current.name) participantBD.push(current.name);
      });
      return participantBD as string[];
    };
    setselectedParticipant(participant);
  }, [meeting]);

  const sunnyTypes = [
    { id: 0, point: 0, src: user },
    { id: 1, point: 1, src: sun },
    { id: 2, point: 50, src: sun },
    { id: 3, point: 100, src: sun },
    { id: 4, point: 150, src: sun },
    { id: 5, point: 200, src: sun },
    { id: 6, point: 250, src: sun },
    { id: 7, point: 300, src: sun },
    { id: 8, point: 350, src: sun },
    { id: 9, point: 400, src: sun },
    { id: 10, point: 450, src: sun },
    { id: 11, point: 500, src: sun },
    { id: 12, point: 550, src: sun },
    { id: 16, point: 600, src: sun },
  ];

  useEffect(() => {
    if (!isUserSunnyInitilized && meeting && meeting.participants) {
      getUserByRef(meeting.participants);
      setIsUserSunnyInitilized(true);
    }
  }, [meeting.participants]);

  const getUserSunnyId = (reference: string): number => {
    const found = userSunny.find((element) => element.reference === reference);
    if (found && found.avatar && found.avatar.current !== undefined) {
      return (found.avatar.current as number) + 1;
    }
    return 0;
  };

  return (
    <>
      <div className="module-item role">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-person"></i>
            </span>
            {t("participant_module.title")}
          </h3>
          <span
            className="remove-module"
            onClick={(event) => deactivateModule("participants")}
          >
            <i className="ico-remove-circle"></i>
          </span>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">
            {t("participant_module.default_animator")}
          </p>
          <div className="module-main-content module-main-content-role">
            {/* custom table */}
            <div className="custom-table">
              {/* table row head */}
              <div className="row-table head">
                <div className="column">
                  <i className="ico-name"></i>
                  <span>{t("participant_module.name")}</span>
                </div>
                <div className="column">
                  <i className="ico-param-role"></i>
                  <span>{t("participant_module.role.title")}</span>
                </div>
                <div className="column"></div>
              </div>

              {/* table row */}
              <div className="row-table-content custom-scroll">
                {meeting.participants?.map(
                  (participant: Participant, index: number) => (
                    <div key={index} className="row-table">
                      <div className="column column-name">
                        <figure>
                          {meeting && meeting.participants && userSunny ? (
                            <img
                              src={
                                sunnyTypes[
                                  getUserSunnyId(
                                    participant.reference as string
                                  )
                                ].src
                              }
                              alt="user"
                            />
                          ) : (
                            <img src={user} alt="user" />
                          )}
                        </figure>
                        <span>{participant.name}</span>
                      </div>

                      <div className="column column-role">
                        <FormControl fullWidth className="no-border">
                          <Select
                            id="role-paricipant"
                            value={participant.role}
                            onChange={(event) => {
                              if (userDatabase) {
                                updataParticipantRole(event, index);
                              }
                            }}
                            IconComponent={() => <KeyboardArrowDownIcon />}
                            disabled={
                              participant.role === "admin" ||
                              participant.reference === profile?.id ||
                              isActiveAddParticipant()
                            }

                            // nChange={handleChangeRole}
                          >
                            <MenuItem value="admin" hidden={adminAvailable}>
                              {t("participant_module.role.admin")}
                            </MenuItem>
                            <MenuItem
                              value="animator"
                              hidden={animatorAvailable}
                            >
                              {t("participant_module.role.animator")}
                            </MenuItem>

                            <MenuItem value="member">
                              {t("participant_module.role.member")}
                            </MenuItem>
                            <MenuItem value="note_taker">
                              {t("participant_module.role.note_taker")}
                            </MenuItem>
                            <MenuItem value="time_keeper">
                              {t("participant_module.role.time_keeper")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="column remove-participant">
                        <span
                          onClick={() => handleToggleDeleteModal(index)}
                          hidden={participant.role === "admin"}
                        >
                          <i className="ico-person-delete" hidden></i>
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="btn-wrapper right">
            {/* <Button
                type="button"
                className="btn btn-primary"
                title="Add participant"
                onClick={handleShowAddParticipantModal}
                disabled={isActiveAddParticipant()}
              >
                {t("participant_module.add")}
              </Button> */}
          </div>
        </div>
      </div>

      <Modal
        show={showAddParticimantModal}
        onHide={handleCloseAddParticipantModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-group-title">
            <div className="modal-group-icon">
              <i className="ico-modal-title"></i>
            </div>
            <div>
              <h5 className="modal-title">{t("participant_module.add")}</h5>
              <h6 className="modal-subtitle">Meeteo</h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("participant_module.add_module")}</p>

          <Form className="form-participant">
            <Form.Group className="form-group" controlId="add-participant">
              <Form.Label>{t("participant_module.participant")}</Form.Label>
              <div className="form-group-input">
                <i className="ico-add-participant"></i>
                <SelectMultiple
                  isMulti
                  name="participants"
                  options={optionParticipants(selectedParticipant)}
                  className="select-multiple"
                  classNamePrefix="select"
                  placeholder="Add required attendees"
                  onChange={(event: any) =>
                    setNewParticipants(
                      event.map((current: { value: any }) => current.value)
                    )
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleCloseAddParticipantModal}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => applyAddParticipants()}
            disabled={newParticipants.length ? false : true}
          >
            {t("save_change")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal delete */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">{deleteText.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="remove">
          <p>{deleteText.text}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => deleteParticipant(currentParticipantIndex)}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ParticipantModule;
