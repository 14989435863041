import { FormControl } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Button, Form, ProgressBar } from "react-bootstrap";
import { Meeting, Survey } from "../../../models/Meeting";
import SocketContext from "../../../Providers/SocketProvider/SocketContext";

import DataContext from "../../../Providers/DataProvider/DataContext";

interface BotSurveyProps {
  setShowDeleteConfirmationAction: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSendSurvey: React.Dispatch<React.SetStateAction<boolean>>;
  isShowVotersNumbers: boolean;

  activeTitle: number;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;

  meeting: Meeting;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;

  isDeleteConfirmed: boolean;
  setIsDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>;

  profile: any;
}

const SurveyTab: React.FC<BotSurveyProps> = ({
  setSendSurvey,
  isShowVotersNumbers,
  setShowDeleteConfirmationAction,
  setActiveTitle,
  meeting,
  setMeeting,
  isDeleteConfirmed,
  setIsDeleteConfirmed,
  activeTitle,
  profile,
}: BotSurveyProps) => {
  const { t } = useTranslation();

  const {
    insertMeeting,
    updateSurveysData,
    addSurveysData,
    deleteSurveysData,
  } = useContext(DataContext);
  const [isShowAddSurvey, setShowAddSurvey] = React.useState(false);
  const [isShowModifSurvey, setShowModifSurvey] = React.useState(false);
  const [isShowResultSurvey, setShowResultSurvey] = React.useState(false);

  const [editIndex, setEditIndex] = useState<number>(-1);
  const [currentDeleteId, setcurrentDeleteId] = useState<number>(-1);

  const [listeChoice, setListeChoice] = useState<string[]>([]);
  const [isMultichoice, setIsMultichoice] = useState<boolean>(false);
  const [isBoutonDisabled, setIsBoutonDisabled] = useState<boolean>(true);

  const [editData, setEditData] = useState<Survey | undefined>();
  const [question, setQuestion] = useState<string>("");

  const [resultSurveyIndex, setresultSurveyIndex] = useState<number>(0);

  const { emitSurvey } = useContext(SocketContext);

  const [isAdmin, setisAdmin] = useState<boolean>(false);
  const [isAnimateur, setisAnimateur] = useState<boolean>(false);

  const [user, setUser] = useState("");
  const [role, setrole] = useState<string>("");

  useEffect(() => {
    if (profile !== undefined) setUser(profile?.id);
  }, []);

  useEffect(() => {
    if (user) {
      let temp = meeting.participants?.find(
        (participant) => participant.reference + "" === user + ""
      );
      setrole(temp && temp?.role ? temp?.role : "member");
      if (temp?.role === "admin") {
        setisAdmin(true);
      }
      if (temp?.role === "animator") {
        setisAnimateur(true);
      }
    }
  }, [user]);

  const handleListe = (e: any, index: number) => {
    let temp = listeChoice;
    temp[index] = e.target.value;
    setListeChoice([...temp]);
  };

  const handleShowDeleteOption = (index: number) => {
    var temp = listeChoice;
    temp.splice(index, 1);
    setListeChoice([...temp]);
  };

  const handleShowDeleteSurvey = (index: number) => {
    setActiveTitle(3);
    setcurrentDeleteId(index);
    setShowDeleteConfirmationAction(true);
  };

  const deleteSurvey = (index: number) => {
    let temp =
      meeting && meeting.surveys
        ? (meeting.surveys[index] as Survey)
        : ({} as Survey);
    meeting.surveys?.splice(index, 1);
    deleteSurveysData(temp, meeting);
    setShowDeleteConfirmationAction(false);
  };

  const handleSwitch = (e: any) => {
    setIsMultichoice(!isMultichoice);
  };

  const addOption = () => {
    let temp = listeChoice;
    temp.push("");
    setListeChoice([...temp]);
  };

  const addSurvey = () => {
    var temp = {
      question: question,
      isMultichoice: isMultichoice,
      options: listeChoice,
      creator: user,
    };
    meeting.surveys?.push(temp);
    addSurveysData(temp, meeting);
    setShowAddSurvey(false);
  };

  const editSurvey = () => {
    var temp = {
      _id: editData && editData._id ? editData._id : "",
      question: question,
      isMultichoice: isMultichoice,
      options: listeChoice,
      resultat: editData && editData.resultat ? editData.resultat : [],
      isShared: editData && editData.isShared ? editData.isShared : false,
      creator: editData && editData.creator ? editData.creator : "",
    };
    meeting.surveys?.splice(editIndex, 1, temp);

    updateSurveysData(temp, meeting);

    setEditData(undefined);
    setShowModifSurvey(false);
  };

  const handleQuestion = (e: any) => {
    setQuestion(e.target.value);
  };

  const isOprtionEmpty = (): boolean => {
    var temp = false;
    listeChoice.forEach((element) => {
      if (element.trim() === "") {
        temp = true;
      }
    });
    return temp;
  };

  useEffect(() => {
    if (isShowAddSurvey) {
      setListeChoice(["", ""]);
      setQuestion("");
      setIsMultichoice(false);
    }
  }, [isShowAddSurvey]);

  useEffect(() => {
    if (isShowModifSurvey) {
      if (editData) {
        setQuestion(editData.question as string);
        setListeChoice(editData.options as string[]);
        setIsMultichoice(editData.isMultichoice as boolean);
      }
    }
  }, [isShowModifSurvey]);

  useEffect(() => {
    if (question.trim() === "" || isOprtionEmpty()) {
      setIsBoutonDisabled(true);
    } else {
      setIsBoutonDisabled(false);
    }
  }, [question, listeChoice]);

  useEffect(() => {
    if (isDeleteConfirmed && activeTitle === 3) {
      deleteSurvey(currentDeleteId);
      setIsDeleteConfirmed(false);
    }
  }, [isDeleteConfirmed]);

  const handleEditSurveyModal = (data: Survey, index: number) => {
    setEditIndex(index);
    setEditData(data);
    setShowModifSurvey(true);
  };

  const handleSendSurvey = (index: number) => {
    if (meeting && meeting._id) {
      let temp = meeting && meeting.surveys ? meeting.surveys : [];
      temp[index].isShared = true;
      updateSurveysData(temp[index], meeting);
      emitSurvey(meeting._id, index);
    }
  };

  const handleShowResultSurvey = (index: number) => {
    setresultSurveyIndex(index);
    setShowResultSurvey(true);
  };

  const calculPourcentage = (survey: Survey): number => {
    let temp = survey.resultat ? survey.resultat : [];
    let temp2 = meeting.participants ? meeting.participants : [];
    if (temp.length === 0 || temp2.length === 0) {
      return 0;
    } else {
      return Math.round((temp.length / temp2.length) * 100);
    }
  };

  const calculPourcentageOption = (index: number): number => {
    if (meeting && meeting.surveys) {
      let temp = meeting.surveys[resultSurveyIndex];
      if (temp.resultat) {
        let result = temp.resultat;
        let somme = 0;
        result.forEach((element) => {
          if (element.optionResult) {
            if (element.optionResult[index]) {
              somme++;
            }
          }
        });

        somme =
          meeting && meeting.participants
            ? (somme * 100) / meeting.participants.length
            : 0;

        return Math.round(somme);
      }
    }
    return 0;
  };

  return (
    <>
      <Tab.Pane eventKey="survey-tabsmeeting">
        <div className="survey-form">
          <Button
            variant="primary"
            className="btn btn-primary btn-add-survey"
            type="button"
            onClick={() => setShowAddSurvey(true)}
          >
            {t("in_meetings.tabs.add_survey")}
          </Button>

          {/*Add survey */}
          {isShowAddSurvey ? (
            <div className="add-survey">
              <Button
                variant="link"
                className="btn-add-survey-back"
                type="button"
                title="Back"
                onClick={() => setShowAddSurvey(false)}
              >
                <i className="ico-arrow"></i> {t("back")}
              </Button>

              <h2>{t("in_meetings.tabs.add_survey")}</h2>
              <p>{t("in_meetings.tabs.add_useful_survey")}</p>
              <Form className="add-survey-form">
                <div className=" custom-scroll">
                  <div className="form-group">
                    <label htmlFor="question-1" className="form-label">
                      {t("survey_module.question")}
                    </label>
                    <Form.Control
                      type="text"
                      id="question-1"
                      placeholder=""
                      value={question}
                      onChange={handleQuestion}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      {t("survey_module.options")}
                    </label>
                    <span className="add-option" onClick={addOption}>
                      <i className="ico-add-circle"></i>
                      {t("survey_module.add_option")}
                    </span>
                  </div>

                  <div className="form-group">
                    <FormControl className="form-check-inline">
                      <Form.Check
                        type="switch"
                        className="form-check form-switch"
                        checked={isMultichoice}
                        onChange={handleSwitch}
                        id="possibility"
                      />
                      <label htmlFor="possibility" className="form-label">
                        {t("survey_module.choose_options")}
                      </label>
                    </FormControl>
                  </div>
                  <div className="option-list">
                    {listeChoice?.map((choice: string, index: number) => (
                      <div key={index} className="form-group form-inline">
                        <span className="form-label">
                          {t("survey_module.option")} {index}:
                        </span>
                        <Form.Control
                          type="text"
                          value={choice}
                          onChange={(event) => {
                            handleListe(event, index);
                          }}
                          placeholder=""
                          id={`option${index}`}
                        />
                        {index > 1 && (
                          <span
                            className="btn"
                            title="Delete Survey"
                            onClick={() => {
                              handleShowDeleteOption(index);
                            }}
                          >
                            <i className="ico-delete"></i>
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="submit-button">
                  <button
                    className="btn btn-primary"
                    title="Create"
                    onClick={addSurvey}
                    disabled={isBoutonDisabled}
                  >
                    {t("create")}
                  </button>
                </div>
              </Form>
            </div>
          ) : null}

          {/* //? Modify survey */}
          {isShowModifSurvey ? (
            <div className="add-survey">
              <Button
                variant="link"
                className="btn-add-survey-back"
                type="button"
                title="Back"
                onClick={() => setShowModifSurvey(false)}
              >
                <i className="ico-arrow"></i> {t("back")}
              </Button>

              <h2>{t("in_meetings.tabs.modify_survey")}</h2>
              <p>{t("in_meetings.tabs.can_modify_survey")}</p>
              <Form className="add-survey-form">
                <div className=" custom-scroll">
                  <div className="form-group">
                    <label htmlFor="question-1" className="form-label">
                      {t("survey_module.question")}
                    </label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      id="question-1"
                      value={question}
                      onChange={handleQuestion}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      {t("survey_module.options")}
                    </label>
                    <span className="add-option" onClick={addOption}>
                      <i className="ico-add-circle"></i>
                      {t("survey_module.add_option")}
                    </span>
                  </div>

                  <div className="form-group">
                    <FormControl className="form-check-inline">
                      <Form.Check
                        type="switch"
                        className="form-check form-switch"
                        checked={isMultichoice}
                        onChange={handleSwitch}
                        id="possibility"
                      />
                      <label htmlFor="possibility" className="form-label">
                        {t("survey_module.choose_options")}
                      </label>
                    </FormControl>
                  </div>

                  {listeChoice?.map((choice: string, index: number) => (
                    <div key={index} className="form-group form-inline">
                      <span className="form-label">
                        {t("survey_module.option")} {index}:
                      </span>
                      <Form.Control
                        type="text"
                        value={choice}
                        onChange={(event) => {
                          handleListe(event, index);
                        }}
                        placeholder=""
                        id={`option${index}`}
                      />
                      {index > 1 && (
                        <span
                          className="btn"
                          title="Delete Survey"
                          onClick={() => {
                            handleShowDeleteOption(index);
                          }}
                        >
                          <i className="ico-delete"></i>
                        </span>
                      )}
                    </div>
                  ))}
                </div>

                <div className="submit-button">
                  <button
                    className="btn btn-primary"
                    title="Modify"
                    onClick={editSurvey}
                    disabled={isBoutonDisabled}
                  >
                    {t("modify")}
                  </button>
                </div>
              </Form>
            </div>
          ) : null}

          {/*Result survey */}
          {isShowResultSurvey ? (
            <div className="survey-result">
              <Button
                variant="link"
                className="btn-survey-result-back"
                type="button"
                title="Back"
                onClick={() => setShowResultSurvey(false)}
              >
                <i className="ico-arrow"></i> {t("back")}
              </Button>

              <h2>{t("in_meetings.tabs.survey_result")}</h2>
              <p>{t("in_meetings.tabs.consult_survey_result")}</p>

              <div className="survey-result-form">
                <div className="form-group">
                  <label className="form-label">
                    {t("survey_module.question")}
                  </label>
                  <p>
                    {meeting && meeting.surveys
                      ? meeting?.surveys[resultSurveyIndex].question
                      : ""}
                  </p>
                </div>
                <label className="form-label">
                  {t("survey_module.options")}
                </label>
                <div className="survey-result-list custom-scroll">
                  {meeting && meeting.surveys && (
                    <>
                      {meeting.surveys[resultSurveyIndex].options?.map(
                        (element, index) => {
                          return (
                            <div className="form-group" key={index}>
                              <p>
                                {t("survey_module.option")} {index + 1}:{" "}
                                <span>{element}</span>
                              </p>
                              <ProgressBar
                                now={calculPourcentageOption(index)}
                                label={calculPourcentageOption(index) + "%"}
                              />
                            </div>
                          );
                        }
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className="module-add-list-wrapper custom-scroll">
            <div className="survey-list">
              {meeting.surveys?.map((survey: Survey, index: number) => (
                <div className="survey-item" key={index}>
                  <div className="survey-item-content">
                    <label>{t("survey_module.question")}:</label>
                    <p>{survey.question}</p>

                    <label>{t("survey_module.options")}:</label>
                    <ul>
                      {survey?.options?.map((op: string, index: number) => (
                        <div key={index}>
                          <li>{op}</li>
                        </div>
                      ))}
                    </ul>
                    {survey.isShared ? (
                      <div className="number-voters">
                        <label>{t("in_meetings.tabs.voters_number")}:</label>
                        <p>
                          <span>
                            {survey.resultat?.length}/
                            {meeting.participants?.length}
                          </span>{" "}
                          {calculPourcentage(survey)}%
                        </p>
                      </div>
                    ) : null}

                    <div className="send">
                      {survey.isShared ? (
                        <button
                          className="btn btn-outline-primary btn-show-result-survey "
                          type="button"
                          title="Send"
                          onClick={() => handleShowResultSurvey(index)}
                        >
                          {t("in_meetings.tabs.show_results")}
                        </button>
                      ) : (
                        (isAdmin || isAnimateur) && (
                          <button
                            className="btn btn-outline-primary btn-send-survey"
                            type="button"
                            title="Send"
                            disabled={isAnimateur || isAdmin ? false : true}
                            onClick={() => {
                              handleSendSurvey(index);
                            }}
                          >
                            {t("send")}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                  <div className="survey-item-actions">
                    <span
                      className={
                        isAdmin || isAnimateur || survey.creator === user
                          ? "btn"
                          : "btn disabled"
                      }
                      title="Edit Survey"
                      onClick={() => {
                        handleEditSurveyModal(survey, index);
                      }}
                    >
                      <i className="ico-edit"></i>
                    </span>
                    <span
                      className={
                        isAdmin || isAnimateur || survey.creator === user
                          ? "btn"
                          : "btn disabled"
                      }
                      title="Delete Survey"
                      onClick={() => handleShowDeleteSurvey(index)}
                    >
                      <i className="ico-delete"></i>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Tab.Pane>
    </>
  );
};

export default SurveyTab;
