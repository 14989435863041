import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Meeting } from "../../../models/Meeting";
import { ROLE } from "../../../utils/MeetingVar";
import DataContext from "../../../Providers/DataProvider/DataContext";

interface BotStepObjectiveProps {
  setGeIt: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
}

const BotStepObjective = ({ setGeIt, setStep }: BotStepObjectiveProps) => {
  const { t } = useTranslation();
  const { meeting, contextData } = useContext(DataContext);
  const [showRoleValidation, setShowRoleValidation] = useState<Boolean>(false);

  useEffect(() => {
    getRoleParticipant();
  }, [meeting]);

  const getRoleParticipant = () => {
    meeting?.participants?.forEach((participant) => {
      if (
        participant?.reference?.toString() ===
        contextData?.userObjectId?.toString()
      ) {
        if (
          participant?.role === ROLE.ADMIN ||
          participant?.role === ROLE.ANIMATOR ||
          participant?.role === ROLE.NOTE_TAKER ||
          participant?.role === ROLE.TIME_KEEPER
        ) {
          setShowRoleValidation(true);
        }
      }
    });
  };

  const handleClick = () => {
    if (!showRoleValidation) {
      setStep(3);
    } else setStep((currentStep) => (currentStep + 1) as 1 | 2 | 3);
  };

  return (
    <div className="bot-meeting-objective">
      <h3>{t("meeting_objective")}</h3>
      <p>
        {meeting && meeting.objective
          ? meeting.objective
          : t("meeting_objective_improvment")}
      </p>
      <div className="bot-actions">
        <Button className="btn btn-accept" variant="link" onClick={handleClick}>
          {t("got_it")}
        </Button>
      </div>
    </div>
  );
};

export default BotStepObjective;
