import { Meeting, Agenda, Survey, Indicator, Action } from "../models/Meeting";
import { TFunction } from "react-i18next";

const MailPostMeetingTemplate = (
  meeting: Meeting,
  t: TFunction<"translation", undefined>,
  numberEffectiveness: number,
  numberTimeTalk: number,
  wheatherMoyen: number
) => {
  const wheatherName = (choice: number): string => {
    switch (choice) {
      case 1:
        return t("thunderstorm");
      case 2:
        return t("rain");
      case 3:
        return t("Cloudy");
      case 4:
        return t("partly_cloudy");
      case 5:
        return t("partly_sunny");
      case 6:
        return t("partly_sunny");
      default:
        return "";
    }
  };

  const calculPourcentageOption = (survey: Survey, index: number): number => {
    if (survey) {
      if (survey.resultat) {
        let result = survey.resultat;
        let somme = 0;
        result.forEach((element) => {
          if (element.optionResult) {
            if (element.optionResult[index]) {
              somme++;
            }
          }
        });
        somme =
          meeting && meeting.participants
            ? (somme * 100) / meeting.participants.length
            : 0;

        return Math.round(somme);
      }
    }
    return 0;
  };

  const rateHTML = (): string => {
    let temp = `  <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                          "rate"
                        )}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                  <tbody>
                    <tr>
                      <td style="vertical-align:top;padding:8px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                                <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                                  "rate_effectiv_meeting"
                                )}:</div>
                              </td>
                            </tr>
                            <tr>
                              <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                                  <tr style="border:1px solid #E0E0E0; border-radius:8px">
                                    <td style="padding: 16px; text-align: center">
                                      <span style="color: #6F6F6F; font-size: 12px">${t(
                                        "in_meetings.tabs.voters_number"
                                      )} </span>
                                      <span style="color: #6F6F6F; font-size: 16px">
                                        <b style="color: #242424">${
                                          numberEffectiveness +
                                          "/" +
                                          meeting.participants?.length
                                        }</b> (${
      meeting &&
      meeting.participants &&
      Math.floor((numberEffectiveness / meeting.participants?.length) * 100) +
        "% )"
    } </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                  <tbody>
                    <tr>
                      <td style="vertical-align:top;padding:3px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                          <tbody>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                  <tbody>
                    <tr>
                      <td style="vertical-align:top;padding:8px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                                <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                                  "rate_of_level"
                                )}:</div>
                              </td>
                            </tr>
                            <tr>
                              <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                                  <tr style="border:1px solid #E0E0E0; border-radius:8px">
                                    <td style="padding: 16px; text-align: center">
                                      <span style="color: #6F6F6F; font-size: 12px"> ${t(
                                        "in_meetings.tabs.voters_number"
                                      )}: </span>
                                      <span style="color: #6F6F6F; font-size: 16px">
                                        <b style="color: #242424">${
                                          numberTimeTalk +
                                          "/" +
                                          meeting.participants?.length
                                        }</b> (${
      meeting &&
      meeting.participants &&
      Math.floor((numberTimeTalk / meeting.participants?.length) * 100) + " %)"
    } </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                  <tbody>
                    <tr>
                      <td style="vertical-align:top;padding:3px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                          <tbody>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                  <tbody>
                    <tr>
                      <td style="vertical-align:top;padding:8px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                                <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                                  "average_weather"
                                )}:</div>
                              </td>
                            </tr>
                            <tr>
                              <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                                  <tr style="border:1px solid #E0E0E0; border-radius:8px">
                                    <td style="padding: 16px; text-align: center">
                                      <div style="font-size: 16px">
                                        <b style="color: #242424">${wheatherName(
                                          wheatherMoyen
                                        )}</b>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  const participantHTML = (): string => {
    let temp = ` <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                          "list_of_participants"
                        )}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                          "list_of_participant_present"
                        )}.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr style="background-color:#E0E0E0;text-align:left">
                            <th style="padding:6px 12px; font-size:12px; font-weight:400; color:#242424">${t(
                              "participant_module.name"
                            )}</th>
                            <th style="padding:6px 12px; font-size:12px; font-weight:400; color:#242424">${"Role"}</th>
                          </tr>

                          ${meeting?.participants
                            ?.map((participant, index) => {
                              let str = `
                           <tr>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">${
                              participant.name
                            }</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">${t(
                              "participant_module.role." + participant.role
                            )}</td>
                          </tr>`;
                              return str;
                            })
                            .join("")}
                          
                
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  const TalkTimeHTML = (): string => {
    let temp = `<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">Talk time</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">List of participant present at the meeting with everyone's speaking time.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">Wine Dominique</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">0:06</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">20%</td>
                          </tr>
                          <tr>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">Berbatowski Sela</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">1:06</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">30%</td>
                          </tr>
                          <tr>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">Barbara Lavende</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">2:06</td>
                            <td style="padding: 6px 12px; color:#6F6F6F; border-bottom: 1px solid #e0e0e0">50%</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  let agendaHTML = (): string => {
    let temp = `<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                          "agenda"
                        )}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                          "status_of_agendas"
                        )}.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr>
                          ${meeting?.agenda
                            ?.map((agend: Agenda, index: number) => {
                              let str = `<td>
                              <table style="border-bottom: 1px solid #e0e0e0; margin-bottom: 8px; width: 100%">
                                <tr>
                                  <td style="padding: 0 8px">
                                    <span style="padding: 0px 0px; color:#6F6F6F; font-size: 12px; text-align:left">${
                                      agend.title
                                    }</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 0 8px">
                                    <div>
                                      <div><span style="color: #6F6F6F; font-size:12px">${t(
                                        "estimate"
                                      )}: </span><span>${
                                agend.duration
                              }mn</span></div>
                                      <div><span style="color: #6F6F6F; font-size:12px">${"used"}: </span><span style=color:${
                                agend &&
                                agend.usedTime &&
                                agend.duration &&
                                agend.usedTime > agend.duration
                                  ? "#D63230"
                                  : "#49A078"
                              }
                              >${agend.usedTime}mn</span></div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>`;
                              return str;
                            })
                            .join("")}
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  const surveyHTML = (): string => {
    let temp = `<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                          "survey"
                        )}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                          "list_of_survey"
                        )}.</div>
                      </td>
                    </tr>
                    
                     
                        ${meeting?.surveys
                          ?.map((survey: Survey, index: number) => {
                            let str = `
                            <tr>
                              <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                            
                                <td>
                                  <table style="border-bottom: 1px solid #e0e0e0; margin-bottom: 8px; width: 100%">
                                    <tr>
                                      <td style="padding: 0 8px">
                                        <span style="padding: 0px 0px; color:#6F6F6F; font-size: 12px; text-align:left">${""} </span><span>${
                              survey.question
                            }</span>
                                      </td>
                                    </tr>
                                    ${survey?.options
                                      ?.map((option: any, sindex: number) => {
                                        let str = `
                                        <tr>
                                          <td style="padding: 0 8px">
                                            <span style="padding: 0px 0px; color:#6F6F6F; font-size: 12px; text-align:left">${option} : </span><span><b>${
                                          survey.resultat?.length
                                        }/${
                                          meeting.participants?.length
                                        }</b> ${calculPourcentageOption(
                                          survey,
                                          sindex
                                        )}%</span>
                                          </td>
                                        </tr>`;
                                        return str;
                                      })
                                      .join("")}
                                  
                                         
                                                      
                                  </table>
                                </td>
                              </table>
                             </td>
                            </tr>`;
                            return str;
                          })
                          .join("")}
                           
                    
                
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  const indicatorHTML = (): string => {
    let temp = `<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                          "indicator"
                        )}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                          "list_of_indicator"
                        )}.</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          
                        ${meeting?.indicator
                          ?.map((indic: Indicator, index: number) => {
                            let str = `<tr>
                            <td>
                              <table style="border-bottom: 1px solid #e0e0e0; margin-bottom: 8px; width: 100%">
                                <tr>
                                  <td style="padding: 0 8px">
                                    <span style="padding: 0px 0px; color:#6F6F6F; font-size: 12px; text-align:left">${
                                      indic.title
                                    }: </span><span>${indic.description}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 0 8px">
                                    <div>
                                      <div><span style="color: #6F6F6F; font-size:12px">${t(
                                        "status"
                                      )}: </span><span>${
                              indic.status
                            }</span></div>
                                      <div><span style="color: #6F6F6F; font-size:12px">${t(
                                        "trend"
                                      )}: </span><span>${
                              indic.trend
                            }</span></div>
                                      <div><span style="color: #6F6F6F; font-size:12px">${t(
                                        "indicator_module.actions"
                                      )} </span><span>${
                              indic.action
                            }...</span></div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          `;
                            return str;
                          })
                          .join("")}
                        
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  const actionHTML = (): string => {
    let temp = `<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${t(
                          "indicator_module.action"
                        )}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${t(
                          "the_actions_that_have"
                        )}:</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          
                        ${meeting?.actions
                          ?.map((action: Action, index: number) => {
                            let str = `
                            <tr>
                            <td>
                              <table style="border-bottom: 1px solid #e0e0e0; margin-bottom: 8px; width: 100%">
                                <tr>
                                  <td style="padding: 0 8px">
                                    <span>${action.types}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 0 8px">
                                    <div>
                                      <div><span style="color: #6F6F6F; font-size:12px">${t(
                                        "the_person_in_charge"
                                      )} : </span>
                                      ${meeting?.participants
                                        ?.map((participant, index) => {
                                          if (
                                            participant.reference ===
                                            action.inCharge
                                          )
                                            return `<span>${participant.name}</span>`;
                                        })
                                        .join("")}
                                      
                                    </div>
                                      <div><span style="color: #6F6F6F; font-size:12px">Date: </span><span>${new Date(
                                        Date.parse(
                                          action && action.date
                                            ? action.date.toString()
                                            : ""
                                        )
                                      ).toLocaleDateString()}</span></div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          `;
                            return str;
                          })
                          .join("")}
                      
                  
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;
    return temp;
  };

  const objectifHTML = (): string => {
    let temp = ` <!-- rate -->
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-header-outlook" role="presentation" style="width:600px;" width="600" bgcolor="#4464AD" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-header" style="background:#4464AD;background-color:#4464AD;margin:0px auto;border-radius:4px 4px 0 0;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#4464AD;background-color:#4464AD;width:100%;border-radius:4px 4px 0 0;">
        <tbody>
          <tr>
            <td style="border:1px solid #4464AD;direction:ltr;font-size:0px;padding:16px 16px 16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" class="section-header-title" style="font-size:0px;padding:0px;word-break:break-word;">
                        <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${"Objective"}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-body-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div class="section-body" style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="border:1px solid #E0E0E0;direction:ltr;font-size:0px;padding:16px 16px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-px-600 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                  <tbody>
                    <tr>
                      <td style="vertical-align:top;padding:8px;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" class="section-body-title center" style="font-size:0px;padding:0px;word-break:break-word;">
                                <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:center;color:#6F6F6F;">${
                                  meeting.objective ? meeting.objective : ""
                                }</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
    <div style="height:25px;line-height:25px;">&#8202;</div>`;

    return temp;
  };

  let temp = `
    <!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
<title> ${meeting.subject} </title>
<!--[if !mso]><!-->
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<!--<![endif]-->
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<style type="text/css">
#outlook a {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

table,
td {
  border-collapse: collapse;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
}

img {
  border: 0;
  height: auto;
  line-height: 100%;
  outline: none;
  text-decoration: none;
  -ms-interpolation-mode: bicubic;
}

p {
  display: block;
  margin: 13px 0;
}

</style>
<!--[if mso]>
<noscript>
<xml>
<o:OfficeDocumentSettings>
  <o:AllowPNG/>
  <o:PixelsPerInch>96</o:PixelsPerInch>
</o:OfficeDocumentSettings>
</xml>
</noscript>
<![endif]-->
<!--[if lte mso 11]>
<style type="text/css">
  .mj-outlook-group-fix { width:100% !important; }
</style>
<![endif]-->
<!--[if !mso]><!-->
<link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
<style type="text/css">
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);

</style>
<!--<![endif]-->
<style type="text/css">
@media only screen and (min-width:480px) {
  .mj-column-per-100 {
    width: 100% !important;
    max-width: 100%;
  }

  .mj-column-px-600 {
    width: 600px !important;
    max-width: 600px;
  }
}

</style>
<style media="screen and (min-width:480px)">
.moz-text-html .mj-column-per-100 {
  width: 100% !important;
  max-width: 100%;
}

.moz-text-html .mj-column-px-600 {
  width: 600px !important;
  max-width: 600px;
}

</style>
<style type="text/css">
</style>
<style type="text/css">
.title div {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #242424 !important;
  text-align: center !important;
  margin-bottom: 8px !important;
}

.sub-title div {
  text-align: center !important;
}

.section-space {
  height: 50px;
}

.section-header-title div {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #ffffff !important;
}

.section-body-title div {
  font-size: 12px !important;
  color: #6F6F6F !important;
}

.section-body-title.center div {
  text-align: center !important;
}

</style>
</head>

<body style="word-spacing:normal;">
<div style="">
<!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="section-title-outlook" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
<div class="section-title" style="margin:0px auto;max-width:600px;">
  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
    <tbody>
      <tr>
        <td style="direction:ltr;font-size:0px;padding:50px 0 25px 0;text-align:center;">
          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
          <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
              <tbody>
                <tr>
                  <td align="left" class="title" style="font-size:0px;padding:0px;word-break:break-word;">
                    <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${
                      meeting.subject ? meeting.subject : ""
                    }</div>
                  </td>
                </tr>
                <tr>
                  <td align="left" class="sub-title" style="font-size:0px;padding:0px;word-break:break-word;">
                    <div style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:1;text-align:left;color:#242424;">${
                      meeting.type ? meeting.type : ""
                    }</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--[if mso | IE]></td></tr></table><![endif]-->
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!--[if mso | IE]></td></tr></table><![endif]-->

<!-- Objectivr -->
${meeting.objective && objectifHTML()}
   
<!-- Participants -->
${meeting.participants && participantHTML()}

<!-- Agenda -->
${meeting.agenda && agendaHTML()}

<!-- Action -->
${meeting.actions && actionHTML()}
   
<!-- Survey -->
${meeting.surveys && surveyHTML()}

<!-- Indicator -->
${meeting.indicator && indicatorHTML()}

<!-- rate -->
${meeting.participants && rateHTML()}

<!-- Talk time -->
<!--  meeting.participants && TalkTimeHTML() -->

</div>
</body>

</html>
`;
  return temp;
};

export { MailPostMeetingTemplate };
