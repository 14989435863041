import React, { useContext, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { Button, Form } from "react-bootstrap";
import InmeetingTabs from "../InmeetingTabs/InmeetingTabs";
import { useTranslation } from "react-i18next";
import DataContext from "../../../Providers/DataProvider/DataContext";
import SocketContext from "../../../Providers/SocketProvider/SocketContext";
import { Participant } from "../../../models/Meeting";
import { User, Score } from "../../../models/User";

interface inMeeting {
  context: any;
  profile: any;
  info: any;
}

const StepForm: React.FC<inMeeting> = ({ context, profile, info }) => {
  const steps = ["", "", ""];
  const { t } = useTranslation();

  const { meeting, insertParticipant, insertUser, user } =
    useContext(DataContext);

  const [activeStep, setActiveStep] = React.useState(0);
  const [tabs, setTabs] = React.useState(true);
  const [userIndex, setUserIndex] = useState<number>(-1);

  const activePoint: number = 10;

  useEffect(() => {
    if (profile?.id) {
      let temp = meeting?.participants?.findIndex(
        (participant) => participant.reference + "" === profile?.id + ""
      );
      if (temp !== undefined && temp !== -1) {
        let t =
          meeting?.participants &&
          temp !== undefined &&
          meeting.participants[temp] &&
          meeting.participants[temp].activeStep
            ? (meeting.participants[temp].activeStep as number)
            : 0;
        setUserIndex(temp);
        if (t > 2) {
          setTabs(false);
        } else {
          setActiveStep(t);
        }
      }
    }
  }, [profile, meeting]);

  useEffect(() => {
    if (meeting && userIndex !== undefined && userIndex > -1) {
      let temp = meeting.participants as Participant[];
      temp[userIndex].activeStep = activeStep;
      insertParticipant(temp[userIndex], meeting._id, {
        ...meeting,
        participants: temp,
      });
    }
  }, [activeStep]);

  const handleSteps = (step: number) => {
    switch (step) {
      case 0:
        return <StepOne profile={profile} context={context} />;
      case 1:
        return (
          <StepTwo profile={profile} meeting={meeting} context={context} />
        );
      case 2:
        return (
          <StepThree profile={profile} meeting={meeting} context={context} />
        );
      default:
        return "Unknown";
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (user.score) {
      let isAlReadySaved = false;

      let temp = user.score.meetingId as string[];
      temp?.forEach((element) => {
        if (element.toString() === meeting.reference) {
          isAlReadySaved = true;
        }
      });
      if (!isAlReadySaved && meeting.reference) {
        // user.score.meetingId.push(meeting.reference);
        let tempScore = user.score as Score;
        tempScore?.meetingId?.push(meeting.reference);
        if (tempScore.point !== undefined) {
          tempScore.point += activePoint;
        } else tempScore.point = activePoint;

        if (tempScore.total !== undefined) {
          tempScore.total += activePoint;
        } else tempScore.total = activePoint;

        insertUser({ ...user, score: tempScore });
      }
    }
  };

  const handleGetStarted = () => {
    setActiveStep(3);
    setTabs(false);
  };

  return (
    <div className="inmeeting-meeteo">
      <div className="inmeeting-meeteo-body">
        {/* Step content */}
        {tabs ? (
          <div className="step-form">
            <Form className="inmeeting-meteo-form">
              <div id="step-meeteo">
                <Stepper activeStep={activeStep} alternativeLabel></Stepper>
                {handleSteps(activeStep)}
                <div className="actions">
                  <ul>
                    <li>
                      <Button
                        variant="outline-primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        {t("back")}
                      </Button>
                    </li>
                    <li>
                      {activeStep === steps.length - 1 ? (
                        <Button variant="primary" onClick={handleGetStarted}>
                          {t("get_started")}
                        </Button>
                      ) : (
                        <Button variant="primary" onClick={handleNext}>
                          {t("next")}
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <InmeetingTabs context={context} info={info} profile={profile} />
        )}
      </div>
    </div>
  );
};

export default StepForm;
