import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ScriptableContext,
  Filler,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import profile_outline from "./assets/images/profile-outline.svg";
import sunShine from "../components/assets/images/sunshine.svg";
import partlySunny from "../components/assets/images/partly-sunny.svg";
import partlyCloudy from "../components/assets/images/partly-cloudy.svg";
import cloudy from "../components/assets/images/cloudy.svg";
import rain from "../components/assets/images/rain.svg";
import thunderstorm from "../components/assets/images/thunderstorm.svg";
import { useTranslation } from "react-i18next";
import useLogic from "./Logic/Admin/useLogic";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
);

export default function Admin() {
  const { t } = useTranslation();

  const {
    getLabels,
    dataAverageUser,
    dataAverageAnimator,
    dataAverageAllParticipant,
    dataWheatherAverage,
    userInformation,
    userScore,
    generateDataAverage,
    isActiveButton,
    setisActiveButton,
    generateDataChart,
  } = useLogic();

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const optionsLine2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: string | number) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
          color: "white",
        },
      },
    },
  };

  const optionsBar = {
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  // ? DATA  LINECHART
  const dataLineChart = {
    labels: getLabels,
    datasets: [
      {
        label: `${t("admin.title_participant_chart")}`,
        data: generateDataAverage(getLabels, dataAverageUser!),
        borderColor: "rgba(68, 100, 173, 1)",
        backgroundColor: "rgba(68, 100, 173, 0.5)",
      },
      {
        label: `${t("admin.title_animator_chart")}`,
        data: generateDataAverage(getLabels, dataAverageAnimator!),
        borderColor: "rgba(73, 160, 120, 1)",
        backgroundColor: "rgba(73, 160, 120, 0.5)",
      },

      {
        label: `${t("admin.title_company_chart")}`,
        data: generateDataAverage(getLabels, dataAverageAllParticipant!),
        borderColor: "rgba(255, 183, 0, 1)",
        backgroundColor: "rgba(255, 183, 0, 0.5)",
      },
    ],
  };

  const images = [
    thunderstorm,
    rain,
    cloudy,
    partlyCloudy,
    partlySunny,
    sunShine,
  ];

  const plugins = [
    {
      id: "chart",
      afterDraw: (chart: any) => {
        const ctx = chart.ctx;
        const {
          y: { ticks: yTicks, _labelItems },
        } = chart.scales;

        yTicks.forEach((value: any, index: number) => {
          var [x, y] = _labelItems[index].translation;
          var image = new Image();
          image.src = images[index];
          ctx.drawImage(image, 0, y - 15, 24, 24);
        });
      },
    },
  ];

  const dataLineChart2 = () => ({
    labels: getLabels,
    datasets: [
      {
        label: "",
        data: generateDataAverage(getLabels, dataWheatherAverage!),
        // borderColor: "rgba(255, 255, 255)",
        fill: "start",
        pointRadius: 0,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 600);
          gradient.addColorStop(0, "rgba(73, 160, 120, 1)");
          gradient.addColorStop(0.2, "rgba(28, 158, 207, 1)");
          gradient.addColorStop(0.4, "rgba(47, 203, 197, 1)");
          gradient.addColorStop(0.6, "rgba(200, 212, 35, 1)");
          gradient.addColorStop(0.8, "rgba(217, 79, 31, 1)");
          gradient.addColorStop(1, "rgba(214, 50, 48, 1)");
          return gradient;
        },
      },
    ],
  });

  // ? DATA StackBarChart
  const dataStackedBarChart = {
    labels: getLabels,
    datasets: [
      {
        label: `${t("admin.badly_distributed")}`,
        data: generateDataChart(getLabels, "bad"),
        backgroundColor: "rgba(214, 50, 48, 1)",
      },
      {
        label: `${t("admin.moderatly_distributed")}`,
        data: generateDataChart(getLabels, "average"),
        backgroundColor: "rgba(255, 183, 0, 1)",
      },

      {
        label: `${t("admin.well_distributed")}`,
        data: generateDataChart(getLabels, "good"),
        backgroundColor: "rgba(73, 160, 120, 1)",
      },
    ],
  };

  return (
    <div className="appmenu-meeteo">
      <div className="personal-tab">
        <div className="kpi">
          <div className="kpi-profile">
            <figure>
              <img src={profile_outline} alt="Alternate Text" />
              {/* <i className="ico-profile-outline"></i> */}
            </figure>
            <span>{userInformation?.name}</span>
          </div>
          <div className="kpi-list">
            <div className="kpi-list-item">
              <span>{t("personal.rank")}:</span>
              <p>{(userScore?.total / 100).toFixed(0)}</p>
            </div>
            <div className="kpi-list-item">
              <span>{t("personal.total_points")}:</span>
              <p>{userScore?.point} pts</p>
            </div>
          </div>
        </div>

        <div className="chart-list custom-scroll">
          <div className="chart-item">
            <h2 className="chart-title">{t("admin.meeting_note")}</h2>

            <Line options={optionsLine} data={dataLineChart} />
            {/* <canvas id="meeting-note"></canvas> */}
          </div>
          <div className="chart-item">
            <h2 className="chart-title">{t("admin.speaking_time")}</h2>
            <Bar options={optionsBar} data={dataStackedBarChart} />
            {/* <canvas id="latest-meeting" width="450" height="315"></canvas> */}
          </div>
          <div className="chart-item">
            <h2 className="chart-title"> {t("admin.average_wheather")} </h2>
            <div className="filtre-chart">
              <div
                className={`filtre-chart-item ${
                  isActiveButton === "week" && "active"
                }`}
                onClick={() => {
                  setisActiveButton("week");
                }}
              >
                1 week
              </div>
              <div
                className={`filtre-chart-item ${
                  isActiveButton === "month" && "active"
                }`}
                onClick={() => {
                  setisActiveButton("month");
                }}
              >
                1 month
              </div>
              {/* <div className="filtre-chart-item" onClick={}>
                6 months
              </div>
              <div className="filtre-chart-item" onClick={}>
                1 year
              </div> */}
            </div>
            <Line
              data={dataLineChart2()}
              options={optionsLine2}
              plugins={plugins}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export { Admin };
