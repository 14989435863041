import React, { useState } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { Meeting, Survey } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";

interface SurveyInterface {
  meeting: Meeting;
}

const SurveyPost: React.FC<SurveyInterface> = ({ meeting }) => {
  const { t } = useTranslation();

  const [showResultModal, setShowResultModal] = useState(false);
  const [showResultModalIndex, setshowResultModalIndex] = useState<number>(-1);

  const handleCloseResultModal = () => setShowResultModal(false);
  const handleShowResultModal = (index: number) => {
    setshowResultModalIndex(index);
    setShowResultModal(true);
  };

  const calculPourcentage = (survey: Survey): number => {
    let temp = survey?.resultat ? survey.resultat : [];
    let temp2 = meeting.participants ? meeting.participants : [];
    if (temp.length === 0 || temp2.length === 0) {
      return 0;
    } else {
      return Math.round((temp.length / temp2.length) * 100);
    }
  };

  const calculPourcentageOption = (index: number): number => {
    if (meeting?.surveys) {
      let temp = meeting?.surveys[showResultModalIndex];
      if (temp?.resultat) {
        let result = temp.resultat;
        let somme = 0;
        result.forEach((element) => {
          if (element.optionResult) {
            if (element.optionResult[index]) {
              somme++;
            }
          }
        });
        somme = meeting?.participants
          ? (somme * 100) / meeting.participants.length
          : 0;

        return Math.round(somme);
      }
    }
    return 0;
  };

  return (
    <>
      <div className="module-item survey">
        <div className="module-header">
          <h3 className="module-title">
            <span className="module-icon">
              <i className="ico-survey"></i>
            </span>
            {t("survey")}
          </h3>
        </div>

        <div className="module-item-content">
          <p className="module-content-intro">
            {t("survey_module.add_module")}
          </p>

          <div className="module-main-content module-main-content-survey">
            <div className="survey-list custom-scroll">
              {meeting?.surveys?.map((survey: Survey, index: number) => (
                <div className="survey-item" key={index}>
                  <div className="survey-item-content">
                    <label>{t("survey_module.question")}:</label>
                    <p>{survey.question}</p>
                    <div className="number-voters">
                      <label>{t("in_meetings.tabs.voters_number")}:</label>
                      <p>
                        <span>
                          {survey.resultat?.length}/
                          {meeting?.participants?.length}
                        </span>{" "}
                        ({calculPourcentage(survey)})%
                      </p>
                    </div>
                    <div className="send">
                      <button
                        className="btn btn-outline-primary"
                        type="button"
                        title="Sow results"
                        onClick={() => {
                          handleShowResultModal(index);
                        }}
                      >
                        {t("in_meetings.tabs.show_results")}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal survey result */}
      <Modal
        show={showResultModal && showResultModalIndex !== -1}
        onHide={handleCloseResultModal}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-survey"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-group-title">
              <div className="modal-group-icon">
                <i className="ico-modal-title"></i>
              </div>
              <div>
                <h5 className="modal-title">
                  {t("in_meetings.tabs.survey_result")}
                </h5>
                <h6 className="modal-subtitle">Meeteo</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="survey-result">
            <div className="survey-result-form">
              <div className="form-group">
                <label className="form-label">{t("question")}</label>
                <p>
                  {meeting?.surveys && showResultModalIndex !== -1
                    ? meeting?.surveys[showResultModalIndex]?.question
                    : ""}
                </p>
              </div>
              <label className="form-label">{t("survey_module.options")}</label>
              <div className="survey-result-list">
                {meeting?.surveys && showResultModalIndex !== -1 && (
                  <>
                    {meeting?.surveys[showResultModalIndex].options?.map(
                      (element, index) => {
                        return (
                          <div className="form-group" key={index}>
                            <p>
                              {t("survey_module.option")} {index + 1}:{" "}
                              <span>{element}</span>
                            </p>
                            <ProgressBar
                              now={calculPourcentageOption(index)}
                              label={calculPourcentageOption(index) + "%"}
                            />
                          </div>
                        );
                      }
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseResultModal}>
            {t("got_it")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SurveyPost;
