import { TeamsUserCredentialAuthConfig } from "@microsoft/teamsfx";

const appStartLogin = process.env.REACT_APP_START_LOGIN_PAGE_URL
  ? process.env.REACT_APP_START_LOGIN_PAGE_URL
  : "https://localhost:53000/auth-start.html";

const appClienId = process.env.REACT_APP_CLIENT_ID
  ? process.env.REACT_APP_CLIENT_ID
  : "247f76da-0a9e-43f4-831f-8626058c26ed";

const authConfig: TeamsUserCredentialAuthConfig = {
  clientId: appClienId,
  initiateLoginEndpoint: appStartLogin,
};

const msal2ProviderConfig = {
  clientId: appClienId,
  authPopupUrl: appStartLogin,
  scopes: ["User.Read"],
};

const scope = [
  "User.Read",
  "Chat.Read",
  "OnlineMeetings.Read",
  "OnlineMeetings.ReadWrite",
  "User.Read.All",
  "Calendars.ReadWrite",
];

export { scope, authConfig, msal2ProviderConfig };
