import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const ProfileOutlined = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g id="ic_fluent_person_20_regular" transform="translate(-3 -2)">
        <path
          id="Tracé_1021"
          data-name="Tracé 1021"
          d="M10,2a4,4,0,1,0,4,4A4,4,0,0,0,10,2ZM7,6a3,3,0,1,1,3,3A3,3,0,0,1,7,6Z"
          fill="#212121"
        />
        <path
          id="Tracé_1022"
          data-name="Tracé 1022"
          d="M5.009,11A2,2,0,0,0,3,13a4.352,4.352,0,0,0,2.135,3.8A9.142,9.142,0,0,0,10,18a9.142,9.142,0,0,0,4.865-1.2A4.352,4.352,0,0,0,17,13a2,2,0,0,0-2-2ZM4,13a1,1,0,0,1,1.009-1H15a1,1,0,0,1,1,1,3.355,3.355,0,0,1-1.673,2.953A8.163,8.163,0,0,1,10,17a8.162,8.162,0,0,1-4.327-1.047A3.355,3.355,0,0,1,4,13Z"
          fill="#212121"
        />
      </g>
    </SvgIcon>
  );
};

export default ProfileOutlined;
