import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useStopwatch } from "react-timer-hook";
import DataContext from "../../../Providers/DataProvider/DataContext";
import { Agenda, Meeting } from "../../../models/Meeting";

interface agendaProps {
  duration: number | undefined;
  index: number;
  restart: boolean;
}

const AgendaTimer = forwardRef(
  ({ duration, restart, index }: agendaProps, ref) => {
    const { t } = useTranslation();
    const { minutes, seconds, pause, reset, start } = useStopwatch({
      autoStart: true,
    });
    const { meeting } = useContext(DataContext);

    useEffect(() => {
      if (restart) {
        let watchOffset = new Date();
        let debut =
          meeting &&
          meeting.agenda &&
          meeting.agenda[index].startTime !== undefined
            ? new Date((meeting.agenda[index].startTime as Date).toString())
            : new Date();

        let t1 = watchOffset.getTime();
        let t2 = debut.getTime();

        let dateDiff = (t1 - t2) / 1000;
        // let differenceSereconde = debut?.getSeconds() - watchOffset.getSeconds();

        watchOffset.setSeconds(watchOffset.getSeconds() + dateDiff);
        if (meeting && meeting.agenda && meeting.agenda[index].isClosed) {
          let closedTime = new Date();
          let sec =
            meeting && meeting.agenda && meeting.agenda[index].usedTime
              ? (meeting.agenda[index].usedTime as number)
              : 0;
          closedTime.setSeconds(closedTime.getSeconds() + sec * 60);
          reset(closedTime, false);
          pause();
        } else if (
          meeting &&
          meeting.agenda &&
          !meeting.agenda[index].isClosed
        ) {
          reset(watchOffset, true);
        }
      }
    }, [restart]);

    useImperativeHandle(ref, () => ({
      pause,
      minutes,
    }));

    return (
      <div>
        <span>{t("in_meetings.tabs.used")}:</span>
        <span
          className={
            duration && duration < minutes
              ? "higher-estimate"
              : "lower-estimate"
          }
        >
          <i className="ico-timer"></i> {("0" + minutes).slice(-2)} mn
        </span>
      </div>
    );
  }
);

export default AgendaTimer;
