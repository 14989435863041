import React, { useEffect, useState } from "react";

import io from "socket.io-client";

import SocketContext from "./SocketContext";
const SOCKET_BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8080"
    : "https://" + process.env.REACT_APP_BACKEND_HOST;

const SocketProvider: React.FC = ({ children }) => {
  const socket = io(SOCKET_BASE_URL, { transports: ["websocket"] });

  const [showShareRules, setShareRules] = React.useState(false);
  const [sendSurvey, setSendSurvey] = useState<any>(null);
  const [shareID, setShareID] = React.useState<string>("");
  const [responseUser, setresponseUser] = useState<boolean>(false);
  const [responseRole, setresponseRole] = useState<boolean>(false);

  const [updateMeeting, setUpdateMeeting] = useState<boolean>(false);

  const [isRoleRejected, setisRoleRejected] = useState<boolean>(false);
  const [rejectedRoleName, setrejectedRoleName] = useState<string>("");

  const timeOutDelay: number = 10000;

  useEffect(() => {
    socket.on("ShareRulesFromServer", (...args) => {
      setShareRules(true);
      setShareID(args[0]);
    });

    socket.on("SendSurveyFromServer", (...args) => {
      setShareID(args[0]);
      setSendSurvey(args);
    });

    socket.on("respondSurveyFromServer", (...args) => {
      setShareID(args[0]);
      setresponseUser(true);
    });

    socket.on("sendRoleFromServer", (...args) => {
      setShareID(args[0]);
      setresponseRole(true);
    });

    socket.on("update", (...args) => {
      setUpdateMeeting(true);
    });

    socket.on("requestUpdateFromServer", (...args) => {
      setUpdateMeeting(true);
    });

    socket.on("emitRejectionFromServer", (...args) => {
      setisRoleRejected(true);
      setrejectedRoleName(args[1]);
    });

    socket.on("emitTestResponse", (...args) => {});

    // socket.on("connected", (...args) => {});
  }, [socket]);

  const emitRule = (meetingId: string) => {
    socket
      .timeout(timeOutDelay)
      .emit("ShareRules", meetingId, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };

  const emitSurvey = (meetingId: string, surveyId: number) => {
    socket
      .timeout(timeOutDelay)
      .emit("SendSurvey", meetingId, surveyId, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };

  const respondSurvey = (meetingId: string) => {
    socket
      .timeout(timeOutDelay)
      .emit("respondSurvey", meetingId, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };
  const requestTime = () => {
    return new Promise((resolve, reject) => {
      socket
        .timeout(timeOutDelay)
        .emit("requestTime", (err: any, response: any) => {
          if (response) {
            resolve(response);
          }
          if (err) {
            reject(err);
          }
        });
    });
  };

  const joinRoom = (meetingId: string) => {
    socket
      .timeout(timeOutDelay)
      .emit("JoinRoom", meetingId, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };

  const emitUpdate = (meetingId: string) => {
    // socket.emit("sendRole", meetingId);
  };

  const requestUpdate = (meetingId: string) => {
    socket
      .timeout(timeOutDelay)
      .emit("requestUpdate", meetingId, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };

  const emitRejection = (meetingId: string, name: string) => {
    socket
      .timeout(timeOutDelay)
      .emit("emitRejection", meetingId, name, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };

  const emitTest = (meetingId: string) => {
    socket
      .timeout(timeOutDelay)
      .emit("emitTest", meetingId, (err: any, response: any) => {
        if (response) {
        }
        if (err) {
        }
      });
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        emitRule,
        emitSurvey,
        showShareRules,
        setShareRules,
        shareID,
        sendSurvey,
        respondSurvey,
        responseUser,
        setresponseUser,
        joinRoom,
        emitUpdate,
        responseRole,
        setresponseRole,
        requestUpdate,
        updateMeeting,
        setUpdateMeeting,
        emitRejection,
        setrejectedRoleName,
        isRoleRejected,
        rejectedRoleName,
        setisRoleRejected,
        emitTest,
        requestTime,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
