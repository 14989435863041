import React, { useContext, useEffect, useState } from "react";
import { Meeting, Rule } from "../../../models/Meeting";
import { useTranslation } from "react-i18next";
import SocketContext from "../../../Providers/SocketProvider/SocketContext";
import DataContext from "../../../Providers/DataProvider/DataContext";

interface RuleProps {
  meeting: Meeting;
  context: any;
  profile: any;
  isDeleteConfirmed: boolean;
  ruleIndex: number;
  setMeeting: React.Dispatch<React.SetStateAction<Meeting>>;
  setShowDeleteConfirmationAction: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
  activeTitle: number;
  deactivateModule: (moduleName: string) => void;
  setIsDeleteConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  setRuleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const useLogic = ({
  meeting,
  context,
  isDeleteConfirmed,
  ruleIndex,
  setIsDeleteConfirmed,
  setShowDeleteConfirmationAction,
  setActiveTitle,
  deactivateModule,
  setRuleIndex,
  activeTitle,
}: RuleProps) => {
  const { addRules, deleteRulesData } = useContext(DataContext);

  const { t } = useTranslation();

  const { emitRule } = useContext(SocketContext);

  const [newRule, setNewRule] = useState<Rule>({
    title: "",
    owner: "",
    isAdmin: false,
  });
  const [deleteText] = useState({
    title: t("remove.rule.title"),
    text: t("remove.rule.confirmation"),
  });
  const [currentRuleIndex, setCurrentRuleIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(true);
  const [role, setRole] = useState("");
  const [title, setTitle] = useState(newRule.title);
  const [owner, setOwner] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [indexRuleStep, setIndexRuleStep] = useState(0);

  useEffect(() => {
    if (context) setOwner(context.userObjectId);
  }, [context]);

  useEffect(() => {
    if (meeting && meeting.participants && context) {
      let id = meeting.participants?.findIndex(
        (element) => element.reference === context.userObjectId
      );
      setRole(
        meeting?.participants[id]?.role
          ? (meeting?.participants[id]?.role as string)
          : "member"
      );
      let temp = meeting.participants;
      if (temp[id]?.role !== "admin" && temp[id]?.role !== "animator") {
        setIsAdmin(false);
      } else {
        setIsAdmin(true);
      }
    }
  }, [meeting.participants]);

  useEffect(() => {
    if (isDeleteConfirmed && activeTitle === 2) {
      deleteRule(ruleIndex);
      setIsDeleteConfirmed(false);
    }
  }, [isDeleteConfirmed]);

  const handleToggleDeleteModal = (index: number) => {
    setCurrentRuleIndex(index);
    setShowDeleteModal(!showDeleteModal);
  };

  const handlerEmitRules = () => {
    if (meeting && meeting._id) emitRule(meeting._id);
  };

  const handleRuleChange = (event: any) => {
    setTitle(event.target.value);
  };

  const reset = () => {
    setNewRule({
      title: "",
      owner: "",
      isAdmin: false,
    });
    setTitle("");
  };

  const addRule = () => {
    meeting.rules?.push({ ...{ title, owner, isAdmin } });
    addRules({ title, owner, isAdmin }, meeting);
    reset();
  };

  const deleteRule = (index: number) => {
    let temp =
      meeting && meeting.rules ? (meeting.rules[index] as Rule) : ({} as Rule);
    meeting.rules?.splice(index, 1);
    if (temp) deleteRulesData(temp, meeting);

    setShowDeleteConfirmation(true);
    handleToggleDeleteModal(index);
    // setActiveTitle(index);
    reset();
  };

  const handleDeleteRuleStep = (index: number) => {
    setShowDeleteConfirmation(false);
    setIndexRuleStep(index);
  };

  const handleDeleteRuleTab = (index: number) => {
    setShowDeleteConfirmationAction(true);
    setActiveTitle(2);
    setRuleIndex(index);
  };

  return {
    meeting,
    newRule,
    title,
    deleteText,
    showDeleteModal,
    currentRuleIndex,
    showDeleteConfirmation,
    role,
    indexRuleStep,
    handleRuleChange,
    addRule,
    deleteRule,
    handleToggleDeleteModal,
    setShowDeleteModal,
    setShowDeleteConfirmation,
    deactivateModule,
    handlerEmitRules,
    handleDeleteRuleTab,
    handleDeleteRuleStep,
  };
};

export default useLogic;
